import { render, staticRenderFns } from "./AboutIndex.vue?vue&type=template&id=12ebbf94&scoped=true&"
import script from "./AboutIndex.vue?vue&type=script&lang=ts&"
export * from "./AboutIndex.vue?vue&type=script&lang=ts&"
import style0 from "./AboutIndex.vue?vue&type=style&index=0&id=12ebbf94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ebbf94",
  null
  
)

export default component.exports