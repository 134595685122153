


































































































import { Component, Watch } from "vue-property-decorator";
import { FormMixin } from "@/components/Mixins/FormMixin";
import { IOption } from "@/store/models/Form/FormModel";
import VueInput from "@/components/Form/VueInput.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import Lang from "@/store/modules/LangModule";
import { EnumLang } from "@/store/models/Lang/LangModel";
import {
	IFindEmailReq,
	IFindEmailReqValidate,
} from "@/store/models/Auth/AuthModel";
import Auth from "@/store/modules/AuthModule";
import Country from "@/store/modules/CountryModule";

@Component({
	components: {
		VueInput,
		VueSelect,
		VueButton,
		VueValidator,
	},
})
export default class VueFindEmail extends FormMixin {
	findEmailData: IFindEmailReq = {
		lastName: "",
		firstName: "",
		mobileNum: "",
        mobileCountryPk: 0
	};

	validator: IFindEmailReqValidate = {
		lastName: "",
		firstName: "",
		mobileNum: "",
	};

	countryCode: string = "선택";
	mobile1: string = "";
	mobile2: string = "";
	mobile3: string = "";

	$refs!: {
		firstName: HTMLInputElement;
		lastName: HTMLInputElement;
		mobile1: HTMLInputElement;
		mobile2: HTMLInputElement;
		mobile3: HTMLInputElement;
	};

	lang = EnumLang;

	//휴대폰
	numberList: IOption[] = [];

	localeToCode() {
		return Lang.getLocaleToCode;
	}

	get countryCodeList(): IOption[] {
		return Country.getCountryCodeList;
	}

	@Watch("mobile1")
	@Watch("mobile2")
	@Watch("mobile3")
	setMobile() {
		this.findEmailData.mobileNum =
			this.mobile1 +
			"-" +
			this.mobile2 +
			"-" +
			this.mobile3;
	}

	validate() {
		let valid = true;
		let focus = false;
		const mobileRule = /\d{3}-\d{3,4}-\d{4}/;

        this.validator = {
            firstName: '',
            lastName: '',
            mobileNum: '',
        }

		if (this.findEmailData.firstName.length < 1) {
			this.validator.firstName = "validate.find_email.last_name.required";
			valid = false;

			if (!focus) {
				this.$refs.firstName.focus();
				focus = true;
			}
		}

		if (this.findEmailData.lastName.length < 1) {
			this.validator.lastName = "validate.find_email.first_name.required";
			valid = false;

			if (!focus) {
				this.$refs.lastName.focus();
				focus = true;
			}
		}

		if (
			this.mobile1.length < 1 ||
			this.mobile2.length < 1 ||
			this.mobile3.length < 1
		) {
			this.validator.mobileNum = "validate.find_email.mobile.required";
			valid = false;

			if (!focus) {
				if (this.mobile1.length < 1) {
					this.$refs.mobile1.focus();
					focus = true;
				} else if (this.mobile2.length < 1) {
					this.$refs.mobile2.focus();
					focus = true;
				} else {
					this.$refs.mobile3.focus();
					focus = true;
				}
			}
		} else if (this.findEmailData.mobileCountryPk === 0) {
            this.validator.mobileNum = "validate.country_select";
            valid = false;

            if (!focus) {
                this.$refs.mobile1.focus();
                focus = true;
            }
        } else if (!mobileRule.test(this.findEmailData.mobileNum)) {
			this.validator.mobileNum = "validate.find_email.mobile.not_correct";
			valid = false;

			if (!focus) {
				this.$refs.mobile1.focus();
				focus = true;
			}
		}

		return valid;
	}

	async submit() {
        if (this.validate()) {
            await Auth.actionFindEmail(this.findEmailData)
                .then((res: any) => {
                    if (res.data) {
                        this.$emit("next", res.data.email);
                    }

                    if (res.errors.firstName) {
                        this.validator.firstName = res.errors.firstName[0]
                    }

                    if (res.errors.lastName) {
                        this.validator.lastName = res.errors.lastName[0]
                    }

                    if (res.errors.mobileNum) {
                        this.validator.mobileNum = res.errors.mobileNum[0]
                    }
                });
        }
	}

	created() {
		Country.countryList();
	}
}
