import {
    ExhibitionSiteContentListRes,
    IExhibitionSiteContentListReq
} from "@/store/models/ExhibitionSite/ExhibitionSiteModel";
import {axiosAuth} from "@/store/api/BaseApi";
import {BaseRes} from "@/store/models/BaseModel";

/**
 * 전시 사이트 컨텐츠 리스트
 *
 * @param req
 */
export async function reqExhibitionSiteContentList(req: IExhibitionSiteContentListReq): Promise<ExhibitionSiteContentListRes> {

    let exhibitionSiteContentListRes: ExhibitionSiteContentListRes = new ExhibitionSiteContentListRes();

    await axiosAuth()
        .post('/api/exhibition-site/list', req)
        .then(result => {
            exhibitionSiteContentListRes = (result.data.data as ExhibitionSiteContentListRes)
        })
        .catch(error => exhibitionSiteContentListRes = error);

    return exhibitionSiteContentListRes;
}

/**
 * 전시사이트 컨텐츠 조회수 증가
 *
 * @param exhibitionSiteContentPk
 */
export async function reqExhibitionSiteContentUpCount(exhibitionSiteContentPk: number): Promise<BaseRes> {

    let res: BaseRes = new BaseRes();

    await axiosAuth()
        .post('/api/exhibition-site/upcount', {
            pk: exhibitionSiteContentPk
        })
        .then(result => res = (result.data as BaseRes))
        .catch(error => res = error);

    return res;
}
