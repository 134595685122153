import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from "@/pages/Login.vue";
import RegistryComplete from "@/pages/RegistryComplete.vue";
import Home from "@/pages/Index.vue";
import About from "@/pages/About.vue";
import PressKit from "@/pages/PressKit.vue";
import Product from "@/pages/Product.vue";
import Meeting from "@/pages/Meeting.vue";
import RequestMeeting from "@/pages/RequestMeeting.vue";
import Mypage from "@/pages/Mypage.vue";
import Qna from "@/pages/Qna.vue";
import Unregister from "@/pages/Unregister.vue";
import Exhibition from "@/pages/Exhibition.vue";
import MediaCenter from "@/pages/MediaCenter.vue";
import Search from "@/pages/Search.vue";
import ResetPassword from "@/pages/ResetPassword.vue";
import ResetPasswordUser from "@/pages/ResetPasswordUser.vue";
import Error404 from "@/pages/error/Error404.vue";
import Error500 from "@/pages/error/Error500.vue";
import ApprovalPending from "@/pages/ApprovalPending.vue";
import Invite from "@/pages/Invite.vue";
import Intro from "@/pages/Intro.vue";

import StyleGuide from "@/pages/StyleGuide.vue";
import Link from "@/pages/Link.vue";
import AuthMiddleware from "@/router/middleware/AuthMiddleware";
import I18nMiddleware from "@/router/middleware/I18nMiddleware";
import CheckPasswordAuthMiddleware from "@/router/middleware/CheckPasswordAuthMiddleware";
import { OAuth } from "@/libs/OAuth";
import AuthModule from "@/store/modules/AuthModule";
import FindPassword from "@/pages/FindPassword.vue";
import { Session } from "@/libs/Session";
import ErrorCert from "@/pages/error/ErrorCert.vue";
import GnbModule from "@/store/modules/GnbModule";
import { convertToMenuName, MENU_NAME } from "@/store/models/Statistics/StatisticsApiModel";
import CheckEmailAuthMiddleare from "@/router/middleware/CheckEmailAuthMiddleare";
import CheckInviteAuthMiddleware from "@/router/middleware/CheckInviteAuthMiddleware";

import VsrIndex from "@/pages/vsr/Index.vue";
import VsrLobby from "@/pages/vsr/Lobby.vue";
import VsrMHi from "@/pages/vsr/MHi.vue";
import VsrMTo from "@/pages/vsr/MTo.vue";
import VsrPop from "@/pages/vsr/MPop.vue";
import VsrM2go from "@/pages/vsr/M2go.vue";
import VsrTechGallery from "@/pages/vsr/TechGallery.vue";
import VsrMobisVision from "@/pages/vsr/MobisVision.vue";
import VsrMobisNow from "@/pages/vsr/MobisNow.vue";
import VipExhibition from "@/pages/VipExhibition.vue";
import VipMediaCenter from "@/pages/VipMediaCenter.vue";

Vue.use(VueRouter);

export enum ROUTE_NAME {
    HOME = 'home',
    ABOUT = 'about',
    PRESSKIT = 'presskit',
    LOGIN = 'login',
    REGISTRYCOMPLETE = 'registrycomplete',
    STYLEGUIDE = 'styleguide',
    PRODUCT = 'product',
    MEETING = 'meeting',
    REQUESTMEETING = 'requestmeeting',
    MYPAGE = 'mypage',
    QNA = 'qna',
    EXHIBITION = 'exhibition',
    VIP_EXHIBITION = 'vip-exhibition',
    MEDIACENTER = 'mediacenter',
    VIP_MEDIACENTER = 'vip-mediacenter',
    SEARCH = 'search',
    UNREGISTER = 'unregister',
    RESETPASSWORD = 'resetpassword',
    RESETPASSWORDUSER = 'resetpassworduser',
    RESETPASSWORDEMAIL = 'resetpasswordemail',
    FIND_PASSWORD = 'findpassword',
    ERROR404 = 'error404',
    ERROR500 = 'error500',
    ERRORCERT = 'errorcert',
    APPROVALPENDING = 'approvalPending',
    INVITE = 'invite',
    BROWSERCHECK = 'browsercheck',
    INTRO_VIEW = 'intro-view',

    VSR_INDEX = 'virtualshowroom',
    VSR_LOBBY = 'virtualshowroom/lobby',
    VSR_MPOP = 'virtualshowroom/mPop',
    VSR_M2GO = 'virtualshowroom/m2go',
    VSR_MTO = 'virtualshowroom/mTo',
    VSR_MHI = 'virtualshowroom/mHi',
    VSR_TECH_GALLERY = 'virtualshowroom/techGallery',
    VSR_MOBIS_VISION = 'virtualshowroom/mobisVision',
    VSR_MOBIS_NOW = 'virtualshowroom/mobisNow'
}

export interface IMenu {
    name: String;
    path: String;
    icon?: String;
    isActive?: boolean;
    isShow: Boolean;
    menus?: IMenu[];
}

const userMenu: IMenu[] = [
    {
        name: '계정관리',
        path: '/config',
        icon: 'fi-rr-settings',
        isActive: false,
        isShow: true,
        menus: [
            {
                name: '마이페이지',
                path: '/config/mypage',
                icon: 'fi-rr-database',
                isShow: true,
            }
        ]
    }
]

const menu: IMenu[] = [
    {
        name: '홈',
        path: '/',
        icon: 'fi-rr-home',
        isActive: true,
        isShow: true,
    },
    {
        name: '캠페인 관리',
        path: '/campaign',
        icon: 'fi-rr-apps',
        isActive: false,
        isShow: true,
        menus: [
            {
                name: '캠페인 리스트',
                path: '/campaign',
                isShow: true,
            }
        ]
    }
]

const router: VueRouter = new VueRouter({
    mode: 'history',

    routes: [
        {
            name: ROUTE_NAME.INTRO_VIEW,
            path: '/',
            component: Intro,
            meta: {
                title: 'INTRO',
                requiresAuth: false,
            }
        },
        {
            name: "lang",
            path: "/:lang",
            component: Intro,
            meta: {
                title: 'INTRO',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.HOME,
            path: '/:lang/main',
            component: Home,
            meta: {
                title: 'MAIN',
                requiredAuth: false,
            }
        },
        {
            name: ROUTE_NAME.LOGIN,
            path: '/:lang/login',
            component: Login,
            //beforeEnter: LoginMiddleware,
            meta: {
                title: 'LOGIN',
                requiresAuth: false,
            }
        },
        {
            name: "about",
            path: "/:lang/about",
            component: About,
            meta: {
                title: 'ABOUT',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.PRESSKIT,
            path: '/:lang/presskit',
            component: PressKit,
            meta: {
                title: 'PRESSKIT',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.REGISTRYCOMPLETE,
            path: '/:lang/registrycomplete',
            component: RegistryComplete,
            meta: {
                title: 'REGISTRYCOMPLETE',
                requiresAuth: false,
            },
            beforeEnter: CheckEmailAuthMiddleare,
        },
        {
            name: ROUTE_NAME.PRODUCT,
            path: '/:lang/product/:id',
            component: Product,
            meta: {
                title: 'PRODUCT',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.MYPAGE,
            path: '/:lang/mypage',
            component: Mypage,
            meta: {
                title: 'MYPAGE',
                requiresAuth: true,
            }
        },
        {
            name: ROUTE_NAME.MEETING,
            path: '/:lang/meeting',
            component: Meeting,
            meta: {
                title: 'MEETING',
                requiresAuth: true,
            }
        },
        {
            name: ROUTE_NAME.REQUESTMEETING,
            path: '/:lang/requestmeeting',
            component: RequestMeeting,
            meta: {
                title: 'REQUESTMEETING',
                requiresAuth: true,
            }
        },
        {
            name: ROUTE_NAME.QNA,
            path: '/:lang/qna',
            component: Qna,
            meta: {
                title: 'QNA',
                requiresAuth: true,
            }
        },
        {
            name: ROUTE_NAME.EXHIBITION,
            path: '/:lang/exhibition/:id/:subId?',
            component: Exhibition,
            meta: {
                title: 'EXHIBITION',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.VIP_EXHIBITION,
            path: '/:lang/vip-exhibition/:subId?',
            component: VipExhibition,
            meta: {
                title: 'EXHIBITION',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.MEDIACENTER,
            path: '/:lang/mediacenter/:id/:subId?',
            component: MediaCenter,
            meta: {
                title: 'MEDIACENTER',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.VIP_MEDIACENTER,
            path: '/:lang/vip-mediacenter/:subId?',
            component: VipMediaCenter,
            meta: {
                title: 'MEDIACENTER',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.SEARCH,
            path: '/:lang/search',
            component: Search,
            meta: {
                title: 'SEARCH',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.UNREGISTER,
            path: '/:lang/unregister',
            component: Unregister,
            meta: {
                title: 'UNREGISTER',
                requiresAuth: true,
            }
        },
        {
            name: ROUTE_NAME.RESETPASSWORD,
            path: '/:lang/resetpassword',
            component: ResetPassword,
            meta: {
                title: 'RESETPASSWORD',
                requiresAuth: false,
            },
            beforeEnter: CheckPasswordAuthMiddleware
        },
        {
            name: ROUTE_NAME.RESETPASSWORDUSER,
            path: '/:lang/resetpassworduser',
            component: ResetPasswordUser,
            meta: {
                title: 'RESETPASSWORDUSER',
                requiresAuth: true,
            },
        },
        {
            name: ROUTE_NAME.FIND_PASSWORD,
            path: '/:lang/findpassword',
            component: FindPassword,
            meta: {
                title: 'findPassword',
                requiresAuth: false
            }
        },
        {
            name: ROUTE_NAME.ERROR404,
            path: '/:lang/error404',
            component: Error404,
            meta: {
                title: '404',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.ERROR500,
            path: '/:lang/error500',
            component: Error500,
            meta: {
                title: '500',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.ERRORCERT,
            path: '/:lang/errorcert',
            component: ErrorCert,
            meta: {
                title: 'cert',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.APPROVALPENDING,
            path: '/:lang/approvalpending',
            component: ApprovalPending,
            meta: {
                title: 'ApprovalPending',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.INVITE,
            path: '/:lang/invite',
            component: Invite,
            meta: {
                title: 'invite',
                requiresAuth: false,
            },
            beforeEnter: CheckInviteAuthMiddleware
        },
        {
            name: ROUTE_NAME.INTRO_VIEW,
            path: '/:lang/intro',
            component: Intro,
            meta: {
                title: 'Intro',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.STYLEGUIDE,
            path: '/:lang/styleguide',
            component: StyleGuide,
            meta: {
                title: 'StyleGuide',
                requiresAuth: false,
            }
        },
        {
            path: '/:lang/Link',
            component: Link,
            meta: {
                title: 'Link',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.VSR_INDEX,
            path: '/virtualshowroom',
            component: VsrIndex,
            meta: {
                title: 'VSR_INDEX',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.VSR_INDEX,
            path: '/:lang/virtualshowroom',
            component: VsrIndex,
            meta: {
                title: 'VSR_INDEX',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.VSR_LOBBY,
            path: '/:lang/virtualshowroom/lobby',
            component: VsrLobby,
            meta: {
                title: 'VSR_LOBBY',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.VSR_MTO,
            path: '/:lang/virtualshowroom/mTo',
            component: VsrMTo,
            meta: {
                title: 'VSR_MTO',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.VSR_MHI,
            path: '/:lang/virtualshowroom/mHi',
            component: VsrMHi,
            meta: {
                title: 'VSR_MHI',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.VSR_MPOP,
            path: '/:lang/virtualshowroom/mPop',
            component: VsrPop,
            meta: {
                title: 'VSR_MPOP',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.VSR_M2GO,
            path: '/:lang/virtualshowroom/m2go',
            component: VsrM2go,
            meta: {
                title: 'VSR_M2GO',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.VSR_TECH_GALLERY,
            path: '/:lang/virtualshowroom/techGallery',
            component: VsrTechGallery,
            meta: {
                title: 'VSR_TECH_GALLERY',
                requiresAuth: true,
            }
        },
        {
            name: ROUTE_NAME.VSR_MOBIS_VISION,
            path: '/:lang/virtualshowroom/mobisVision',
            component: VsrMobisVision,
            meta: {
                title: 'VSR_MOBIS_VISION',
                requiresAuth: false,
            }
        },
        {
            name: ROUTE_NAME.VSR_MOBIS_NOW,
            path: '/:lang/virtualshowroom/mobisNow',
            component: VsrMobisNow,
            meta: {
                title: 'VSR_MOBIS_NOW',
                requiresAuth: false,
            }
        }
    ]
});

function convertToStatisticMenu(name: string): MENU_NAME {
    // @ts-ignore
    return convertToMenuName[name.toUpperCase()];
}

router.beforeEach((to, from, next) => {
    if (!OAuth.hasToken()) {
        AuthModule.clearUser();
    }

    if (OAuth.token && !Session.hasToken()) {
        Session.clearToken();
        AuthModule.actionGetSessionToken();
    }

    if (from?.name) {
        switch (from.name) {
            case 'meeting':
            case 'qna':
                GnbModule.actionMenuShowEnd(convertToStatisticMenu(from.name));
                break;
        }
    }

    if (to?.name) {
        switch (to.name) {
            case 'meeting':
            case 'qna':
                GnbModule.actionMenuShowStart(convertToStatisticMenu(to.name));
                break;
        }
    }

    next();
})
router.beforeEach(I18nMiddleware)
router.beforeEach(AuthMiddleware)

export { menu, router, userMenu };
