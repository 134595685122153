











































import Vue from "vue";
import { Component } from "vue-property-decorator";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueButton from "@/components/Button/VueButton.vue";
import AuthModule from "@/store/modules/AuthModule";

@Component({
	components: {
		VueCheck,
		VueButton,
	},
})
export default class VueUnregisterConfirm extends Vue {
    check: boolean = false

    validate() {
        let valid = true

        if (!this.check) {
            alert(this.$t('validate.unregister.confirm2').toString());
            valid = false
        }

        return valid
    }

    async submit() {
        if (this.validate()) {
            if (confirm(this.$t('validate.unregister.confirm1').toString())) {
                // todo: 회원탈퇴 api 400 에러 발생.
                await AuthModule.actionWithdrawal()
                this.$emit('update:switcher', 'VueUnregister')
            }
        }
    }
}
