import store from '@/store';
import {Action, getModule, Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    IMainMeeting,
    IMeeting,
    IMeetingCancelReq,
    IMeetingEdit,
    IMeetingList,
    IMeetingState,
    MeetingDetail,
    MeetingEdit,
    MeetingEditDataRes,
    MeetingRes,
} from "@/store/models/Meeting/MeetingModel";
import {
    reqMainMeetingList,
    reqMeetingCancel,
    reqMeetingDetail,
    reqMeetingEdit,
    reqMeetingEditData,
    reqMeetingList, reqMeetingNew,
    reqMeetingProduct,
    reqMeetingProductCategory,
    reqMeetingUserConfirm
} from "@/store/api/MeetingApi";
import moment from "moment";

@Module({
    store,
    name: "meeting",
    namespaced: true,
    dynamic: true,
})
class MeetingModule extends VuexModule implements IMeetingState {

    mainMeetings: IMainMeeting[] = [];
    meetingList: IMeetingList = {
        reserveList: [],
        requestList: [],
        completeList: [],
        cancelList: []
    }
    meetingDetail: IMeeting = new MeetingDetail()
    meetingEditData: IMeetingEdit = new MeetingEdit()

    @MutationAction
    async actionMainMeetingList() {
        const mainMeetingListRes = await reqMainMeetingList();

        return {
            mainMeetings: mainMeetingListRes.data
        };
    }

    @MutationAction
    async actionMeetingList() {
        const meetingListRes = await reqMeetingList();

        return {
            meetingList: meetingListRes.data
        };
    }

    /**
     * 제품 카테고리 요청 API
     *
     * @param parentCode
     */
    @Action
    async actionMeetingProductCategory(parentCode?: number) {
        return await reqMeetingProductCategory(parentCode);
    }

    /**
     * 제품 요청 API
     *
     * @param parentCode
     */
    @Action
    async actionMeetingProduct(parentCode?: number) {
        return await reqMeetingProduct(parentCode);
    }

    /**
     * 미팅 > 등록/수정
     */
    @Action
    async editMeeting(
        meetingEditReq: IMeetingEdit
    ) {
        const meetingData: IMeetingEdit = {
            ...meetingEditReq
        };

        meetingData.requestDescription = `\r\n\r\n----\r\n[${moment().format('YYYY-MM-DD hh:mm:ss')} (GMT${moment().format('Z')})]\r\n\r\n`
            + meetingData.requestDescription;

        return await reqMeetingEdit(meetingData);
    }

    /**
     * 미팅 > 상세정보
     */
    @MutationAction
    async actionMeetingDetail(pk: number) {
        const meetingDetailRes: MeetingRes = await reqMeetingDetail(pk);

        return {
            meetingDetail: meetingDetailRes.data
        }
    }

    /**
     * 미팅 > 미팅 수정 데이터
     */
    @MutationAction
    async actionMeetingEditData(pk: number) {
        const meetingEditDataRes: MeetingEditDataRes = await reqMeetingEditData(pk);

        return {
            meetingEditData: meetingEditDataRes.data
        }
    }

    /**
     * 미팅 > 취소
     */
    @Action
    async actionMeetingCancel(meetingCancelReq: IMeetingCancelReq) {
        return await reqMeetingCancel(meetingCancelReq);
    }

    /**
     * 미팅 > 사용자 확인
     * @param meetingPk
     */
    @Action
    async actionMeetingUserConfirm(meetingPk: number) {
        return await reqMeetingUserConfirm(meetingPk);
    }

    /**
     * 미팅 > 신규화면 기본값
     */
    @Action
    async actionMeetingNew() {
        return await reqMeetingNew();
    }
}

export default getModule(MeetingModule)
