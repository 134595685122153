import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {IAuth} from "@/store/models/Auth/AuthModel";
import {ILang} from "@/store/models/Lang/LangModel";

Vue.use(Vuex);

export interface IRootState {
    auth: IAuth,
    lang: ILang
}

export default new Vuex.Store<IRootState>({
    plugins: [
        createPersistedState()
    ]
});
