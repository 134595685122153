import {NavigationGuard} from "vue-router";
import {OAuth} from "@/libs/OAuth";
import {ROUTE_NAME} from "@/router";
import store from "@/store";
import AuthModule from "@/store/modules/AuthModule";


const beforeEach: NavigationGuard = async function (to, from, next) {
    if (!to.query?.uuid || !to.query?.type) {
        next({
            name: ROUTE_NAME.ERRORCERT,
            params: to.params,
        })
    } else {
        next();
    }
}

export default beforeEach;
