import Vue from "vue";
import Utils from "@/libs/Utils";

const UtilityServiceProvider = {
    install: (vue: typeof Vue, options?: any) => {
        vue.mixin({
            created() {
                vue.prototype.$utils = new Utils();
            }
        })
    }
}

export default UtilityServiceProvider;
