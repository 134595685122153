







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import VueInput from "@/components/Form/VueInput.vue";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueTextarea from "@/components/Form/VueTextarea.vue";
import VueSortingCheck from "@/components/Form/VueSortingCheck.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueCalendar from "@/components/Form/VueCalendar.vue";
import VueTab from "@/components/Tab/VueTab.vue";
import VueListTab from "@/components/Tab/VueListTab.vue";
import VueModal from "@/components/Modal/VueModal.vue";
import VueTag from "@/components/Tag/VueTag.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueTitle from "@/components/Title/VueTitle.vue";
import VueCard from "@/components/Card/VueCard.vue";
import List from "@/components/List/index.vue";
import VueSpinner from "@/components/Spinner/VueSpinner.vue";
import { ICheck, IOption } from "@/store/models/Form/FormModel";
import {ICommonCode} from "@/store/models/CommonModel";

@Component({
	components: {
		VueInput,
		VueCheck,
		VueTextarea,
		VueSortingCheck,
		VueSelect,
		VueCalendar,
		VueTab,
		VueListTab,
		VueModal,
		VueTag,
		VueButton,
		VueTitle,
		VueCard,
		List,
		VueSpinner,
	},
	metaInfo() {
		return {
			title: this.$route.meta.title,
		};
	},
})
export default class StyleGuide extends Vue {
	//@State(state => state.meeting.meetingData) meetingData!: IMainMeeting[];
	//meeting data
	meetingData: object[] = [
		{
			dDay: "D-DAY",
			tag: "예정",
			title: "운전자지원시스템(DAS) 기술 미팅",
			date: "Aug 20, 2021",
			time: "10:00",
			name: "윤근 오",
			company: "현대모비스",
			country: "한국",
			email: "oyk33@mobis.co.kr",
			buttonType: ["join"],
		},
		{
			dDay: "D-5",
			tag: "예정",
			title: "운전자지원 시스템(DAS)",
			date: "Aug 20, 2021",
			time: "10:00",
			name: "윤근 오",
			company: "현대모비스",
			country: "한국",
			email: "oyk33@mobis.co.kr",
			buttonType: ["meeting-cancel"],
		},
		{
			dDay: "D-10",
			tag: "예정",
			title: "운전자지원 시스템(DAS)",
			date: "Aug 20, 2021",
			time: "10:00",
			name: "윤근 오",
			company: "현대모비스",
			country: "한국",
			email: "oyk33@mobis.co.kr",
			buttonType: ["change", "meeting-cancel"],
		},
		{
			dDay: "",
			tag: "신청",
			title: "운전자지원 시스템(DAS)",
			date: "Aug 20, 2021",
			time: "10:00",
			name: "윤근 오",
			company: "현대모비스",
			country: "한국",
			email: "oyk33@mobis.co.kr",
			buttonType: ["content-check"],
		},
		{
			dDay: "",
			tag: "취소",
			title: "운전자지원 시스템(DAS)",
			date: "Aug 20, 2021",
			time: "10:00",
			name: "윤근 오",
			company: "현대모비스",
			country: "한국",
			email: "oyk33@mobis.co.kr",
			buttonType: ["content-check"],
		},
		{
			dDay: "",
			tag: "완료",
			title: "운전자지원 시스템(DAS)",
			date: "Aug 20, 2021",
			time: "10:00",
			name: "윤근 오",
			company: "현대모비스",
			country: "한국",
			email: "oyk33@mobis.co.kr",
			buttonType: ["content-check"],
		},
	];

	isModal: boolean = false;
	isLogin: boolean = false;
	isInput: boolean = false;
	isCheckbox: boolean = false;
	isCombobox: boolean = false;
	isTab: boolean = false;
	isTag: boolean = false;
	isButton: boolean = false;
	isMainTitle: boolean = false;
	isCardList: boolean = false;
	isQnaLis: boolean = false;

	activeTab: number = 0;

	textValue: string = "";

	guideList: object[] = [
		{ kind: "Color" },
		{ kind: "Title" },
		{ kind: "Form" },
		{ kind: "List" },
		{ kind: "Button" },
		{ kind: "Tag" },
		{ kind: "Tab" },
		{ kind: "Modal" },
		{ kind: "FormBox" },
		{ kind: "Spinner" },
	];

	changeTab(key: number) {
		this.activeTab = key;
	}

	//input clear
	emailText: string = "";

	//select
	selectList: IOption[] = [
		{
			text: "ENG",
			value: "en",
			disabled: false,
		},
		{
			text: "한국어",
			value: "ko",
			disabled: false,
		},
		{
			text: "中文",
			value: "ch",
			disabled: false,
		},
		{
			text: "日本語",
			value: "jp",
			disabled: true,
		},
	];

	//tab
	activeIndex: number = 0;
	
	tabsList: ICommonCode[] = [
		{
			name: "아이디 찾기",
			pk: 0
		},
		{
			name: "비밀번호 재설정",
			pk: 1
		}
	]

	setSelected(tab: number) {
		this.activeIndex = tab;
	}

	//modal
	modalType1: boolean = false;
	modalType2: boolean = false;

	toggleModal(name: string) {
		if (name === "type1") {
			this.modalType1 = !this.modalType1;
		} else {
			this.modalType2 = !this.modalType2;
		}
	}

	imageCardDataSimpleType: Object[] = [
		{
			category: "기술",
			title: "정보 제어 시스템 디스플레이와 헤드유닛",
			src: require("@/assets/images/temp/@tmp_thumb01.jpg"),
			type: "simple",
		},
		{
			category: "임포테인먼트",
			title: "다양한 정보를 디스플레이 전면 모니터 및 터치 제어 장치",
			src: require("@/assets/images/temp/@tmp_thumb01.jpg"),
			type: "simple",
		},
		{
			category: "임포테인먼트",
			title: "다양한 정보를 디스플레이 전면 모니터 및 터치 제어 장치",
			src: require("@/assets/images/temp/@tmp_thumb01.jpg"),
			type: "simple",
		},
		{
			category: "임포테인먼트",
			title: "다양한 정보를 디스플레이 전면 모니터 및 터치 제어 장치",
			src: require("@/assets/images/temp/@tmp_thumb01.jpg"),
			type: "simple",
		},
		{
			category: "임포테인먼트",
			title: "다양한 정보를 디스플레이 전면 모니터 및 터치 제어 장치",
			src: require("@/assets/images/temp/@tmp_thumb01.jpg"),
			type: "simple",
		},
	];

	//sorting button
	sortingList: ICheck[] = [
		{
			text: "#IVI",
			name: "name",
			value: false,
		},
		{
			text: "#자율주행",
			name: "name",
			value: false
		},
	];

	imageCardDataDetailType: Object[] = [
		{
			category: "기술",
			title: "정보 제어 시스템 디스플레이와 헤드유닛",
			subTitle:
				"현대모비스, 완전 자율주행 모빌리티 미래 모빌리티 세상이 궁금하신가요?!",
			src: require("@/assets/images/temp/@tmp_thumb01.jpg"),
			type: "detail",
		},
		{
			category: "임포테인먼트",
			title: "다양한 정보를 디스플레이 전면 모니터 및 터치 제어 장치",
			subTitle: "스마트폰을 연결해 차량을 컨트롤하는 미래 모빌리티",
			src: require("@/assets/images/temp/@tmp_thumb01.jpg"),
			type: "detail",
		},
		{
			category: "임포테인먼트",
			title: "다양한 정보를 디스플레이 전면 모니터 및 터치 제어 장치",
			subTitle: "스마트폰을 연결해 차량을 컨트롤하는 미래 모빌리티",
			src: require("@/assets/images/temp/@tmp_thumb01.jpg"),
			type: "detail",
		},
		{
			category: "기술",
			title: "정보 제어 시스템 디스플레이와 헤드유닛",
			subTitle:
				"현대모비스, 완전 자율주행 모빌리티 미래 모빌리티 세상이 궁금하신가요?!",
			src: require("@/assets/images/temp/@tmp_thumb01.jpg"),
			type: "detail",
		},
		{
			category: "임포테인먼트",
			title: "다양한 정보를 디스플레이 전면 모니터 및 터치 제어 장치",
			subTitle: "스마트폰을 연결해 차량을 컨트롤하는 미래 모빌리티",
			src: require("@/assets/images/temp/@tmp_thumb01.jpg"),
			type: "detail",
		},
		{
			category: "임포테인먼트",
			title: "다양한 정보를 디스플레이 전면 모니터 및 터치 제어 장치",
			subTitle: "스마트폰을 연결해 차량을 컨트롤하는 미래 모빌리티",
			src: require("@/assets/images/temp/@tmp_thumb01.jpg"),
			type: "detail",
		},
	];

	textCardData: Object[] = [
		{
			dDay: "D-DAY",
			tag: "예정",
			title: "운전자지원 시스템(DAS)",
			date: "Aug 20, 2021",
			time: "10:00",
			buttonType: ["join", "change"],
		},
		{
			dDay: "D-DAY",
			tag: "신청",
			title: "운전자지원 시스템(DAS)",
			date: "Aug 20, 2021",
			time: "10:00",
			buttonType: ["change"],
		},
		{
			dDay: "D-DAY",
			tag: "취소",
			title: "운전자지원 시스템(DAS)",
			date: "Aug 20, 2021",
			time: "10:00",
			buttonType: ["content-check"],
		},
	];

	qnaListData: Object[] = [
		{
			sort: "문의",
			title: "모비스 부품대리점과 직영판매점(사업소) 간에 부품 가격이 다를 수 있나요?",
			date: "2021년 7월 28일",
			state: "답변완료",
			inquiryContent:
				"모비스 부품대리점과 직영판매점(사업소) 간에 부품 가격이 순정부품과 KS규격부품은 모두 인증제품이라는 면에서는 서로 같습니다. 모비스 부품대리점과 직영판매점(사업소) 간에 부품 가격이 모두 인증제품이라는 면에서는 서로 같습니다. 모비스 부품대리점과 직영판매점(사업소) 간에 부품 가격이 다를 수 있나요?",
			add: [
				{
					file: "https://www.mobis.co.kr/communityid/2/list.do?categoryId=804",
					link: "Abc.jpg, Brochure.zip, catalog.zip",
				},
			],
			answerContent:
				"WPC상의 가격은 공임이 포함되지 않은 부가가치세가 포함된 소비자 가격입니다. 즉, 모비스 직영부품 사업장(직영판매점)에서 구입하실 때 받는 가격입니다.다만, 대리점이 직영판매점과 동일가격으로 판매 여부는 해당 대리점 자율에 맡겨져 있습니다.",
		},
		{
			sort: "문의",
			title: "모비스 부품대리점과 직영판매점(사업소) 간에 부품 가격이 다를 수 있나요?",
			date: "2021년 7월 28일",
			state: "답변완료",
			inquiryContent:
				"모비스 부품대리점과 직영판매점(사업소) 간에 부품 가격이 순정부품과 KS규격부품은 모두 인증제품이라는 면에서는 서로 같습니다. 모비스 부품대리점과 직영판매점(사업소) 간에 부품 가격이 모두 인증제품이라는 면에서는 서로 같습니다. 모비스 부품대리점과 직영판매점(사업소) 간에 부품 가격이 다를 수 있나요?",
			add: [
				{
					file: "https://www.mobis.co.kr/communityid/2/list.do?categoryId=804",
					link: "Abc.jpg, Brochure.zip, catalog.zip",
				},
			],
			answerContent:
				"WPC상의 가격은 공임이 포함되지 않은 부가가치세가 포함된 소비자 가격입니다. 즉, 모비스 직영부품 사업장(직영판매점)에서 구입하실 때 받는 가격입니다.다만, 대리점이 직영판매점과 동일가격으로 판매 여부는 해당 대리점 자율에 맡겨져 있습니다.",
		},
	];

	/**
	 * list tab active
	 */
	tabActive(key: number) {
		this.activeIndex = key;
	}

	tabList: ICommonCode[] = [
		{
            name: "내 정보",
            pk: 0,
		},
		{
            name: "북마크",
            pk: 1,
		},
	];

	dataTabList: ICommonCode[] = [
		{
            name: "#전체",
            pk: 0,
		},
		{
            name: "#IVI",
            pk: 1,
		},
		{
            name: "#자율주행",
            pk: 2,
		},
		{
            name: "#조명",
            pk: 3,
		},
		{
            name: "#전기구동",
            pk: 4,
		},
		{
            name: "#자율주행",
            pk: 5,
		},
		{
            name: "#자율주행",
            pk: 6,
		},
	];
}
