import store from '@/store';
import {getModule, Module, MutationAction, VuexModule, Action} from "vuex-module-decorators";
import {
    ExhibitionListRes,
    IExhibition,
    IExhibitionState,
    IMainExhibition,
    MainExhibitionListRes
} from "@/store/models/Exhibition/ExhibitionModel";
import {reqExhibitionList, reqExhibitionUpCount, reqMainExhibitionList} from "@/store/api/ExhibitionApi";
import LangModule from "@/store/modules/LangModule";
import {ICommonCode} from "@/store/models/CommonModel";
import {reqCommonCode} from "@/store/api/CommonApi";
import {
    ExhibitionSiteContentListRes,
    IExhibitionSiteContent, IExhibitionSiteContentListReq,
    IExhibitionSiteState
} from "@/store/models/ExhibitionSite/ExhibitionSiteModel";
import {reqExhibitionSiteContentList, reqExhibitionSiteContentUpCount} from "@/store/api/ExhibitionSiteApi";

@Module({
    store,
    name: 'exhibitionSite',
    namespaced: true,
    dynamic: true
})
class ExhibitionSiteModule extends VuexModule implements IExhibitionSiteState {

    exhibitionSiteContentList: IExhibitionSiteContent[] = [];

    /**
     * 메인 전시 리스트
     */
    @MutationAction
    async actionExhibitionSiteContentList(req: IExhibitionSiteContentListReq) {
        const exhibitionSiteContentListRes: ExhibitionSiteContentListRes = await reqExhibitionSiteContentList(req);

        return {
            exhibitionSiteContentList: exhibitionSiteContentListRes.items
        };
    }

    /**
     * 조회수 증가
     * @param exhibitionSiteContentPk
     */
    @Action
    async actionExhibitionSiteContentUpCount(exhibitionSiteContentPk: number) {
        await reqExhibitionSiteContentUpCount(exhibitionSiteContentPk);
    }
}

export default getModule(ExhibitionSiteModule);
