
























































































































import {CardItemMixin} from "@/components/Mixins/CardItemMixin";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import VueButton from "@/components/Button/VueButton.vue";
import QnaModule from "@/store/modules/QnaModule";
import {ObjType} from "@/store/models/Form/FormModel";
import {State} from "vuex-class";
import {IQuestionType, QnaStatus} from "@/store/models/Qna/QnaModel";

@Component({
    components: {
        VueButton
    }
})
export default class ListItem extends CardItemMixin {
    @State((state) => state.qna.questionTypes) questionTypes!: IQuestionType[];

    @Prop({default: false}) isActive!: boolean;

    state: string = ''

    get questionTypeList(): ObjType {
        let list: ObjType = {}

        this.questionTypes.forEach(item => {
            list[item.code] = item.name
        })

        return list
    }

    /**
     * nl2br
     */
    getContentHtml(content: string) {
        return content.replace(/(\n|\r\n)/g, '<br>');
    }

    async removeQna() {
        if (confirm(this.$t('qna_page.inquiry_list.alert.delete_inquiry').toString())) {
            await QnaModule.actionQnaRemove(this.item.pk)
        }
    }

    mounted() {
        if (this.item.state == QnaStatus.COMPLETE) {
            this.state = this.$t('qna_page.inquiry_list.state.complete').toString()
        } else {
            this.state = this.$t('qna_page.inquiry_list.state.wait').toString()
        }
    }
}
