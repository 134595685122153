import {BaseRes, IAsset} from "@/store/models/BaseModel";

export interface ICommonCode {
    pk: number; // 코드 기본키
    parentPk?: number; // 상위 코드
    name: string; // 코드 이름
}

export interface ICommonCodeReq {
    parentPk: number; // 요청 상위 코드
}

/**
 * 공통코드 Res
 */
export class CommonCodeRes extends BaseRes {

    commonCodes!: ICommonCode[];

    constructor() {
        super();
    }
}

/**
 * 파일업로드 기본 인터페이스
 */
export interface IUploadReq {
    target: string; // 에셋 저장 대상 (QNA, ...)
    files: File[]; // 파일s
}

/**
 * 업로드 Res 모델
 */
export interface IUpload {
    assets: IAsset[];
}

/**
 * 업로드 리스폰스
 */
export class UploadRes extends BaseRes {

    data!: IUpload;

    constructor() {
        super();

    }
}
