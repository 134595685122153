import {BaseRes, IAsset} from "@/store/models/BaseModel";

export interface IMainKeyvisual {
    pk: number;
    title: string;
    content: string;
    visualType: string;
    assetImgPc: IAsset;
    assetImgMob: IAsset;
    movUrl: string;
    linkPcUrl: string;
    linkPcTarget: string;
    linkMobUrl: string;
    linkMobTarget: string;
}

export interface IKeyvisualState {
    mainKeyvisuals: IMainKeyvisual[];
}

export class MainKeyvisualListRes extends BaseRes {
    items!: IMainKeyvisual[];

    constructor() {
        super();
    }
}
