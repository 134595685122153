


































































































































































import Vue from "vue";
import Component from "vue-class-component";
import VueModal from "@/components/Modal/VueModal.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueInput from "@/components/Form/VueInput.vue";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueTextarea from "@/components/Form/VueTextarea.vue";
import VueSortingCheck from "@/components/Form/VueSortingCheck.vue";
import VueCalendar from "@/components/Form/VueCalendar.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import {State} from "vuex-class";
import {
    IMeetingEdit,
    IMeetingEditReqValidate,
    IMeetingUser,
    MainMeetingStatus,
} from "@/store/models/Meeting/MeetingModel";
import Lang from "@/store/modules/LangModule";
import {ICheck, IOption} from "@/store/models/Form/FormModel";
import MeetingModule from "@/store/modules/MeetingModule";
import {Prop, Watch} from "vue-property-decorator";

@Component({
    components: {
        VueModal,
        VueInput,
        VueCheck,
        VueTextarea,
        VueSelect,
        VueSortingCheck,
        VueButton,
        VueCalendar,
        VueValidator,
    },
})
export default class MeetingModifyModal extends Vue {
    @State((state) => state.meeting.meetingEditData) meetingEditData!: IMeetingEdit;
    @State((state) => state.lang.locale) locale!: string;

    @Prop({default: MainMeetingStatus.REQUEST}) meetingStatus!: string;
    MainMeetingStatus = MainMeetingStatus;

    meetingData: IMeetingEdit = {
        pk: 0,
        title: "",
        reason: "",
        productCategoryPk1: 0,
        productCategoryPk2: 0,
        siteLang: Lang.getLocaleToLang,
        requestAnywhen: true,
        status: "",
        requestMeetingAt: "",
        requestPlatform: "",
        requesterJoin: true,
        requestDescription: "",
        meetingUsers: [],
    };

    validator: IMeetingEditReqValidate = {
        title: "",
        business: "",
        requestPlatform: "",
        meetingUsers: "",
        requestDescription: "",
    };

    meetingUsers: IMeetingUser[] = [];
    productCategoryList: IOption[] = [];
    productSubCategoryList: IOption[] = [];
    productList: IOption[] = [];
    meetingUser: number = 0;
    meetingTime: string = "00:00";
    meetingAt: string = "";

    productCategoryText: string = this.$t('meeting_page.modify.section2.category').toString();
    productSubCategoryText: string = this.$t('meeting_page.modify.section2.classification').toString();
    productText: string = this.$t('meeting_page.modify.section2.name').toString();

    $refs!: {
        requestDescription: HTMLInputElement;
        requesterJoin: HTMLInputElement;
        title: HTMLInputElement;
    };

    meetingPlatformList: ICheck[] = [];

    isChangeRequestMeetingAt: boolean = false;

    getMeetingProductCategory(type: string, pk?: number) {
        MeetingModule.actionMeetingProductCategory(pk).then((res) => {
            for (const item of res) {
                if (type == "category") {
                    this.productCategoryList.push({
                        text: item.name,
                        value: item.pk,
                    });

                    if (item.pk == this.meetingData.productCategoryPk1) {
                        this.productCategoryText = item.name;
                    }
                } else if (type === "subCategory") {
                    this.productSubCategoryList.push({
                        text: item.name,
                        value: item.pk,
                    });

                    if (item.pk == this.meetingData.productCategoryPk2) {
                        this.productSubCategoryText = item.name;
                    }
                }
            }
        });
    }

    getMeetingProduct(pk: number) {
        MeetingModule.actionMeetingProduct(pk).then((res) => {
            for (const item of res) {
                this.productList.push({
                    text: item.name,
                    value: item.pk,
                });

                if (item.pk == this.meetingData.productPk) {
                    this.productText = item.name;
                }
            }
        });
    }

    changeProductCategory(pk: number) {
        let productSubCategoryList: IOption[] = [];
        let productList: IOption[] = [];

        this.productSubCategoryText = "meeting_page.modify.section2.classification";

        productList.push({
            text: "meeting_page.modify.section2.name",
            value: 0,
        });
        this.productText = "meeting_page.modify.section2.name";

        this.productSubCategoryList = productSubCategoryList;
        this.productList = productList;

        if (pk > 0) {
            this.meetingData.productCategoryPk1 = pk;
            this.meetingData.productCategoryPk2 = 0;
            this.getMeetingProductCategory("subCategory", pk);
        } else {
            this.meetingData.productCategoryPk1 = pk;
            this.meetingData.productCategoryPk2 = pk;
        }
    }

    changeProductSubCategory(pk: number) {
        let productList: IOption[] = [];
        this.productList = productList;
        this.productText = "meeting_page.modify.section2.name";

        if (pk > 0) {
            this.meetingData.productCategoryPk2 = pk;
            this.getMeetingProduct(pk);
        } else {
            this.meetingData.productCategoryPk2 = pk;
        }
    }

    changeProduct(pk: number) {
        this.meetingData.productPk = pk;
    }

    setMeetingPlatform(key: number) {
        this.meetingPlatformList.forEach((item, index) => {
            item.value = false;
            if (key === index) {
                item.value = true;
                this.meetingData.requestPlatform = item.name;
            }
        }, this);
    }

    addMeetingUser() {
        if (this.meetingUser < 4) {
            this.meetingUser++;
            this.setMeetingUser();
        }
    }

    removeMeetingUser(key: number) {
        const meetingUser: IMeetingUser[] = [];

        this.meetingUsers.forEach((item, index) => {
            if (key !== index) {
                meetingUser.push(item);
            }
        });

        this.meetingUser--;
        this.meetingUsers = meetingUser;
    }

    setMeetingUser() {
        const meetingUser: IMeetingUser[] = [];

        this.meetingUsers.forEach((item) => {
            meetingUser.push(item);
        });

        meetingUser.push({
            firstName: "",
            lastName: "",
            position: "",
            email: "",
        });

        this.meetingUsers = meetingUser;
    }

    setRequestMeetingAt(value: string) {
        this.meetingData.requestMeetingAt = (value)
            ? this.$moment(value).format("YYYY-MM-DD HH:mm")
            : '';

        this.meetingAt = this.meetingData.requestMeetingAt;

        if (!this.isChangeRequestMeetingAt) {
            this.isChangeRequestMeetingAt = true;
        }
    }

    cancel() {
        this.$emit("close");
    }

    @Watch("locale")
    changeSiteLang() {
        this.meetingData.siteLang = Lang.getLocaleToLang;
    }

    @Watch('meetingData.requestAnywhen')
    changeAnywhen() {
        if (this.meetingData.requestAnywhen) {
            this.meetingData.requestMeetingAt = null;
        } else {
            this.meetingData.requestMeetingAt = (this.meetingAt) ? this.meetingAt : null;
        }
    }

    checkEmptyMeetingUser() {
        const meetingUser: IMeetingUser[] = [];

        this.meetingUsers.forEach((item) => {
            if (item.firstName && item.lastName) {
                meetingUser.push(item);
            }
        });

        this.meetingUsers = meetingUser;
        this.meetingUser = meetingUser.length;
    }

    async approval() {
        let pk: number = (this.meetingData.pk) ? this.meetingData.pk : 0;

        if (pk < 1) {
            alert(this.$t('meeting_page.modify.alert.fail.approval').toString());
            return false;
        }

        if (confirm(this.$t('meeting_page.modify.alert.schedule_approval').toString())) {
            await MeetingModule.actionMeetingUserConfirm(pk)
                .then((res) => {
                    if (res.data.pk) {
                        alert(this.$t('meeting_page.modify.alert.completed_approval').toString());
                        MeetingModule.actionMeetingList();
                        this.$emit("close");
                    } else {
                        alert(this.$t('meeting_page.modify.alert.fail.approval').toString());
                        console.log("meeting 승인 api 에러");
                    }
                })
        }
    }

    get isShowTime() {
        return this.meetingEditData.meetingAt !== null
            && !this.isChangeRequestMeetingAt;
    }

    get getStatusToSubject() {
        return this.meetingData.status === 'RESERVE' && this.meetingData.meetingAt !== null
            ? 'meeting_page.modify.section1.req_manager_subject'
            : 'meeting_page.modify.section1.subject2';
    }

    get getStatusToNotice() {
        return this.meetingData.status === 'RESERVE' && this.meetingData.meetingAt !== null
            ? 'meeting_page.modify.section1.os_time'
            : 'meeting_page.modify.section1.notice';
    }

    validate() {
        let valid = true;
        let focus = false;

        this.validator = {
            title: "",
            business: "",
            requestPlatform: "",
            meetingUsers: "",
            requestDescription: "",
        };

        if (this.meetingData.title.length < 1) {
            this.validator.title = 'meeting_page.modify.alert.enter_subject';
            valid = false;

            if (!focus) {
                focus = true;
            }
        }

        if (
            this.meetingData.productCategoryPk1 < 1 ||
            this.meetingData.productCategoryPk2 < 1
        ) {
            this.validator.business = "validate.meeting.modify.business.required";
            valid = false;

            if (!focus) {
                focus = true;
            }
        }

        if (this.meetingData.requestPlatform.length < 1) {
            this.validator.requestPlatform = "validate.meeting.modify.request_platform.required";
            valid = false;

            if (!focus) {
                focus = true;
            }
        }

        /*if (!this.meetingData.requesterJoin && this.meetingUsers.length < 1) {
            this.validator.meetingUsers = "validate.meeting.modify.meeting_users.required";
            valid = false;

            if (!focus) {
                this.$refs.requesterJoin.focus();
                focus = true;
            }
        }*/

        if (this.meetingData.requestDescription.length < 1) {
            this.validator.requestDescription = "validate.meeting.modify.request_description.required";
            valid = false;

            if (!focus) {
                this.$refs.requestDescription.focus();
                focus = true;
            }
        }

        return valid;
    }

    async edit() {
        if (confirm(this.$t('meeting_page.modify.alert.modify_meeting').toString())) {
            if (
                !this.meetingData.requestAnywhen &&
                this.meetingData.requestMeetingAt === ""
            ) {
                this.meetingData.requestAnywhen = true;
                this.meetingData.requestMeetingAt = null;
            }

            if (this.meetingData.requestMeetingAt === 'Invalid date') {
                this.meetingData.requestMeetingAt = null;
            }

            this.checkEmptyMeetingUser();
            this.meetingData.meetingUsers = this.meetingUsers;
            this.meetingData.requestTimeZone = this.$moment().format('Z');

            if (this.validate()) {
                await MeetingModule.editMeeting(this.meetingData)
                    .then((res: any) => {
                        if (res.pk) {
                            alert(this.$t('meeting_page.modify.alert.end_modify').toString());
                            MeetingModule.actionMeetingList();
                            this.$emit("close");
                        } else {
                            alert(this.$t('meeting_page.modify.alert.fail_modify').toString())
                            console.log("meeting 수정 api 에러");
                        }
                    }
                );
            }
        }
    }

    created() {
        this.productCategoryList.push({
            text: "meeting_page.modify.section2.category",
            value: 0,
        });

        this.productSubCategoryList.push({
            text: "meeting_page.modify.section2.classification",
            value: 0,
        });

        this.productList.push({
            text: "meeting_page.modify.section2.name",
            value: 0,
        });

        this.getMeetingProductCategory("category");

        this.meetingData.pk = this.meetingEditData.pk;
        this.meetingData.requestAnywhen = this.meetingEditData.requestAnywhen;
        this.meetingData.status = this.meetingEditData.status;
        this.meetingData.title = this.meetingEditData.title;

        if (this.meetingEditData.lastUpdator !== 'ADMIN'
            && this.meetingEditData.status !== 'RESERVE'
            && this.meetingEditData.requestAnywhen
            && this.meetingEditData.requestMeetingAt === null)
        {
            this.meetingTime = "00:00";
            this.meetingAt = "";
            this.meetingData.requestMeetingAt = null;
        } else if (this.meetingEditData.lastUpdator == 'USER'
            || this.meetingEditData.status === MainMeetingStatus.REQ_MANAGER) {
            this.meetingData.requestMeetingAt = this.$moment(
                this.meetingEditData.requestMeetingAt
            ).format("YYYY-MM-DD HH:mm");

            this.meetingData.requestAnywhen = this.meetingEditData.requestAnywhen;

            if (this.meetingStatus === MainMeetingStatus.REQUEST) {
                this.meetingTime = this.$moment(this.meetingEditData.requestMeetingAt).format("HH:mm");
                this.meetingAt = this.$moment(this.meetingEditData.requestMeetingAt).format('YYYY-MM-DD HH:mm');
            } else {
                this.meetingTime = this.$moment(this.meetingEditData.meetingAt).format("HH:mm");
                this.meetingAt = this.$moment(this.meetingEditData.meetingAt).format('YYYY-MM-DD HH:mm');
            }
        } else {
            this.meetingData.requestMeetingAt = this.$moment(
                this.meetingEditData.meetingAt
            ).format("YYYY-MM-DD HH:mm");

            this.meetingData.requestAnywhen = false;
            if (this.meetingStatus === MainMeetingStatus.REQUEST) {
                this.meetingTime = this.$moment(this.meetingEditData.meetingAt).format("HH:mm");
                this.meetingAt = this.$moment(this.meetingEditData.meetingAt).format('YYYY-MM-DD HH:mm');
            } else {
                this.meetingTime = this.$moment(this.meetingEditData.meetingAt).format("HH:mm");
                this.meetingAt = this.$moment(this.meetingEditData.meetingAt).format('YYYY-MM-DD HH:mm');
            }
        }

        this.meetingData.productCategoryPk1 = this.meetingEditData.productCategoryPk1;
        this.meetingData.productCategoryPk2 = this.meetingEditData.productCategoryPk2;
        this.meetingData.requesterJoin = this.meetingEditData.requesterJoin;
        this.meetingData.requestPlatform = this.meetingEditData.requestPlatform;
        this.meetingData.requestDescription = this.meetingEditData.requestDescription;

        this.changeProductCategory(this.meetingEditData.productCategoryPk1);
        this.changeProductSubCategory(this.meetingEditData.productCategoryPk2);

        if (this.meetingEditData.meetingUsers) {
            this.meetingUsers = this.meetingEditData.meetingUsers;
            this.meetingUser = this.meetingEditData.meetingUsers.length;
        }

        if (this.meetingEditData.platforms) {
            this.meetingEditData.platforms.forEach((item) => {
                this.meetingPlatformList.push({
                    text: item.name,
                    name: item.value,
                    value: false,
                });
            }, this);
        }

        this.meetingPlatformList.forEach((item) => {
            item.value = false;
            if (item.name == this.meetingData.requestPlatform) {
                item.value = true;
                this.meetingData.requestPlatform = item.name;
            }
        }, this);
    }

    get calendarDisabled() {
        return this.meetingData.requestAnywhen
            || this.meetingEditData.meetingAt
            || this.meetingEditData.status === 'REQ_MANAGER'
            || this.meetingEditData.status === 'REQ_ADMIN'
            || this.meetingEditData.status === 'CHANGE'
    }
}
