












































































import Vue from "vue";
import Component from "vue-class-component";
import { ROUTE_NAME } from "@/router";
import { State } from "vuex-class";
import Layout from "@/layouts/Vsr.vue";
import Auth from "@/store/modules/AuthModule";
import DetailPageMenu from "@/components/Vsr/DetailPageMenu.vue";
import VideoPlayer from "@/components/Vsr/VideoPlayer.vue";
import ProductModule from "@/store/modules/ProductModule";
import ProductDetail from "@/components/Product/Detail.vue";
import VueModal from "@/components/Modal/VueModal.vue";
import {
  VsrPageInfo,
  IVsrProductSummary,
  BASE_S3_URL
} from "@/store/models/Vsr/VsrModel";
import VsrModule from "@/store/modules/VsrModule";

@Component({
  components: {
    Layout,
    DetailPageMenu,
    VideoPlayer,
    ProductDetail,
    VueModal
  }
})
export default class VsrTechGallery extends Vue {
  info = new VsrPageInfo("techGallery");

  isShowIntro = true;
  ROUTE_NAME = ROUTE_NAME;
  showProductsPopup = false;
  popupTitle = "";
  modalCategoryName = "";
  toggleModal = false;
  videoPoster: string = require("@/assets/images/vsr/bg_tech_electronics@2x.jpg");
  prevMuteBgSound = false;
  products: IVsrProductSummary[] = [];

  @State(state => state.lang.originLocale) originLocale!: string;

  get videoPlayer() {
    return this.$refs.videoPlayer as any;
  }

  get isLogin() {
    return Auth.isLogin;
  }

  getProductThumb(productPk: number) {
    return `${BASE_S3_URL}/images/products/${productPk}.jpg`;
  }

  changeMenu(i: number) {
    this.isShowIntro = false;

    this.videoPoster = [
      require("@/assets/images/vsr/bg_tech_electronics@2x.jpg"),
      require("@/assets/images/vsr/bg_tech_lamp@2x.jpg"),
      require("@/assets/images/vsr/bg_tech_epowertrain@2x.jpg")
    ][i];

    const item = this.info.getItemAtIndex(i);

    this.popupTitle = item.title;
    this.products = item.products || [];

    this.videoPlayer.load(BASE_S3_URL + item.video, null);
  }

  showProducts(isShow: boolean) {
    (this.$refs.productsPopup as any).scrollTop = 0;
    this.showProductsPopup = isShow;
  }

  openModal(product: IVsrProductSummary) {
    this.modalCategoryName = product.categoryName;
    this.prevMuteBgSound = VsrModule.muteBgSound;
    VsrModule.setMuteBgSound(true);

    const productPk = this.originLocale == "ch" ? product.pk_ch : product.pk;

    ProductModule.actionProductShow(productPk).then(() => {
      this.toggleModal = true;
      ProductModule.actionProductShowStart(productPk);
    });
  }

  closeModal() {
    VsrModule.setMuteBgSound(this.prevMuteBgSound);
    this.toggleModal = false;
    ProductModule.actionProductShowEnd(0);
  }
}
