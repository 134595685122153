import {Action, getModule, Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import store from "@/store";
import {IQna, IQnaEditReq, IQnaState, IQuestionType, QnaListRes, QuestionTypeRes} from "@/store/models/Qna/QnaModel";
import {reqQnaEdit, reqQnaList, reqQnaRemove, reqQuestionType} from "@/store/api/QnaApi";
import {BaseRes, EditRes, IAsset} from "@/store/models/BaseModel";
import {UploadRes} from "@/store/models/CommonModel";
import {reqUpload} from "@/store/api/UploadApi";

@Module({
    store,
    name: "qna",
    namespaced: true,
    dynamic: true,
})
class QnaModule extends VuexModule implements IQnaState {

    qnaList: IQna[] = [];
    questionTypes: IQuestionType[] = [];
    uploadedFiles: IAsset[] = [];

    @MutationAction
    async actionQnaList() {
        const qnaListRes: QnaListRes = await reqQnaList();

        return {
            qnaList: qnaListRes.items
        };
    }

    @MutationAction
    async actionQuestionType() {
        const questionTypeRes: QuestionTypeRes = await reqQuestionType();

        return {
            questionTypes: questionTypeRes.data
        }
    }

    /**
     * 파일 등록
     * @param asset
     */
    @Mutation
    async mutationAppendFiles(asset: IAsset) {
        this.uploadedFiles.push(asset);
    }

    /**
     * 등록파일 삭제
     * @param asset
     */
    @Mutation
    async mutationRemoveFiles(asset: IAsset) {
        this.uploadedFiles.splice(
            this.uploadedFiles.findIndex(file => file.uuid === asset.uuid),
            1
        );
    }

    @MutationAction
    async actionResetFiles() {
        const files: IAsset[] = []

        return {
            uploadedFiles: files
        }
    }

    @Action
    async actionQnaEdit(
        qnaEditReq: IQnaEditReq
    ) {
        return await reqQnaEdit(qnaEditReq)
    }

    @Action
    async actionQnaRemove(pk: number) {
        const qnaRemoveRes: BaseRes = await reqQnaRemove(pk);

        await this.actionQnaList()

        if (qnaRemoveRes.error) {
            console.log(qnaRemoveRes.error)
        }
    }

    /**
     * 파일 업로드
     * @param files
     */
    @Action
    async actionUploadQna(files: File[]) {
        const uploadRes: UploadRes = await reqUpload({
            target: 'QNA',
            files: files
        });

        uploadRes.data?.assets.map(asset => this.mutationAppendFiles(asset));
    }
}

export default getModule(QnaModule)
