import {NavigationGuard} from "vue-router";
import store from "@/store";
import axios from "axios";
import {i18n} from "@/libs/i18n";

const beforeEach: NavigationGuard = function (to, from, next) {

    if (!to.params.lang) {
        if(localStorage.getItem('vuex')) {
            const locale = JSON.parse(<string>localStorage.getItem('vuex')).lang.locale;
            store.commit('lang/setLocale', locale)
        }

        next('/' + store.state.lang.locale)
    } else if (!store.state.lang.locales.find((el:any) => el === to.params.lang)) {
        if(localStorage.getItem('vuex')) {
            // const locale = JSON.parse(<string>localStorage.getItem('vuex')).lang.locale;
            // store.commit('lang/setLocale', locale)
			store.commit('lang/setLocale', 'en')
        }

        next('/' + store.state.lang.locale + to.fullPath.toLowerCase())
    }

    const locale = to.params.lang
    store.commit('lang/setLocale', locale)

    if (store.state.lang.locales.indexOf(locale) !== -1) {
        const htmlLang = store.state.lang.htmlLang
        i18n.locale = htmlLang
        axios.defaults.headers.common['Accept-Language'] = htmlLang
        document.querySelector('html')?.setAttribute('lang', htmlLang)
    }
    next()
}

export default beforeEach;
