import store from '@/store';
import {Action, getModule, Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {IMediaCenterNews, IMediaCenterState, IMediaCenterViewTarget} from "@/store/models/MediaCenter/MediaCenterModel";
import {reqMediaCenterNewsList, reqMediaCenterUpCount} from "@/store/api/MediaCenterApi";
import LangModule from "@/store/modules/LangModule";
import {reqCommonCode} from "@/store/api/CommonApi";
import {ICommonCode} from "@/store/models/CommonModel";

@Module({
    store,
    name: 'mediaCenter',
    namespaced: true,
    dynamic: true
})
class MediaCenterModule extends VuexModule implements IMediaCenterState {

    mediaCenterNewsList: IMediaCenterNews[] = [];

    mediaCenterCommonCodes: ICommonCode[] = [];

    /**
     * 미디어센터 뉴스 리스트
     *
     * @param commonCodePk
     * @param orderBy
     * @param viewTarget
     */
    @MutationAction
    async actionMediaCenterNewsList({commonCodePk, orderBy}: {commonCodePk: number, orderBy: string}) {
        const mediaCenterNewsListRes = await reqMediaCenterNewsList({
            pageNo: 1,
            rowPerPage: 100,
            orderBy: orderBy,
            siteLang: LangModule.getLocaleToLang,
            commonCodePk: commonCodePk
        });

        return {
            mediaCenterNewsList: mediaCenterNewsListRes.items
        };
    }

    /**
     * 미디어센터 카테고리 코드
     */
    @MutationAction
    async actionMediaCenterCategory() {
        const mediaCenterCommonCode = await reqCommonCode(3);

        return {
            mediaCenterCommonCodes: mediaCenterCommonCode.commonCodes
        }
    }

    /**
     * 미디어센터 조회수 증가
     * @param mediaCenterNewsPk
     */
    @Action
    async actionMediaCenterUpCount(mediaCenterNewsPk: number) {
        return await reqMediaCenterUpCount(mediaCenterNewsPk);
    }

    get normalMediaCenterNewsList() {
        return this.mediaCenterNewsList.filter((item) => {
            return item.viewTarget == IMediaCenterViewTarget.NORMAL
                || item.viewTarget == IMediaCenterViewTarget.ALL;
        })
    }

    get vipMediaCenterNewsList() {
        return this.mediaCenterNewsList.filter((item) => {
            return item.viewTarget == IMediaCenterViewTarget.VIP
                || item.viewTarget == IMediaCenterViewTarget.ALL;
        })
    }
}

export default getModule(MediaCenterModule);
