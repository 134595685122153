import {NavigationGuard} from "vue-router";
import {OAuth} from "@/libs/OAuth";
import {ROUTE_NAME} from "@/router";
import store from "@/store";
import Auth from "@/store/modules/AuthModule"
import LoginModalModule from "@/store/modules/LoginModalModule";

const beforeEach: NavigationGuard = function (to, from, next) {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 인증이 필요한 페이지들
        if (!OAuth.hasToken()) {
            Auth.clearUser()
            LoginModalModule.show(next);
        } else {
            next();
        }
    } else {
        next();
    }
};

export default beforeEach;
