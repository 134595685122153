


















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class VideoPlayer extends Vue {
  @Prop({ required: true, default: [""] }) titles!: string[];

  isExpanded = false;
  index = 0;

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  select(i: number) {
    this.isExpanded = false;
    this.index = i;
    this.$emit("change", i);
  }
}
