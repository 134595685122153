















































import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class VueModal extends Vue {
	@Prop({ default: "" }) maxWidth!: string;
	@Prop({ default: "" }) height!: string;
	@Prop({ default: "" }) modalType!: string;
	@Prop({ default: "" }) scrollType!: string;
	@Prop({ default: 0 }) tabindex!: number;
	@Prop({ default: false }) detailModal!: boolean;

	/**
	 * scroll top
	 */
	scrollTop() {
		let modal = this.$refs.modal as HTMLElement;

		modal.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}

}
