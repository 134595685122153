





















import Component from "vue-class-component";
import { FormMixin } from "@/components/Mixins/FormMixin";
import { Prop } from 'vue-property-decorator';

@Component
export default class VueCheck extends FormMixin {
    @Prop({ default: '' }) width!: string;
    @Prop({ default: 'checkbox' }) buttonType!: string;

	change($event: any) {
		this.$emit("input", $event.target.checked);
	}
}
