import {BaseRes} from "@/store/models/BaseModel";
import {IPartner} from "@/store/models/Partner/PartnerModel";
import {IUserInfoProductCategory} from "@/store/models/Product/ProductModel";

export interface IAuth {
    userInfo: IUserInfo;
}

export interface IUserInfo {
    lastName: string; // 성
    firstName: string; // 이름
    email: string;
    partner: IPartner
    department: string;
    position: string;
    mobileNum: string; // 휴대폰
    phoneNum: string; // 전화번호
    userProducts: IUserInfoProductCategory[];
}

export interface ILoginReqValidate {
    email: string;
    password: string;
}

export interface IRegistryReqValidate {
    name: string;
    partner: string;
    partnerLocalName: string;
    partnerGlobalName: string;
    partnerUrl: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    phone: string;
    mobile: string;
    country: string;
    department: string;
    position: string;
    productCategories: string;
}

export interface IFindEmailReq {
    lastName: string;
    firstName: string;
    mobileNum: string;
    mobileCountryPk: number;
}

export interface IFindEmailReqValidate {
    lastName: string;
    firstName: string;
    mobileNum: string;
}

export interface IFindEmail {
    email: string;
}

export class UserInfo implements IUserInfo{
    lastName!: string;
    firstName!: string;
    email!: string;
    partner!: IPartner;
    department!: string;
    position!: string;
    mobileNum!: string; // 휴대폰
    phoneNum!: string; // 전화번호
    userProducts!: IUserInfoProductCategory[];
}

export interface IResetPassword {
    uuid: string;
    password: string;
    checkPassword: string;
}

export interface ILoginInfo {
    accessToken: string;
    sessionToken: string;
    firstName: string;
    lastName: string;
    userProducts: IUserInfoProductCategory[];
}

export interface ICheckEmailAuthReq {
    uuid: string;
    type: string;
}

export interface IUserPasswordResetReq {
    password: string;
    updatePassword: string;
    checkPassword: string;
}

export class LoginReq {
    email!: string;
    password!: string;
}

export class RegistryReq {
    type!: string;
    lastName!: string;
    firstName!: string;
    email!: string;
    password!: string;
    passwordConfirmation!: string;
    partnerPk!: number | null;
    newPartner?:IPartner;
    department!: string;
    position!: string;
    mobileNum!: string; // 휴대폰
    phoneNum!: string; // 전화번호
    mobileCountryPk!: number; // 휴대폰 국가번호
    phoneCountryPk?: number | null; // 전화번호 국가번호
    countryPk?: number | null;
    siteLang!: string;
    productCategories!: number[];
    agreementTermsOfService!: boolean;
    agreementPrivacyPolicy!: boolean;
    agreementMarketing!: boolean;
}

export class UserLoginRes extends BaseRes {
    data!: ILoginInfo;

    constructor() {
        super();
    }
}

export class UserRegistryRes extends BaseRes {
    accessToken!: string;

    constructor() {
        super();
    }
}

export class UserInfoRes extends BaseRes {
    userInfo!: UserInfo;

    constructor() {
        super();
    }
}

export class FindEmailRes extends BaseRes {
    email!: IFindEmail;

    constructor() {
        super();
    }
}

export class ResetPasswordRes extends BaseRes {
    data!: number;

    constructor() {
        super();
    }
}

/**
 * 패스워드 재설정 1단계 이메일 요청
 */
export class SendPasswordAuthEmailRes extends BaseRes {
    data: {
        email: string;
    } = {
        email: ''
    }

    constructor() {
        super();
    }
}

/**
 * 패스워드 재설정 2단계 uuid 유효성 인증
 */
export class CheckPasswordAuthEmailRes extends BaseRes {
    data: {
        uuid: string;
    } = {
        uuid: ''
    }

    constructor() {
        super();
    }
}

/**
 * 세션토큰
 */
export class SessionTokenRes extends BaseRes {
    data: {
        sessionToken: string;
    } = {
        sessionToken: ''
    }

    constructor() {
        super();
    }
}

/**
 * 이메일 인증
 */
export class CheckEmailAuthRes extends BaseRes {
    data: {
        uuid: string;
        type: string;
    } = {
        uuid: '',
        type: ''
    }

    errors!: any;

    constructor() {
        super();
    }
}

export class UserPasswordResetRes extends BaseRes {

    data: {
        pk: number
    } = {
        pk: 0
    };

    constructor() {
        super();
    }
}
