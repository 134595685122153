






















































































































































import Vue from 'vue'
import Component from "vue-class-component";
import VueButton from "@/components/Button/VueButton.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueInput from "@/components/Form/VueInput.vue";
import VueSortingCheck from "@/components/Form/VueSortingCheck.vue";
import {State} from "vuex-class";
import {IMypageShow} from "@/store/models/User/UserModel";
import Auth from "@/store/modules/AuthModule";
import UserModule from "@/store/modules/UserModule";
import {IProductCategory} from "@/store/models/Product/ProductModel";
import {ROUTE_NAME} from '@/router';
import VueSortingCheckMulti from "@/components/Form/VueSortingCheckMulti.vue";
import VuePhoneInput from "@/components/Form/VuePhoneInput.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import {IOption} from "@/store/models/Form/FormModel";
import CountryModule from "@/store/modules/CountryModule";
import {Watch} from "vue-property-decorator";
import {ICountry} from "@/store/models/Country/CountryModel";

@Component({
  components: {VueValidator, VuePhoneInput, VueSortingCheckMulti, VueButton, VueSelect, VueInput, VueSortingCheck },
})
export default class VueInformation extends Vue {
    UserModule = UserModule;

    ROUTE_NAME = ROUTE_NAME;

    @State(state => state.user.mypageShow)
    mypageShow!: IMypageShow;

    @State(state => state.user.productCategories)
    productCategories!: IProductCategory[];

    @State(state => state.lang.locale)
    locale!: string;

    @State((state) => state.country.countries) countries!: ICountry[];

    phoneCountryCode: string = "mypage_page.information.form.phone_default_option";
    mobileCountryCode: string = "mypage_page.information.form.phone_default_option";

    get countryCodes(): IOption[] {
        return CountryModule.getCountryCodeList;
    }

    @Watch('locale')
    watchLocaleChange() {
        UserModule.actionMypageProductCategoryList();
    }

    selectedCategories: IProductCategory[] = [];

    validator: any = {
        phoneNum: '',
        mobileNum: '',
        productCategoryPks: ''
    }

    /**
     * 유저 이름
     */
    get getUserName() {
        return Auth.getUserName
    }

    created() {
        UserModule.actionMypageProductCategoryList();

        Promise.all([
            UserModule.actionMypageShow(),
            CountryModule.countryList()
        ])
        .then(() => {
            this.countries.map((country) => {
                if (country.pk === this.mypageShow.user.phoneCountryPk) {
                    this.phoneCountryCode = '+' + country.code.padStart(3, '0')
                }

                if (country.pk === this.mypageShow.user.mobileCountryPk) {
                    this.mobileCountryCode = '+' + country.code.padStart(3, '0')
                }
            })
        });
    }

    submit() {
        let valid = true;

        if (!this.mypageShow.user.mobileNum || this.mypageShow.user.mobileNum.length < 11) {
            this.validator.mobileNum = [this.$t("validate.mypage.mobile.required").toString()];
            valid = false;
        }

        if (this.mypageShow.userProduct.productCategoryPks.length < 1) {
            this.validator.productCategoryPks = [this.$t("validate.mypage.product.required").toString()];
            valid = false;
        }

        if (valid && confirm(this.$t("validate.mypage.confirm").toString())) {
            this.initValidate();

            UserModule.actionMypageEdit({
                phoneNum: (this.mypageShow.user.phoneNum) ? this.mypageShow.user.phoneNum : '',
                mobileNum: this.mypageShow.user.mobileNum,
                phoneCountryPk: (this.mypageShow.user.phoneCountryPk) ? this.mypageShow.user.phoneCountryPk : null,
                mobileCountryPk: (this.mypageShow.user.mobileCountryPk) ? this.mypageShow.user.mobileCountryPk : 0,
                productCategoryPks: this.mypageShow.userProduct.productCategoryPks,
                department: this.mypageShow.user.department,
                position: this.mypageShow.user.position
            })
            .then(() => this.$router.back())
        }
    }

    initValidate() {
        this.validator = {
            phoneNum: '',
            mobileNum: '',
            productCategoryPks: '',
            department: '',
            position: '',
        }
    }
}
