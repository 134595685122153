







































































































































import Component from "vue-class-component";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueCard from "@/components/Card/VueCard.vue";
import VueListTab from "@/components/Tab/VueListTab.vue";
import VueModal from "@/components/Modal/VueModal.vue";
import {IOption} from "@/store/models/Form/FormModel";
import MediaCenterModule from "@/store/modules/MediaCenterModule";
import {State} from "vuex-class";
import {IMediaCenterNews} from "@/store/models/MediaCenter/MediaCenterModel";
import {Watch} from "vue-property-decorator";
import {ICommonCode} from "@/store/models/CommonModel";
import {isMacOs} from 'mobile-device-detect';
import {PopupMixin} from "@/components/Mixins/PopupMixin";
import IntroLayout from "@/layouts/IntroIndex.vue";
import VueSimpleTitle from "@/components/Title/VueSimpleTitle.vue";

export interface ISwiperElement extends HTMLElement {
    $swiper: any;
}

@Component({
    components: {
      VueSimpleTitle,
        IntroLayout,
        VueSelect,
        VueButton,
        VueCard,
        VueListTab,
        VueModal,
    },
    metaInfo() {
        return {
            title: `News Letter | MOBIS Business Connect.`
        };
    },
})
export default class VipMediaCenter extends PopupMixin {
    @State((state) => state.mediaCenter.mediaCenterCommonCodes)
    mediaCenterCommonCodes!: ICommonCode[];

    @State(state => state.lang.locale)
    locale!: string;

    /**
     * mac os show
     */
    isMacOsShow: boolean = false;

    /**
     * req용 카테고리 코드
     */
    commonCodePk: number | null = null;

    /**
     * req용 orderby
     */
    orderBy: string = "new";

    /**
     * tab active
     */
    activeIndex: number = 0;

    /**
     * 활성 탭 오브젝트
     */
    activeItem: ICommonCode = {
        name: "",
        pk: 0,
    };

    activeMediaNews: IMediaCenterNews | null = null;

    /**
     * modal
     */
    youtubeModal: boolean = false;
    snsModal: boolean = false;

    /**
     * 필터 옵션
     */
    selectList: IOption[] = [
        {
            text: "common.form.order_select.view",
            value: "view",
            disabled: false,
        },
        {
            text: "common.form.order_select.new",
            value: "new",
            disabled: false,
        },
    ];

    /**
     * swiper option
     */
    swiperOption: any = {
        loop: false,
        keyboard: {
            enabled: true,
        },
        slidesPerGroup: 1,
        slidesPerView: 1,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        }
    };

    $refs!: {
        mySwiper: ISwiperElement
    };

    get swiperHandler() {
        return this.$refs.mySwiper.$swiper;
    }

    get commonCodes() {
        let codes = [{
            name: this.$t("common.categories_all").toString(),
            pk: 0
        }]

        this.mediaCenterCommonCodes.map(item => {
            codes.push(item);
        })

        return codes;
    }

    get mediaCenterNewsList() {
        return MediaCenterModule.vipMediaCenterNewsList;
    }

    created() {
        MediaCenterModule.actionMediaCenterCategory();

        if (this.$route.query?.mediaCenterCategoryPk) {
            this.activeItem = {
                name: this.$route.query.name.toString(),
                pk: Number(this.$route.query.mediaCenterCategoryPk)
            }
        } else {
            MediaCenterModule.actionMediaCenterNewsList({
                commonCodePk: this.activeItem.pk,
                orderBy: this.orderBy
            })
            .then(() => {
                if (this.$route.params?.subId) {
                    let item: IMediaCenterNews | undefined = this.mediaCenterNewsList.find((item => item.pk.toString() === this.$route.params.subId));

                    if (item) {
                        MediaCenterModule.actionMediaCenterUpCount(item.pk)
                            .then(() => {
                                if (item && item.mediaType === 'YOUTUBE') {
                                    this.activeMediaNews = item as IMediaCenterNews;
                                    this.youtubeModal = true;
                                } else {
                                    this.activeMediaNews = item as IMediaCenterNews;
                                    this.snsModal = true;
                                }
                            });
                    }
                }
            })
        }
    }

    mounted() {
        if (isMacOs) {
            this.isMacOsShow = true;
        }
    }

    @Watch("orderBy")
    @Watch("activeItem", {deep: true})
    watchMediaCenterNewsList() {
        MediaCenterModule.actionMediaCenterNewsList({
            commonCodePk: this.activeItem.pk,
            orderBy: this.orderBy,
        });
    }

    @Watch('locale')
    watchLocaleChange() {
        MediaCenterModule.actionMediaCenterCategory();

        MediaCenterModule.actionMediaCenterNewsList({
            commonCodePk: this.activeItem.pk,
            orderBy: this.orderBy,
        });
    }

    /**
     * tab active
     */
    tabActive(key: number) {
        this.activeIndex = key;
        this.commonCodePk = this.activeItem.pk;
    }

    toggleModal(name: string) {
        history.pushState({}, '', `/${this.locale}/vip-mediacenter`)
        if (name === "youtubeModal") {
            this.youtubeModal = !this.youtubeModal;
        } else {
            this.snsModal = !this.snsModal;
        }
    }

    openMediaCenterModal(item: IMediaCenterNews) {
        history.pushState({}, '', `/${this.locale}/vip-mediacenter/${item.pk}`)
        MediaCenterModule.actionMediaCenterUpCount(item.pk)
            .then(() => {
                if (item.mediaType === 'YOUTUBE') {
                    this.activeMediaNews = item;
                    this.youtubeModal = true;
                } else {
                    this.activeMediaNews = item;
                    this.snsModal = true;
                }
            })
    }

    next() {
        this.$emit('slideChange', 'left');
    }

    prev() {
        this.$emit('slideChange', 'right');
    }
}
