

































import Component from "vue-class-component";
import { FormMixin } from "@/components/Mixins/FormMixin";
import { Prop } from "vue-property-decorator";

@Component
export default class VueInput extends FormMixin {
	@Prop({ default: "" }) inputSize!: string;
	@Prop({ default: "" }) inputTime!: string;
	@Prop({ default: "" }) width!: string;

	$refs!: {
		input: HTMLInputElement;
	};

	focus() {
		this.$refs.input.focus();
	}
}
