var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('intro-layout',[_c('div',{staticClass:"content"},[_c('div',{staticClass:"box-wrap"},[_c('div',{staticClass:"left",class:{  removeTransition: _vm.isResizing,
                            expanded: _vm.leftExpanded,
                            mobileOpacity: _vm.leftOpacity,
                            en: _vm.locale === 'en',
                            kr: _vm.locale === 'kr',
                            ch: _vm.locale === 'ch',
                            jp: _vm.locale === 'jp'},on:{"mouseenter":function($event){return _vm.handleMouseEnterOrLeave('left')},"mouseleave":function($event){return _vm.handleMouseEnterOrLeave('left')},"click":function($event){return _vm.toggleMobileOpacity('left')}}},[_c('div',{staticClass:"text-wrap"},[_c('h1',[_vm._v("MOBIS"),_c('br'),_vm._v("BUSINESS CONNECT")]),_c('vue-button',{attrs:{"btn-type":"arrow","width":"195px","height":"60px"},on:{"click":function($event){return _vm.$router.push({ name: _vm.ROUTE_NAME.HOME })}}},[_vm._v(_vm._s(_vm.$t("home_page.more_btn"))+" ")])],1)]),_c('div',{staticClass:"right",class:{
                     removeTransition: _vm.isResizing,
                     expanded: _vm.rightExpanded,
                     mobileOpacity: _vm.rightOpacity
                 },on:{"mouseenter":function($event){return _vm.handleMouseEnterOrLeave('right')},"mouseleave":function($event){return _vm.handleMouseEnterOrLeave('right')},"click":function($event){return _vm.toggleMobileOpacity('right')}}},[_c('div',{staticClass:"text-wrap"},[_c('h1',[_vm._v("MOBIS"),_c('br'),_vm._v("VIRTUAL SHOWROOM")]),_c('vue-button',{attrs:{"btn-type":"arrow","width":"195px","height":"60px"},on:{"click":function($event){return _vm.$router.push({ name: _vm.ROUTE_NAME.VSR_LOBBY})}}},[_vm._v(_vm._s(_vm.$t("home_page.more_btn"))+" ")])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }