





















































































import Vue from 'vue'
import {Prop, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import {ICommonCode} from "@/store/models/CommonModel";
import {IProductCategory} from "@/store/models/Product/ProductModel";

export interface IListTabView extends HTMLElement {
    $el: HTMLElement;
}

@Component
export default class VueListTab extends Vue {

    @Prop({ default: () => [] }) items!: ICommonCode[];
    @Prop({ default: '' }) tabType!: string;
    @Prop({default: ''}) width!: string;
    @Prop({default: 0}) activeIndex!: number;

    @Prop() activeItem!: IProductCategory;

    lineWidth: string = '';
    lineLeft: string = '';

    /**
	 * tab swiper option
	 */
	tabSwiperOption: any = {
        slidesPerView: 'auto',
        // freeMode: true,
		navigation: {
            nextEl: ".swiper-button-next.tab",
            prevEl: ".swiper-button-prev.tab"
        }
    }

    /**
     * tab underline active
     */
    activeUnderLine($event: any, key: number | string) {

        this.$emit('update:activeIndex', key)

        // click한 tab의 width 값
        let clickTabWidth = $event.target.offsetWidth;
        // click한 tab의 offsetLeft 값
        let clickTabOffsetLeft = $event.target.offsetLeft;

        // 탭 클릭 시
        // 1. 클릭한 탭의 넓이값과 underline 넓이 값과 같이 만들기
        // 2. 클릭한 탭의 offsetLeft 값만큼 underline lett 밀기
        this.lineWidth = `${clickTabWidth}px`;
        this.lineLeft = `${clickTabOffsetLeft}px`;
    }

    @Watch('activeItem')
    @Watch('items', {deep: true})
    onChangeActiveItem() {
        this.$nextTick(() => {
            let index = this.items.findIndex(item => {
                return item.pk === this.activeItem.pk;
            })

            this.changeActiveItem(index);
        });
    }

    @Watch('activeIndex')
    onChangeActiveIndex() {
        if (this.tabType === 'wide') {
            this.changeActiveItem(this.activeIndex)
        }
    }

    $refs!: {
        tab: IListTabView[]
    }

    changeActiveItem(index: number) {
        if (this.tabType === 'wide') {
            let tabDefaultWidth = this.$refs.tab[index].offsetWidth;
            let clickTabOffsetLeft = this.$refs.tab[index].offsetLeft;

            this.lineWidth = `${tabDefaultWidth}px`;
            this.lineLeft = `${clickTabOffsetLeft}px`;
        } else if (this.tabType === 'swiper') {
            let tabDefaultWidth = this.$refs.tab[index].$el.offsetWidth;
            let clickTabOffsetLeft = this.$refs.tab[index].$el.offsetLeft;

            this.lineWidth = `${tabDefaultWidth}px`;
            this.lineLeft = `${clickTabOffsetLeft}px`;
        }
    }

    mounted() {
        if (this.tabType === 'wide' || this.tabType === 'swiper') {
            this.changeActiveItem(this.activeIndex);
        }

        window.addEventListener('resize', () => this.changeActiveItem(this.activeIndex));
    }

}
