











import Vue from "vue";
import Component from "vue-class-component";
import LayoutHeader from "@/layouts/Header.vue";
import AboutFooter from "@/layouts/AboutFooter.vue";
import VueSpinner from "@/components/Spinner/VueSpinner.vue";

require("@/store/modules/AuthModule");

@Component({
	components: {
		VueSpinner,
		LayoutHeader,
		AboutFooter,
	},
})
export default class AboutLayout extends Vue {
	// 헤더 스크롤
	showScrDown: boolean = true;

	isSlot: boolean = true;

	onScroll() {
		let pageY = window.pageYOffset;
		this.showScrDown = pageY < 60;
	}

	mounted() {
		window.addEventListener("scroll", this.onScroll);
	}

	beforeDestroy() {
		window.removeEventListener("scroll", this.onScroll);
	}
}
