import {BaseRes, IAsset} from "@/store/models/BaseModel";

export interface IExhibitionSiteContent {
    pk: number; // 전시소식 기본키
    title: string; // 전시소식 타이틀
    assetImgPc: IAsset; // 이미지 PC
    assetImgMob: IAsset; // 이미지 Mobile
    movUrl: string; // 영상 URL
    ViewCnt: number; // 조회수
}

export interface IExhibitionSiteState {
    exhibitionSiteContentList: IExhibitionSiteContent[];
}

export interface IExhibitionSiteContentListReq {
    pageNo: number; // 페이지 번호
    rowPerPage: number; // 페이지당 로우 수
    commonCodePk: number; // 카테고리 코드 (전체: 0, 그 외 공통코드 PK)
    siteLang: string; // 사이트 언어
    orderBy: string; // 정렬 (new: 최신, view: 조회순)
}

export class MainExhibitionListRes extends BaseRes {
    items!: IExhibitionSiteContent[];

    constructor() {
        super();
    }
}

export class ExhibitionSiteContentListRes extends BaseRes {
    items!: IExhibitionSiteContent[];

    constructor() {
        super();
    }
}


