import store from '@/store';
import {Action, getModule, Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    ExhibitionListRes,
    IExhibition,
    IExhibitionState,
    IExhibitionViewTarget,
    IMainExhibition,
    MainExhibitionListRes
} from "@/store/models/Exhibition/ExhibitionModel";
import {reqExhibitionList, reqExhibitionUpCount, reqMainExhibitionList} from "@/store/api/ExhibitionApi";
import LangModule from "@/store/modules/LangModule";
import {ICommonCode} from "@/store/models/CommonModel";
import {reqCommonCode} from "@/store/api/CommonApi";

@Module({
    store,
    name: 'exhibition',
    namespaced: true,
    dynamic: true
})
class ExhibitionModule extends VuexModule implements IExhibitionState {

    mainExhibitionList: IMainExhibition[] = [];

    exhibitionList: IExhibition[] = [];

    exhibitionCommonCodes: ICommonCode[] = [];

    /**
     * 메인 전시 리스트
     */
    @MutationAction
    async actionMainExhibitionList() {
        const mainExhibitionListRes: MainExhibitionListRes = await reqMainExhibitionList();

        return {
            mainExhibitionList: mainExhibitionListRes.items.filter((item) => {
                return item.viewTarget === IExhibitionViewTarget.ALL
                    || item.viewTarget === IExhibitionViewTarget.NORMAL
            })
        };
    }

    /**
     * 전시 리스트
     *
     * @param commonCodePk
     * @param orderBy
     */
    @MutationAction
    async actionMutationExhibitionList({commonCodePk, orderBy}: {commonCodePk: number, orderBy: string}) {
        const exhibitionListRes: ExhibitionListRes = await reqExhibitionList({
            pageNo: 1,
            rowPerPage: 100,
            orderBy: orderBy,
            siteLang: LangModule.getLocaleToLang,
            commonCodePk: commonCodePk
        });

        return {
            exhibitionList: exhibitionListRes.items
        }
    }

    /**
     * 전시 카테고리 코드
     */
    @MutationAction
    async actionExhibitionCategory() {
        const exhibitionCategory = await reqCommonCode(2);

        return {
            exhibitionCommonCodes: exhibitionCategory.commonCodes
        }
    }

    /**
     * 조회수 증가
     * @param exhibitionPk
     */
    @Action
    async actionExhibitionUpCount(exhibitionPk: number) {
        await reqExhibitionUpCount(exhibitionPk);
    }

    get normalExhibitionList() {
        console.log(this.exhibitionList)
        return this.exhibitionList.filter((item) => {
            return item.viewTarget === IExhibitionViewTarget.ALL
                || item.viewTarget === IExhibitionViewTarget.NORMAL
        })
    }

    get vipExhibitionList() {
        return this.exhibitionList.filter((item) => {
            return item.viewTarget === IExhibitionViewTarget.ALL
                || item.viewTarget === IExhibitionViewTarget.VIP
        })    }
}

export default getModule(ExhibitionModule);
