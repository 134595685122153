











import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";

@Component
export default class VueSpinner extends Vue {
	@State((state) => state.spinner.isActive) isActive!: boolean;
}
