























import Component from "vue-class-component";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueInput from "@/components/Form/VueInput.vue";
import {FormMixin} from "@/components/Mixins/FormMixin";

@Component({
    components: {
        VueInput,
        VueSelect
    }
})
export default class VuePhoneInput extends FormMixin {
    get phoneNumber() {
        return this.value
            ? this.value.split('-', 3)
            : ['', '', '']
    }

    updateInput() {
        this.$emit('input', this.phoneNumber.join('-'));
    }
}
