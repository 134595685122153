import {BaseRes} from "@/store/models/BaseModel";
import * as BaseApi from "@/store/api/BaseApi";
import {PartnerListRes} from "@/store/models/Partner/PartnerModel";
import {CountryListRes} from "@/store/models/Country/CountryModel";

/**
 * 국가목록 리스트
 */
export async function reqCountryList(): Promise<CountryListRes> {

    let countryList: CountryListRes = new CountryListRes();

    await BaseApi.axiosDefault()
        .get('/api/country/list')
        .then(result => {
            countryList = (result.data.data as CountryListRes)
        })
        .catch(error => countryList = error);
    return countryList;
}
