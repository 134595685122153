import store from '@/store/index';
import Vuex, {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {ObjType} from "@/store/models/Form/FormModel";

@Module({
    store,
    name: 'lang',
    namespaced: true,
    dynamic: true
})
class LangModule extends VuexModule {

    locales: string[] = [
        'en',
        'kr',
        'ch',
        'jp',
    ]

    originLocale: string = process.env.VUE_APP_LOCALE;

    locale: string = localStorage.getItem('vuex')
        ? JSON.parse(<string>localStorage.getItem('vuex')).lang.locale
        : process.env.VUE_APP_LOCALE

    htmlLang: string = process.env.VUE_APP_HTML_LANG

    localeToArray: ObjType = {
        'en': 'ENG',
        'kr': '한국어',
        'ch': '中文',
        'jp': '日本語',
    }

    localeToLang: ObjType = {
        'en': 'ENG',
        'kr': 'KOR',
        'ch': 'CHN',
        'jp': 'JPN',
    }

    localeToHtmlLang: ObjType = {
        'en': 'en',
        'kr': 'ko',
        'ch': 'zh',
        'jp': 'ja',
    }

    get getLocaleToCode() {
        return this.locales.indexOf(this.locale) + 1
    }

    get getLocaleToString() {
        return this.localeToArray[this.locale]
    }

    get getLocaleToLang() {
        return this.localeToLang[this.locale]
    }

    @Mutation
    setLocale(locale: string) {
        if (this.locales.indexOf(locale) !== -1) {
            this.locale = locale
            this.htmlLang = this.localeToHtmlLang[locale]
        }
    }

    @Action({commit: 'setLocale'})
    changeLocale(locale: string) {
        if (this.locales.indexOf(locale) !== -1) {
            return locale
        }
    }
}

export default getModule(LangModule);
