






























import Vue from "vue";
import { Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import { ROUTE_NAME } from "@/router";
import { State } from "vuex-class";
import Lang from "@/store/modules/LangModule";
import Layout from "@/layouts/Vsr.vue";
import VideoPlayer from "@/components/Vsr/VideoPlayer.vue";
import ExhibitionSite from "@/store/modules/ExhibitionSiteModule";
import { IExhibitionSiteContent } from "@/store/models/ExhibitionSite/ExhibitionSiteModel";
import { VsrPageInfo } from "@/store/models/Vsr/VsrModel";
import VsrModule from "@/store/modules/VsrModule";

@Component({
  components: {
    Layout,
    VideoPlayer
  }
})
export default class VsrPop extends Vue {
  info = new VsrPageInfo("mobisNow");
  ROUTE_NAME = ROUTE_NAME;

  showIntro = true;
  isMute: boolean = false;
  prevVolume: number = 0;
  videoSrc: string = "";
  timer: any = null;
  containerStyle: any = null;
  currentTitle: string = "";
  swiperOptions = {
    slidesPerView: 4,
    spaceBetween: 30,
    slideToClickedSlide: true,
    direction: "vertical",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    centeredSlides: true,
    on: {
      slideChange: () => {
        this.createVideo();
      }
    }
  };

  @State(state => state.lang.locale) locale!: string;
  @State(state => state.exhibitionSite.exhibitionSiteContentList)
  contentList!: IExhibitionSiteContent[];

  get swiper() {
    return (this.$refs.mySwiper as any).$swiper;
  }

  created() {
    ExhibitionSite.actionExhibitionSiteContentList({
      pageNo: 1,
      rowPerPage: 10,
      commonCodePk: 0,
      siteLang: Lang.getLocaleToLang,
      orderBy: "new"
    });
  }

  mounted() {
    window.addEventListener("resize", this.updateContainerSize);
  }

  @Watch("locale")
  changeSiteLang() {
    this.contentList = [];

    ExhibitionSite.actionExhibitionSiteContentList({
      pageNo: 1,
      rowPerPage: 10,
      commonCodePk: 0,
      siteLang: Lang.getLocaleToLang,
      orderBy: "new"
    });
  }

  @Watch("contentList")
  changeContentList() {
    this.swiper.slideTo(0);
    this.createVideo();
  }

  onIntroEnded() {
    this.showIntro = false;
    this.updateContainerSize();
    this.createVideo();
  }

  onPlayerReady(event: any) {
    event.target.playVideo();
  }

  updateContainerSize() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      window.scrollTo(0, 1);

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const isHorizontal = windowWidth / windowHeight > 1920 / 1080;
      const containerWidth =
        (isHorizontal ? windowWidth : (windowHeight / 1080) * 1920) * 0.651;
      const containerHeight = containerWidth * 0.495;
      this.containerStyle = {
        width: containerWidth + "px",
        "padding-top": containerHeight + "px"
      };

      this.swiper.params.spaceBetween = windowWidth <= 1024 ? 10 : 30;
      this.swiper.update();
    }, 400);
  }

  createVideo() {
    if (this.contentList.length == 0 || this.showIntro) {
      document.getElementById("video-wrap")!.innerHTML = "";
      this.currentTitle = "";
      return;
    }

    VsrModule.setUseBgSound(false);

    const content = this.contentList[this.swiper.activeIndex];
    this.currentTitle = content.title;
    const videoSrc = `https://www.youtube.com/embed/${content.movUrl}?modestbranding=1&playsinline=1&autoplay=1&controls=0`;

    const html = `<iframe width="100%" height="100%" src="${videoSrc}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    document.getElementById("video-wrap")!.innerHTML = html;
  }
}
