

























































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import {ROUTE_NAME} from "@/router";
import LayoutGnb from "@/layouts/Gnb.vue";
import {State} from "vuex-class";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueInput from "@/components/Form/VueInput.vue";
import {IOption, ObjType} from "@/store/models/Form/FormModel";
import {Prop} from "vue-property-decorator";
import {IUserInfo} from "@/store/models/Auth/AuthModel";
import Auth from "@/store/modules/AuthModule";
import AuthModule from "@/store/modules/AuthModule";
import Lang from "@/store/modules/LangModule";
import LangModule from "@/store/modules/LangModule";
import {
    INotification,
    INotificationItem,
    INotificationManager, isMeetingData,
    NotificationMessages,
    NotificationTypes,
} from "@/store/models/Notification/NotificationModel";
import NotificationModule from "@/store/modules/NotificationModule";
import UserModule from "@/store/modules/UserModule";
import {EnumLang} from "@/store/models/Lang/LangModel";
import axios from "axios";
import LoginModalModule from "@/store/modules/LoginModalModule";

@Component({
	components: {
		LayoutGnb,
		VueSelect,
		VueButton,
		VueInput,
	},
})
export default class LayoutHeader extends Vue {
	ROUTE_NAME = ROUTE_NAME;

	@Prop({ default: 0 }) tabindex!: number;

    @State((state) => state.lang.originLocale) originLocale!: string;
	@State((state) => state.lang.locale) locale!: string;
	@State((state) => state.lang.localeToHtmlLang) localeToHtmlLang!: ObjType;
	@State((state) => state.lang.localeToArray) localeToArray!: ObjType;
	@State((state) => state.auth.userInfo) userInfo!: IUserInfo;
	@State((state) => state.notification.notification)

	notification!: INotification;

	$refs!: {
		search: HTMLInputElement;
	};

	localeOption: IOption[] = [];

	//quick area
	searchArea: boolean = false;
	alarmArea: boolean = false;
	infoArea: boolean = false;
    isShow: boolean = false;

	//search input
	searchExpanded: boolean = false;

	isActive: boolean = false;

	intervalHandler: any;

    searchText: string = "";

	get isNew() {
		return this.notification.notifications.find((item) => {
			return !item.read;
		});
	}

	get isLogin() {
		return Auth.isLogin;
	}

    goSearch() {
        if (this.searchText.length > 0) {
            this.$router.push({
                name: ROUTE_NAME.SEARCH,
                params: {
                    lang: this.locale
                },
                query: {
                    searchText: this.searchText
                }
            });
        }
    }

    loginModalActive() {
        LoginModalModule.show(() => { this.isShow = !this.isShow })
    }

	getNotiManagerName(item: INotificationItem) {
		if (item.notificationData.managerPk) {
			let manager: INotificationManager | undefined =
				this.notification.managers.find((manager) => {
					return manager.pk === item.notificationData.managerPk;
				});

			if (manager) {
				return LangModule.getLocaleToCode === EnumLang.en + 1
					? manager.firstName + " " + manager.lastName
					: manager.lastName + manager.firstName;
			}
		}

		return LangModule.getLocaleToCode === EnumLang.en + 1
			? AuthModule.userInfo.firstName + " " + AuthModule.userInfo.lastName
			: AuthModule.userInfo.lastName + AuthModule.userInfo.firstName;
	}

	getNotiPartnerName(item: INotificationItem) {
		if (item.notificationData.managerPk) {
			let manager: INotificationManager | undefined =
				this.notification.managers.find((manager) => {
					return manager.pk === item.notificationData.managerPk;
				});

			if (manager) {
				return this.$t("common.company.name").toString();
			}
		}

		return LangModule.getLocaleToCode === EnumLang.kr + 1
			? UserModule.mypageShow.partner.localName
			: UserModule.mypageShow.partner.globalName;
	}

	getNotiMessage(notificationItem: INotificationItem) {
		let message: string = this.$t(NotificationMessages[notificationItem.notificationData.type]).toString();

        if (isMeetingData(notificationItem.notificationData)) {
            switch (notificationItem.notificationData.type) {
                case NotificationTypes.APPROVED:
                case NotificationTypes.DAY_BEFORE_MEETING:
                    message = message.replace(
                        '${meetingAt}',
                        this.$moment(notificationItem.notificationData.startAt).format('MMM D, YYYY / HH:mm')
                    );
                    break;
            }
        }

		return message;
	}

	getNotiCreatedAt(item: INotificationItem) {
		const today = this.$moment();
		const createAt = this.$moment(item.createAt)
			//.utc(item.createAt)
			.format("YYYY-MM-DD HH:mm:ss");
		const diff = today.diff(createAt, "hour");

		if (diff >= 24) {
			return Math.ceil(diff / 24) + this.$t("layout.header.alarm.days").toString();
		} else if (diff < 1) {
            return today.diff(createAt, "minute") + this.$t("layout.header.alarm.minutes").toString();
		} else {
            return Math.ceil(diff) + this.$t("layout.header.alarm.hour").toString();
        }
	}

	notiRead(item: INotificationItem) {
		if (!item.read) {
			NotificationModule.actionNotificationRead(item.pk);
		}
	}

	created() {
        if (this.isLogin) {
            NotificationModule.actionNotificationList();
        }
	}

	searchFocusChange() {
		this.searchExpanded = !this.searchExpanded;

		if (this.searchExpanded) {
			this.$refs.search.focus();
		} else if (this.searchText.length > 0) {
            this.goSearch();
        }
	}

	changeLang(locale: string) {
		/*if (process.env.LOCALE === "ch") {
			location.href = process.env.GLOBAL_URL.toString() + "/" + locale;
		} */
		let url: string = "/" + locale;

		if (this.$route.name !== null && this.$route.name !== undefined) {
			if (
				this.$route.name.toString() !== "home" &&
				this.$route.name.toString() !== "lang"
			) {
				url += "/" + this.$route.name.toString();
			}
		}

		Lang.changeLocale(locale);
		this.$i18n.locale = this.localeToHtmlLang[locale];
        axios.defaults.headers.common['Accept-Language'] = this.localeToHtmlLang[locale]
		document
			.querySelector("html")
			?.setAttribute("lang", this.localeToHtmlLang[locale]);

		history.pushState(this.$route.params, "", url);
	}

	logout() {
		Auth.logout();
	}

	getUserName() {
		return Auth.getUserName;
	}

	mounted() {
	    if (this.isLogin) {
            this.intervalHandler = setInterval(function () {
                if (process.env.VUE_NOTICE_ALARM == true) {
                    NotificationModule.actionNotificationList();
                }
            }, 10000);
        }

		for (const item of Object.keys(this.localeToArray)) {
			this.localeOption.push({
				text: this.localeToArray[item],
				value: item,
			});
		}
	}

	destroyed() {
		clearInterval(this.intervalHandler);
	}
}
