





















import Component from "vue-class-component";
import { FormMixin } from "@/components/Mixins/FormMixin";
import { Prop } from 'vue-property-decorator';

@Component
export default class VueObjectCheck extends FormMixin {
    @Prop({ default: '' }) width!: string;
    @Prop({ default: 'checkbox' }) buttonType!: string;

    change($event: any) {
        this.$emit("input", {
            checked: $event.target.checked,
            value: this.value
        });
    }
}
