













import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class VueButton extends Vue {
	@Prop({ default: "text" }) btnType!: string;
	@Prop({ default: "" }) iconType!: string;
	@Prop({ default: "blue" }) btnColor!: string;
	@Prop({ default: "" }) width!: string;
	@Prop({ default: "50px" }) height!: string;
}
