

















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import IntroLayout from "@/layouts/IntroIndex.vue";
import VueButton from "@/components/Button/VueButton.vue";
import {State} from "vuex-class";
import { ROUTE_NAME } from "@/router";

@Component({
    components: {
        IntroLayout,
        VueButton
    },
    metaInfo() {
        return {
            title: 'Hyundai-Mobis'
        };
    }
})
export default class Intro extends Vue {

    @State((state) => state.lang.locale) locale!: string;

    ROUTE_NAME = ROUTE_NAME;

    leftExpanded: boolean = false;
    rightExpanded: boolean = false;
    leftOpacity: boolean = false;
    rightOpacity: boolean = false;
    isResizing: boolean =  false;

    handleResize() {
        this.isResizing = true;
        setTimeout( () => { this.isResizing = false }, 100 );
    }

    mounted() {
        window.addEventListener('resize', this.handleResize )
    }

    destroyed() {
        window.removeEventListener('resize', this.handleResize )
    }

    handleMouseEnterOrLeave(state: string) {
        if(state === 'left') {
            this.rightExpanded = !this.rightExpanded
        } else {
            this.leftExpanded = !this.leftExpanded
        }
    }

    toggleMobileOpacity(state: string) {
        if(state === 'left') {
            this.rightOpacity = true;
            this.leftOpacity = false;
        } else {
            this.rightOpacity = false;
            this.leftOpacity = true;
        }
    }
}
