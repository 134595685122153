import {BaseRes} from "@/store/models/BaseModel";

export interface ICountry {
    pk: number; // 기본키
    name: string; // 지역 이름
    code: string; // 국가 전화번호 코드
}

export interface ICountryState {
    countries: ICountry[];
}

export class CountryListRes extends BaseRes {
    items!: ICountry[];

    constructor() {
        super();
    }
}
