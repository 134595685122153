








































































import Vue from "vue";
import Component from "vue-class-component";
import VueModal from "@/components/Modal/VueModal.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueTag from "@/components/Tag/VueTag.vue";
import { State } from "vuex-class";
import {
	IMeeting,
	IMeetingUser,
	MainMeetingStatus,
} from "@/store/models/Meeting/MeetingModel";
import Lang from "@/store/modules/LangModule";
import { EnumLang } from "@/store/models/Lang/LangModel";
import { Prop } from "vue-property-decorator";

@Component({
	components: {
		VueModal,
		VueButton,
		VueTag,
	},
})
export default class MeetingDetailModal extends Vue {
	@State((state) => state.meeting.meetingDetail) meetingInfo!: IMeeting;

	@Prop({ default: MainMeetingStatus.COMPLETE }) meetingStatus!: string;

	MainMeetingStatus = MainMeetingStatus;

	get meetingDate() {
		let meetingAt = this.$moment(this.meetingInfo.meetingAt);
		let date = meetingAt.format("MMM D, YYYY");
		let time = meetingAt.format("HH:mm");

		return date + " / " + time;
	}

    /**
     * 미팅 결과 미 입력시 - 으로 대체
     */
    get meetingResult() {
        return !this.meetingInfo.result
            ? '-'
            : this.meetingInfo.result
    }

	localeToCode() {
		return Lang.getLocaleToCode;
	}

	getName(item: IMeetingUser) {
		return this.localeToCode() === EnumLang.en + 1
			? item.firstName + " " + item.lastName
            : item.lastName + item.firstName;
	}
}
