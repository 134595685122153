





























































































import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import ImageCardListItem from "@/components/Card/ImageCardListItem.vue";
import ProductCardListItem from "@/components/Card/ProductCardListItem.vue";
import {IProduct} from "@/store/models/Product/ProductModel";
import {ISwiperElement} from "@/pages/MediaCenter.vue";

@Component({
	components: {
		ImageCardListItem,
        ProductCardListItem
	}
})
export default class ImageCardList extends Vue {
	@Prop({ default: [] }) items!: object[];
	@Prop({ default: "" }) type!: string;
	@Prop({ default: "simple" }) cardType!: string;
	@Prop({ default: false }) bookmarkType!: boolean;

	activePage: number = 1;

	/**
	 * swiper option
	 */
	swiperOption: any = {
		direction: "vertical",
		loop: false,
		keyboard: {
			enabled: true,
		},
		mousewheel: true,
		slidesPerGroup: 1,
		slidesPerView: 1,
		slidesPerColumn: 1,
		spaceBetween: 20,
		pagination: {
			el: ".prd-pagination",
			clickable: true,
		},
		createElements: true,
		// breakpoints: {
		// 	320: {
		// 		slidesPerGroup: 1,
		// 		slidesPerView: 1,
		// 		slidesPerColumn: 1,
		// 	},
		// 	721: {
		// 		slidesPerGroup: 3,
		// 		slidesPerView: 3,
		// 		slidesPerColumn: 2,
		// 	},
		// 	1401: {
		// 		slidesPerGroup: 1,
		// 		slidesPerView: 1,
		// 		slidesPerColumn: 1,
		// 	},
		// },
	};

    get swiperCardList() {
        let depths: any = [];
        let child: any = [];

        this.items.map((item, key) => {
            if (key > 0 && (key % 6) === 0) {
                depths.push(child);
                child = [];
            }

            child.push(item);

            if (key === this.items.length - 1) {
                depths.push(child);
            }
        });

        return depths;
    }

    $refs!: {
        mySwiper: ISwiperElement
    }

	get swiperHandler() {
	    return this.$refs.mySwiper.$swiper;
    }

	mounted() {
    }

	slideChange(handler: string) {
	    if (handler === 'left') {
	        ++this.activePage;
        } else {
	        --this.activePage;
        }
    }

    clickEvent(payload: IProduct) {
        this.$emit('click', payload);
    }

    next() {
        this.activePage = this.swiperHandler.activeIndex+1;
        //this.slideChange('left')
    }

    prev() {
        this.activePage = this.swiperHandler.activeIndex+1;
	    //this.slideChange('right')
    }

}
