var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('div',{staticClass:"content"},[(_vm.join)?_c('div',{key:"join",staticClass:"form-box"},[_c('div',{staticClass:"title-area"},[_c('h2',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.$t('common.breadcrumb.registry'))}})]),_c('div',{staticClass:"round-box"},[_c('div',{staticClass:"scroll"},[_c('div',{staticClass:"box-body"},[_c('ul',[_c('li',[_c('dl',[_c('dt',{staticClass:"point",domProps:{"innerHTML":_vm._s(
												_vm.$t('registry_page.name.label')
											)}}),(
												_vm.localeToCode() === _vm.lang.kr + 1
											)?_c('dd',{staticClass:"flex-between"},[_c('div',[_c('VueInput',{ref:"lastName",attrs:{"type":"text","placeholder":_vm.$t(
															'registry_page.name.first_name_input'
														),"maxlength":"30","width":"100%","input-size":"small narrow"},model:{value:(
														_vm.registryData.lastName
													),callback:function ($$v) {_vm.$set(_vm.registryData, "lastName", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tregistryData.lastName\n\t\t\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('div',[_c('VueInput',{ref:"firstName",attrs:{"type":"text","placeholder":_vm.$t(
															'registry_page.name.last_name_input'
														),"maxlength":"30","width":"100%","input-size":"small narrow"},model:{value:(
														_vm.registryData.firstName
													),callback:function ($$v) {_vm.$set(_vm.registryData, "firstName", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tregistryData.firstName\n\t\t\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('vue-validator',{attrs:{"valid-error":_vm.validator.name}})],1):_c('dd',{staticClass:"flex-between"},[_c('div',[_c('VueInput',{ref:"firstName",attrs:{"type":"text","placeholder":_vm.$t(
															'registry_page.name.last_name_input'
														),"maxlength":"30","width":"100%","input-size":"small narrow"},model:{value:(
														_vm.registryData.firstName
													),callback:function ($$v) {_vm.$set(_vm.registryData, "firstName", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tregistryData.firstName\n\t\t\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('div',[_c('VueInput',{ref:"lastName",attrs:{"type":"text","placeholder":_vm.$t(
															'registry_page.name.first_name_input'
														),"maxlength":"30","width":"100%","input-size":"small narrow"},model:{value:(
														_vm.registryData.lastName
													),callback:function ($$v) {_vm.$set(_vm.registryData, "lastName", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tregistryData.lastName\n\t\t\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('vue-validator',{attrs:{"valid-error":_vm.validator.name}})],1)])]),_c('li',[_c('dl',[_c('dt',{staticClass:"point",domProps:{"innerHTML":_vm._s(_vm.$t('registry_page.partner.label'))}}),_c('dd',[_c('VueSelect',{attrs:{"multi":true,"options":_vm.partnerList,"default-value":_vm.partnerDefaultValue,"color-type":"black","select-size":"small wide","width":"100%"},on:{"input":_vm.changePartner}}),_c('vue-validator',{attrs:{"valid-error":_vm.validator.partner}})],1)])]),(_vm.registryData.partnerPk === null)?_c('li',[_c('dl',[_c('dt',{staticClass:"point",domProps:{"innerHTML":_vm._s(_vm.$t('registry_page.partner.label'))}}),_c('dd',{staticClass:"flex-between"},[_c('div',[_c('VueInput',{ref:"partnerLocalName",attrs:{"type":"text","placeholder":_vm.$t(
															'registry_page.partner.partner_local_input'
														),"maxlength":"30","width":"100%","input-size":"small narrow"},model:{value:(
														_vm.partnerData.localName
													),callback:function ($$v) {_vm.$set(_vm.partnerData, "localName", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tpartnerData.localName\n\t\t\t\t\t\t\t\t\t\t\t\t\t"}}),_c('vue-validator',{attrs:{"valid-error":_vm.validator.partnerLocalName}})],1),_c('div',[_c('VueInput',{ref:"partnerGlobalName",attrs:{"type":"text","placeholder":_vm.$t(
															'registry_page.partner.partner_global_input'
														),"maxlength":"30","width":"100%","input-size":"small narrow"},model:{value:(
														_vm.partnerData.globalName
													),callback:function ($$v) {_vm.$set(_vm.partnerData, "globalName", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tpartnerData.globalName\n\t\t\t\t\t\t\t\t\t\t\t\t\t"}}),_c('vue-validator',{attrs:{"valid-error":_vm.validator.partnerGlobalName}})],1)])])]):_vm._e(),(_vm.registryData.partnerPk === null)?_c('li',[_c('dl',[_c('dt',{staticClass:"point",domProps:{"innerHTML":_vm._s(
												_vm.$t(
													'registry_page.partner_url.label'
												)
											)}}),_c('dd',[_c('VueInput',{ref:"partnerUrl",attrs:{"type":"text","placeholder":_vm.$t(
														'registry_page.partner_url.partner_url_input'
													),"maxlength":"100","width":"100%","input-size":"small narrow"},model:{value:(_vm.partnerData.url),callback:function ($$v) {_vm.$set(_vm.partnerData, "url", $$v)},expression:"partnerData.url"}}),_c('vue-validator',{attrs:{"valid-error":_vm.validator.partnerUrl}})],1)])]):_vm._e(),_c('li',{staticClass:"email-area"},[_c('dl',[_c('dt',{staticClass:"point",domProps:{"innerHTML":_vm._s(
												_vm.$t('registry_page.email.label')
											)}}),_c('dd',{staticClass:"flex-between"},[_c('VueInput',{ref:"email",attrs:{"type":"email","placeholder":_vm.$t(
														'registry_page.email.email_input'
													),"width":"27%","input-size":"small narrow","disabled":_vm.partnerList.length === 1},model:{value:(_vm.email1),callback:function ($$v) {_vm.email1=$$v},expression:"email1"}}),_vm._v(" @ "),_c('VueInput',{attrs:{"type":"email","width":"35%","input-size":"small","disabled":!_vm.selfDomain},model:{value:(_vm.email2),callback:function ($$v) {_vm.email2=$$v},expression:"email2"}}),_c('VueSelect',{attrs:{"options":_vm.domainList,"default-value":_vm.domainDefault,"color-type":"black","select-size":"small narrow","width":"30%","disabled":_vm.registryData.partnerPk === null
													|| _vm.registryData.partnerPk === 0
													|| _vm.partnerList.length === 1},on:{"input":_vm.changeDomain}}),_c('p',{staticClass:"notice"},[_vm._v(" "+_vm._s(_vm.$t( "registry_page.email.notice" ))+" ")]),_c('vue-validator',{attrs:{"valid-error":_vm.validator.email}})],1)])]),_c('li',[_c('dl',[_c('dt',{staticClass:"point",domProps:{"innerHTML":_vm._s(
												_vm.$t(
													'registry_page.password.label'
												)
											)}}),_c('dd',[_c('VueInput',{ref:"password",attrs:{"type":"password","placeholder":_vm.$t(
														'registry_page.password.password_input'
													),"maxlength":"16","width":"100%","input-size":"small narrow"},model:{value:(_vm.registryData.password),callback:function ($$v) {_vm.$set(_vm.registryData, "password", $$v)},expression:"registryData.password"}}),_c('vue-validator',{attrs:{"valid-error":_vm.validator.password}})],1)])]),_c('li',[_c('dl',[_c('dt',{staticClass:"point",domProps:{"innerHTML":_vm._s(
												_vm.$t(
													'registry_page.password_confirmation.label'
												)
											)}}),_c('dd',[_c('VueInput',{ref:"passwordConfirm",attrs:{"type":"password","placeholder":_vm.$t(
														'registry_page.password_confirmation.password_input'
													),"maxlength":"16","width":"100%","input-size":"small narrow"},model:{value:(
													_vm.registryData.passwordConfirmation
												),callback:function ($$v) {_vm.$set(_vm.registryData, "passwordConfirmation", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\tregistryData.passwordConfirmation\n\t\t\t\t\t\t\t\t\t\t\t\t"}}),_c('vue-validator',{attrs:{"valid-error":_vm.validator.passwordConfirmation}})],1)])]),_c('li',{staticClass:"number-area"},[_c('dl',[_c('dt',{domProps:{"innerHTML":_vm._s(
												_vm.$t('registry_page.phone.label')
											)}}),_c('dd',{staticClass:"flex-between"},[_c('VueSelect',{attrs:{"multi":true,"options":_vm.countryCodeList,"default-value":"registry_page.phone.phone_default_option","color-type":"black","width":"22%","select-size":"small findId"},model:{value:(_vm.registryData.phoneCountryPk),callback:function ($$v) {_vm.$set(_vm.registryData, "phoneCountryPk", $$v)},expression:"registryData.phoneCountryPk"}}),_c('VueInput',{ref:"phone1",attrs:{"type":"tel","maxlength":"4","width":"22%","input-size":"small narrow"},model:{value:(_vm.phone1),callback:function ($$v) {_vm.phone1=$$v},expression:"phone1"}}),_vm._v(" - "),_c('VueInput',{ref:"phone2",attrs:{"type":"tel","maxlength":"4","width":"22%","input-size":"small narrow"},model:{value:(_vm.phone2),callback:function ($$v) {_vm.phone2=$$v},expression:"phone2"}}),_vm._v(" - "),_c('VueInput',{ref:"phone3",attrs:{"type":"tel","maxlength":"4","width":"22%","input-size":"small narrow"},model:{value:(_vm.phone3),callback:function ($$v) {_vm.phone3=$$v},expression:"phone3"}}),_c('vue-validator',{attrs:{"valid-error":_vm.validator.phone}})],1)])]),_c('li',{staticClass:"number-area"},[_c('dl',[_c('dt',{staticClass:"point",domProps:{"innerHTML":_vm._s(
												_vm.$t('registry_page.mobile.label')
											)}}),_c('dd',{staticClass:"flex-between"},[_c('VueSelect',{attrs:{"multi":true,"options":_vm.countryCodeList,"default-value":"registry_page.mobile.mobile_default_option","color-type":"black","width":"22%","select-size":"small findId"},model:{value:(_vm.registryData.mobileCountryPk),callback:function ($$v) {_vm.$set(_vm.registryData, "mobileCountryPk", $$v)},expression:"registryData.mobileCountryPk"}}),_c('VueInput',{ref:"mobile1",attrs:{"type":"tel","maxlength":"4","width":"22%","input-size":"small narrow"},model:{value:(_vm.mobile1),callback:function ($$v) {_vm.mobile1=$$v},expression:"mobile1"}}),_vm._v(" - "),_c('VueInput',{ref:"mobile2",attrs:{"type":"tel","maxlength":"4","width":"22%","input-size":"small narrow"},model:{value:(_vm.mobile2),callback:function ($$v) {_vm.mobile2=$$v},expression:"mobile2"}}),_vm._v(" - "),_c('VueInput',{ref:"mobile3",attrs:{"type":"tel","maxlength":"4","width":"22%","input-size":"small narrow"},model:{value:(_vm.mobile3),callback:function ($$v) {_vm.mobile3=$$v},expression:"mobile3"}}),_c('vue-validator',{attrs:{"valid-error":_vm.validator.mobile}})],1)])])]),_c('ul',[_c('li',{staticClass:"country-area"},[_c('dl',[_c('dt',{domProps:{"innerHTML":_vm._s(
												_vm.$t(
													'registry_page.country.label'
												)
											)}}),_c('dd',[_c('VueSelect',{attrs:{"options":_vm.countryList,"default-value":"registry_page.country.country_default_option","color-type":"black","select-size":"small","width":"100%","multi":true},model:{value:(_vm.registryData.countryPk),callback:function ($$v) {_vm.$set(_vm.registryData, "countryPk", $$v)},expression:"registryData.countryPk"}}),_c('p',{staticClass:"notice"},[_c('span',[_vm._v(_vm._s(_vm.$t("registry_page.country.country_gmt")))]),_vm._v(" "+_vm._s(_vm.timezone)+" ")]),_c('p',{staticClass:"notice"},[_vm._v(_vm._s(_vm.$t("registry_page.country.country_gmt_auto")))]),_c('vue-validator',{attrs:{"valid-error":_vm.validator.country}})],1)])]),_c('li',[_c('dl',[_c('dt',{domProps:{"innerHTML":_vm._s(
												_vm.$t(
													'registry_page.department.label'
												)
											)}}),_c('dd',[_c('VueInput',{ref:"department",attrs:{"type":"text","maxlength":"30","width":"100%","input-size":"small"},model:{value:(
													_vm.registryData.department
												),callback:function ($$v) {_vm.$set(_vm.registryData, "department", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\tregistryData.department\n\t\t\t\t\t\t\t\t\t\t\t\t"}}),_c('vue-validator',{attrs:{"valid-error":_vm.validator.department}})],1)])]),_c('li',[_c('dl',[_c('dt',{domProps:{"innerHTML":_vm._s(
												_vm.$t(
													'registry_page.position.label'
												)
											)}}),_c('dd',[_c('VueInput',{ref:"position",attrs:{"type":"text","maxlength":"30","width":"100%","input-size":"small"},model:{value:(_vm.registryData.position),callback:function ($$v) {_vm.$set(_vm.registryData, "position", $$v)},expression:"registryData.position"}}),_c('vue-validator',{attrs:{"valid-error":_vm.validator.position}})],1)])]),_c('li',[_c('dl',[_c('dt',{staticClass:"point",domProps:{"innerHTML":_vm._s(
												_vm.$t(
													'registry_page.Product_categories.label'
												)
											)}}),_c('dd',{staticClass:"sorting-area"},[_c('VueSortingCheckMulti',{attrs:{"items":_vm.productCategories},model:{value:(
													_vm.registryData.productCategories
												),callback:function ($$v) {_vm.$set(_vm.registryData, "productCategories", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\tregistryData.productCategories\n\t\t\t\t\t\t\t\t\t\t\t\t"}}),_c('p',{staticClass:"notice"},[_vm._v(" "+_vm._s(_vm.$t( "registry_page.Product_categories.notice" ))+" ")]),_c('VueValidator',{attrs:{"valid-error":_vm.validator.productCategories}})],1)])])])]),_c('div',{staticClass:"box-footer"},[_c('div',{staticClass:"priv-area",class:{ 'direction': _vm.locale === 'en' }},[_c('p',{staticClass:"notice"},[_vm._v(_vm._s(_vm.$t("registry_page.agree.notice")))]),_c('div',{staticClass:"priv-chk-area"},[_c('div',{staticClass:"priv-all"},[_c('vue-check',{attrs:{"value":"name1","label-for":"name1","checked":_vm.agreementAll},on:{"click":_vm.changeAgreementAll},model:{value:(_vm.agreementAll),callback:function ($$v) {_vm.agreementAll=$$v},expression:"agreementAll"}},[_vm._v(_vm._s(_vm.$t( "registry_page.agree.agree_all_label" )))])],1),_c('div',{staticClass:"priv-more"},[_c('div',[_c('vue-check',{attrs:{"value":"A","label-for":"A","checked":_vm.registryData.agreementTermsOfService},model:{value:(
													_vm.registryData.agreementTermsOfService
												),callback:function ($$v) {_vm.$set(_vm.registryData, "agreementTermsOfService", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\tregistryData.agreementTermsOfService\n\t\t\t\t\t\t\t\t\t\t\t\t"}},[_vm._v(_vm._s(_vm.$t( "registry_page.agree.terms_label1" )))]),_c('vue-button',{attrs:{"btn-type":"icon","btn-color":"gray","icon-type":"arrow-right small","width":"18px","height":"18px"},on:{"click":function($event){return _vm.toggleModal(
														'userAgreementsModal'
													)}}},[_vm._v("arrow-right small")])],1),_c('div',[_c('vue-check',{attrs:{"value":"B","label-for":"B","checked":_vm.registryData.agreementPrivacyPolicy},model:{value:(
													_vm.registryData.agreementPrivacyPolicy
												),callback:function ($$v) {_vm.$set(_vm.registryData, "agreementPrivacyPolicy", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\tregistryData.agreementPrivacyPolicy\n\t\t\t\t\t\t\t\t\t\t\t\t"}},[_vm._v(_vm._s(_vm.$t( "registry_page.agree.terms_label2" )))]),_c('vue-button',{attrs:{"btn-type":"icon","btn-color":"gray","icon-type":"arrow-right small","width":"18px","height":"18px"},on:{"click":function($event){return _vm.toggleModal(
														'personalInformationModal'
													)}}},[_vm._v("arrow-right small")])],1)])])]),_c('div',{staticClass:"btn-area"},[_c('vue-button',{attrs:{"width":"195px","btnColor":"gray"},nativeOn:{"click":function($event){return _vm.$emit('update:switcher', 'VueLogin')}}},[_vm._v(_vm._s(_vm.$t("common.button.cancel")))]),_c('vue-button',{attrs:{"width":"195px"},on:{"click":_vm.registry}},[_vm._v(_vm._s(_vm.$t("common.button.Application_completed")))])],1)])])])]):_vm._e(),(_vm.authenticationMail)?_c('VueRegistryComplete',{attrs:{"title":"login_page.registry_complete.subject","message1":"login_page.registry_complete.message1","message2":"login_page.registry_complete.message2"}}):(_vm.standby)?_c('div',[_c('VueRegistryComplete',{attrs:{"title":"login_page.approval_pending.subject","message1":"login_page.approval_pending.message1","message2":"login_page.approval_pending.message2"}})],1):_vm._e(),(_vm.userAgreements)?_c('user-agreements-modal',{on:{"close":function($event){return _vm.toggleModal('userAgreementsModal')}}}):_vm._e(),(_vm.personalInformation)?_c('personal-information-modal',{on:{"close":function($event){return _vm.toggleModal('personalInformationModal')}}}):_vm._e(),(_vm.agreeMarketing)?_c('agree-marketing-modal',{on:{"close":function($event){return _vm.toggleModal('agreeMarketingModal')}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }