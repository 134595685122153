import {NavigationGuard} from "vue-router";
import {OAuth} from "@/libs/OAuth";
import {ROUTE_NAME} from "@/router";
import store from "@/store";
import AuthModule from "@/store/modules/AuthModule";


const beforeEach: NavigationGuard = async function (to, from, next) {
    if (!to.query?.uuid) {
        next({
            path: '/kr/errorcert'
        })
    } else {
        // 인증 요청
        await AuthModule.actionCheckEmailAuth({
            uuid: <string>to.query.uuid,
            type: <string>to.query.type.toString().toUpperCase()
        })
            .then((result => {
                if (result.errors?.uuid || result.status === 422) {
                    next({
                        path: '/kr/errorcert'
                    })
                } else {
                    next();
                }
            }))
            .catch(error => {
                next({
                    path: '/kr/errorcert'
                })
            })
    }
}

export default beforeEach;
