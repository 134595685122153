import store from '@/store';
import {Action, getModule, Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {IPartner, IPartnerDomain, IPartnerState} from "@/store/models/Partner/PartnerModel";
import * as PartnerApi from "@/store/api/PartnerApi";
import {reqPartnerDomainInfo} from "@/store/api/PartnerApi";

@Module({
    store,
    name: 'partner',
    namespaced: true,
    dynamic: true
})
class PartnerModule extends VuexModule implements IPartnerState {

    /**
     * 협력사 리스트
     */
    partners: IPartner[] = [];

    /**
     * 협력사 도메인 리스트
     */
    partnerDomains: IPartnerDomain[] = [];

    /**
     * 협력사 리스트
     */
    @MutationAction
    async partnerList() {
        const partnerListRes = await PartnerApi.reqPartnerList();

        return {
            partners: partnerListRes.items
        }
    }

    /**
     * 협력사 도메인 리스트
     *
     * @param partnerPk
     */
    @MutationAction
    async actionPartnerDomainList(partnerPk: number) {
        const partnerDomainListRes = await PartnerApi.reqPartnerDomainList(partnerPk);

        return {
            partnerDomains: partnerDomainListRes.items
        };
    }

    @Action
    async actionPartnerDomainInfo(domain: string) {
        return await reqPartnerDomainInfo(domain);
    }
}

export default getModule(PartnerModule);
