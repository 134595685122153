import {
    INotificationReadReq,
    NotificationListRes
} from "@/store/models/Notification/NotificationModel";
import {axiosAuth, noticeAxios} from "@/store/api/BaseApi";
import {BaseRes} from "@/store/models/BaseModel";

/**
 * 알림 리스트
 */
export async function reqNotificationList(): Promise<NotificationListRes> {

    let notificationList = new NotificationListRes();

    await noticeAxios()
        .post('/api/notification/list', {})
        .then(result => {
            notificationList = (result.data as NotificationListRes);
        })
        .catch(error => notificationList = error);

    return notificationList;
}

/**
 * 알림 읽음 처리
 */
export async function reqNotificationRead(req: INotificationReadReq): Promise<BaseRes> {

    let res = new BaseRes();

    await axiosAuth()
        .post('/api/notification/read', req)
        .then(result => {
            res = (result.data as BaseRes);
        })
        .catch(error => res = error);

    return res;
}
