import Vue from "vue";
import {Prop} from 'vue-property-decorator';
import {IProduct} from "@/store/models/Product/ProductModel";

export interface IListItem extends IProduct{
    name: string;
    code: string;
    state: string;
    pk: number;
}

export class CardItemMixin extends Vue {

    @Prop() item!: IListItem;

}
