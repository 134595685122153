import {BaseRes} from "@/store/models/BaseModel";

/**
 * 제품
 */
export interface IProduct {
    pk: number; // 기본키
    productCategoryPk: number; // 카테고리 기본키
    productCategoryName: string; // 카테고리 이름
    model: string; // 모델명
    mainImageUrl: string; // 메인 이미지 url
    thumbnailImageUrl: string[]; // 섬네일 이미지 리스트
    bookmark: boolean
}

export interface IProductShow extends IProduct{
    pk: number; // 기본키
    model: string; // 제품명
    description: string; // 안내 내용
    mainImageUrl: string; // 제품 백그라운드 이미지 URL
    thumbnailImageUrls: string[]; // 제품 썸네일 이미지 URL
    bookmark: boolean; // 북마크 여부
    overviewPc: string; // 제품 개요 PC
    overviewMobile: string; // 제품 개요 Mobile
    featuresPc: string; // 특장점 PC
    featuresMobile: string; // 특장점 Mobile
    productRelates: IProductRelates[];
}

export interface IProductRelates {
    pk: number;
    model: string;
    mainImageUrl: string;
    thumbnailImageUrls: string[];
}

/**
 * 제품 카테고리
 */
export interface IProductCategory {
    pk: number; // 제품 기본키
    name: string; // 카테고리 이름
    description?: string; // 카테고리 설명
    parentPk?: number; // 상위코드 기본키
    child?: IProductCategory[] // 하위 코드
}

/**
 * 제품 카테고리 리스트 Request
 */
export interface IProductCategoryListReq {
    parentPk?: number;
}

/**
 * 모든 카테고리 리스트 리퀘스트
 */
export interface IProductListReq {
    productCategoryParentPkList?: number[]; // 상위 프로덕트 키
    productCategoryPkList?: number[]; // 프로덕트 하위 카테고리 리스트
    search?: string; // 검색어
    sortType?: string; // 정렬
}

export interface IUserInfoProductCategory {
    pk: number; // 기본키
    name: string; // 카테고리 이름
}

/**
 * State
 */
export interface IProductState {
    products: IProduct[];
    mainProducts: IProduct[];
    productCategories: IProductCategory[];
}

/**
 * Gnb 카테고리 리스트 Response
 */
export class ProductGnbCategoryRes extends BaseRes {
    productCategories!: IProductCategory[];

    constructor() {
        super();
    }
}

/**
 * 제품 카테고리 리스트 Response
 */
export class ProductCategoryListRes extends BaseRes {
    productCategories!: IProductCategory[];

    constructor() {
        super();
    }
}

/**
 * 메인 제품 리스트 Response
 */
export class ProductListRes extends BaseRes {
    products!: IProduct[];

    constructor() {
        super();
    }
}

export class ProductShowRes extends BaseRes {
    data!: IProductShow;

    constructor() {
        super();

    }

}
