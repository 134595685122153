import {BaseRes} from "@/store/models/BaseModel";
import {IProduct, IProductCategory} from "@/store/models/Product/ProductModel";

export interface IUserState {
    mypageShow: IMypageShow;
    productCategories: IProductCategory[];
    userBookmarks: IProduct[];
}

/**
 * 마이페이지 > 내정보
 */
export interface IMypageUser {
    pk: number; // 회원 기본키
    firstName: string; // 회원 성
    lastName: string; // 회원 이름
    email: string; // 이메일
    phoneNum?: string; // 전화번호
    mobileNum: string; // 핸드폰 번호
    phoneCountryPk?: number; // 전화번호 국가번호
    mobileCountryPk: number; // 핸드폰 국가번호
    department: string; // 부서
    position: string; // 직위
}

/**
 * 마이페이지 > 내정보 > 협력사
 */
export interface IMypagePartner {
    localName: string; // 협력사명 (로컬)
    globalName: string; // 협력사명 (영문)
}

/**
 * 마이페이지 > 내정보 > 국가
 */
export interface IMypageCountry {
    pk: number;
    name: string; // 국가명
    code: string; // 국가 코드
}

/**
 * 마이페이지 > 내정보 > 관심 카테고리
 */
export interface IMypageUserProduct {
    productCategoryPks: number[];
}

export interface IMypageShow {
    user: IMypageUser;
    partner: IMypagePartner;
    country: IMypageCountry;
    userProduct: IMypageUserProduct;
}

/**
 * 마이페이지 내정보 수정
 */
export interface IMypageEditReq {
    phoneNum: string; // 회원 전화 번호
    mobileNum: string; // 회원 핸드폰 번호
    phoneCountryPk: number | null; // 전화번호 국가번호
    mobileCountryPk: number; // 핸드폰 국가번호
    productCategoryPks: number[] // 회원 관심제품 기본키 리스트
    department: string; // 부서명
    position: string; // 직위
}

export interface IBookmark {
    pk: number; // 기본키
    productPk: number; // 제품 기본키
    thumbnail: string; // 썸네일
    productCategoryName: string; // 제품 카테고리 이름
    model: string; // 제품명
}

export interface IBookmarkListReq {
    productCategoryPk?: number; // 제품 카테고리 기본키
    sort: string; //정렬조건 (new:최근등록순, view:조회수많은순)
}

/**
 * 마이페이지 > 북마크 리스트
 */
export class BookmarkListRes extends BaseRes {
    products!: IProduct[];
}

/**
 * 마이페이지 > 내정보 Res
 */
export class MypageShowRes extends BaseRes {
    data!: IMypageShow;
}

export class MypageShow implements IMypageShow {
    user: IMypageUser = {
        department: "", email: "", firstName: "", lastName: "", mobileNum: "", pk: 0, position: "", mobileCountryPk: 0
    }

    country: IMypageCountry = {
        pk: 0, code: "", name: ""
    }

    partner: IMypagePartner = {
        globalName: "", localName: ""
    }

    userProduct: IMypageUserProduct = {
        productCategoryPks: []
    }
}
