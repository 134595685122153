import store from '@/store';
import {Action, getModule, Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    IGnbExhibitionItem,
    IGnbMediaCenterItem,
    IGnbMenu, IGnbParent,
    IGnbProductItem,
    IGnbState, IProductParentCategory
} from "@/store/models/Gnb/GnbModel";
import {ProductGnbCategoryRes} from "@/store/models/Product/ProductModel";
import {reqProductGnbCategoryList} from "@/store/api/ProductApi";
import {CommonCodeRes} from "@/store/models/CommonModel";
import { reqCommonCode } from "@/store/api/CommonApi";
import {i18n} from "@/libs/i18n"
import {reqLeaveMenu, reqLeaveProduct, reqShowMenu} from "@/store/api/StatisticsApi";
import {MENU_NAME} from "@/store/models/Statistics/StatisticsApiModel";

@Module({
    store,
    name: 'gnb',
    namespaced: true,
    dynamic: true
})
class GnbModule extends VuexModule implements IGnbState {

    productParentCategory: IProductParentCategory[] = [];

    gnbMenus: IGnbMenu = {
        product: {
            name: i18n.t("common.gnb.product").toString(),
            toggle: true,
            subMenu: []
        },
        exhibition: {
            name: i18n.t("common.gnb.exhibition").toString(),
            toggle: true,
            subMenu: []
        },
        mediacenter: {
            name: i18n.t("common.gnb.media").toString(),
            toggle: true,
            subMenu: []
        }
    }

    @Mutation
    mutationToggleItem(item: IGnbParent<IGnbProductItem | IGnbExhibitionItem | IGnbMediaCenterItem>) {
        item.toggle = !item.toggle;
    }

    @MutationAction
    async actionGnbList() {
        const exhibitionCategories: CommonCodeRes = await reqCommonCode(2);
        const mediaCenterCategories: CommonCodeRes = await reqCommonCode(3);
        const productCategories: ProductGnbCategoryRes = await reqProductGnbCategoryList();

        const exhibitionCodes: IGnbExhibitionItem[] = [];
        const mediaCenterCodes: IGnbMediaCenterItem[] = [];
        const productCodes: IGnbProductItem[] = [];


        exhibitionCategories.commonCodes.map(item => {
            exhibitionCodes.push({
                name: item.name,
                exhibitionCategoryPk: item.pk,
            });
        });

        mediaCenterCategories.commonCodes.map(item => {
            mediaCenterCodes.push({
                name: item.name,
                mediaCenterCategoryPk: item.pk,
            })
        });

        productCategories.productCategories.map(item => {
            const data: IGnbProductItem = {
                name: item.name,
                parentPk: item.parentPk,
                productCategoryPk: item.pk,
                //description: item.description
            }

            if (item.child !== null) {
                data.child = [];
                item.child?.map(sub => {
                    if (typeof data.child !== 'undefined') {
                        data.child.push({
                            name: sub.name,
                            parentPk: sub.parentPk,
                            productCategoryPk: sub.pk,
                            //description: item.description
                        })
                    }
                })
            }

            productCodes.push(data);
        })


        return {
            gnbMenus: {
                product: {
                    name: i18n.t("common.gnb.product").toString(),
                    toggle: true,
                    subMenu: productCodes
                },
                exhibition: {
                    name: i18n.t("common.gnb.exhibition").toString(),
                    toggle: true,
                    subMenu: exhibitionCodes
                },
                mediacenter: {
                    name: i18n.t("common.gnb.media").toString(),
                    toggle: true,
                    subMenu: mediaCenterCodes
                }
            },
            productParentCategory: productCategories.productCategories
        }
    }

    @Action
    async actionMenuShowStart(name: MENU_NAME) {
        await reqShowMenu({
            type: name
        })
    }

    @Action
    async actionMenuShowEnd(name: MENU_NAME) {
        await reqLeaveMenu({
            type: name
        })
    }

}

export default getModule(GnbModule);
