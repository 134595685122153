










import Vue from 'vue'
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import {ICommonCode} from "@/store/models/CommonModel";

@Component
export default class VueTab extends Vue {
    @Prop({ default: () => [] }) tabs!: ICommonCode[];
    @Prop({ default: 0 }) activeIndex!: number;
}
