import {BaseRes} from "@/store/models/BaseModel";

export interface IPartner {
    pk: number;
    globalName: string;
    localName: string;
    url: string;
}

export interface IPartnerState {
    partners: IPartner[];
    partnerDomains: IPartnerDomain[];
}

export interface IPartnerDomain {
    pk: number;
    domain: string;
}

export interface IDomainInfo {
    pk: number;
    localName: string;
    globalName: string;
    url: string;
}

export interface IDomainInfoRes {
    partners: IDomainInfo[];
}

export class PartnerListRes extends BaseRes {
    items!: IPartner[];

    constructor() {
        super();
    }
}

export class PartnerDomainListRes extends BaseRes {
    items!: IPartnerDomain[];

    constructor() {
        super();
    }
}

export class PartnerDomainInfoRes extends BaseRes {
    data!: IDomainInfoRes;
}
