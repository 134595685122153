
















import Vue from "vue";
import {Component} from "vue-property-decorator";
import {State} from "vuex-class";
import LayoutFooter from "@/layouts/Footer.vue";
import VueSpinner from "@/components/Spinner/VueSpinner.vue";

require("@/store/modules/AuthModule");

@Component({
	components: {
		VueSpinner,
		LayoutFooter,
	},
})
export default class LogoFooterLayout extends Vue {
  @State((state) => state.lang.originLocale) originLocale!: string;
}
