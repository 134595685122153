






































































































































































































import Vue from 'vue'
import {Component, Watch} from "vue-property-decorator";
import ListItem from "@/components/List/ListItem.vue";
import VueModal from "@/components/Modal/VueModal.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueInput from "@/components/Form/VueInput.vue";
import VueTextarea from "@/components/Form/VueTextarea.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import {State} from "vuex-class";
import {IQna, IQnaItem, IQnaEditReq, IQnaEditValidate, IQuestionType} from "@/store/models/Qna/QnaModel";
import Lang from "@/store/modules/LangModule";
import QnaModule from "@/store/modules/QnaModule";
import {IOption, ObjType} from "@/store/models/Form/FormModel";
import {IAsset} from "@/store/models/BaseModel";

@Component ({
	components: {
		ListItem,
        VueModal,
        VueButton,
        VueInput,
        VueTextarea,
        VueSelect,
        VueCheck,
        VueValidator
	}
})
export default class List extends Vue {
    @State((state) => state.lang.locale) locale!: string;
    @State((state) => state.qna.qnaList) qnaList!: IQna[];
    @State((state) => state.qna.questionTypes) questionTypes!: IQuestionType[];
    @State((state) => state.qna.uploadedFiles) uploadedFiles!: IAsset[];

    items: object = [];

    /**
	 * swiper option
	 */
	swiperOption: any = {
		direction: "vertical",
        loop: false,
        keyboard: {
            enabled: true,
        },
		slidesPerGroup: 2,
        slidesPerView: 2,
		spaceBetween: 20,
        mousewheel: true,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
        },
    }

    activeListIndex = 0;

    /**
	 * 모달 핸들러
	 */
	showModal: boolean = false;

    qnaData: IQnaEditReq = {
        siteLang: Lang.getLocaleToLang,
        title: '',
        content: '',
        url: '',
        assets: []
    }

    validator: IQnaEditValidate = {
        title: '',
        content: '',
        assets: ''
    }

    defaultInquiry: string = this.$t('qna_page.personal_inquiry.section1.selectbox').toString()

    $refs!: {
        title: HTMLInputElement;
        content: HTMLInputElement;
        uploadFile: HTMLInputElement;
    }

    get realQnaList(): IQnaItem[] {
        let list: IQnaItem[] = []

        this.qnaList.forEach((item, index) => {
            let isActive: boolean = false

            if (index === this.activeListIndex) {
                isActive = true
            }

            let questionAssets: IAsset[] = []
            let AnswerAssets: IAsset[] = []

            if (item.assets) {
                item.assets.forEach((asset) => {
                    questionAssets.push(asset)
                })
            }

            if (item.answer.assets) {
                item.answer.assets.forEach((asset) => {
                    AnswerAssets.push(asset)
                })
            }

            list.push({
                pk: item.pk,
                isActive: isActive,
                questionTypeCode: item.questionTypeCode,
                title: item.title,
                date: this.$moment(item.createAt).format('MMM DD, YYYY'),
                state: item.processStatus,
                inquiryContent: item.content,
                questionAdd: {
                    link: item.url,
                    assets: questionAssets,
                },
                answerAdd: {
                    link: item.answer.url,
                    assets: AnswerAssets,
                },
                answerContent: item.answer.content
            })
        })

        return list
    }

    get inquiryList(): IOption[] {
        let list: IOption[] = []

        this.questionTypes.forEach(item => {
            list.push({
                text: item.name,
                value: item.code,
            });
        })

        return list
    }

    get questionTypeList(): ObjType {
        let list: ObjType = {}

        this.questionTypes.forEach(item => {
            list[item.code] = item.name
        })

        return list
    }

    /**
     * slide change
     */
    onSlideChange() {
        // this.items = this.listItems

        // this.items = this.listItems.filter((item) => {
        //     return item % 4 === 0
        // })

        // console.log(this.items)
    }

    showEditModal(item: IQnaItem) {
        this.qnaData.pk = item.pk
        this.qnaData.title = item.title
        this.qnaData.content = item.inquiryContent
        this.qnaData.url = (item.questionAdd.link) ? item.questionAdd.link : ''
        this.defaultInquiry = this.questionTypeList[item.questionTypeCode]

        item.questionAdd.assets.forEach(asset => {
            QnaModule.mutationAppendFiles(asset)
        })

        this.showModal = true
    }

    clickFile() {
        if (this.uploadedFiles.length >= 5) {
            alert(this.$t('qna_page.personal_inquiry.alert.file.attach_5file').toString())
            return false;
        }

        this.$refs.uploadFile.click();
    }

    async uploadFileDragend($event: any) {
        await this.uploadFile($event.dataTransfer.files);
    }

    async uploadFileClick($event: any) {
        await this.uploadFile($event.target.files);
    }

    async uploadFile(items: File[]) {
        if (items.length > 5
            || (this.uploadedFiles.length + items.length) > 5) {
            alert(this.$t('qna_page.personal_inquiry.alert.file.attach_5file').toString());
            return false;
        }

        new Promise(((resolve, reject) => {
            items.forEach((file: File) => {
                if (file.size > 10485760) {
                    reject(this.$t('qna_page.personal_inquiry.alert.file.attach_10mb').toString());
                }
            });

            resolve(null);
        }))
        .then(async () => await QnaModule.actionUploadQna(items))
        .catch(err => alert(err));
    }

    // async uploadFile(item: any) {
    //     if (item.target.files[0].size > 10485760) {
    //         alert('10MB 이하의 파일만 첨부 가능합니다.');
    //         return false;
    //     }

    //     await QnaModule.actionUploadQna(item.target.files)
    // }

    async fileDelete(asset: IAsset) {
        await QnaModule.mutationRemoveFiles(asset)
    }

    @Watch('showModal')
    watchShowModalChange() {
        if (!this.showModal) {
            QnaModule.actionResetFiles();
        }
    }

    cancel() {
        this.showModal = false;

        this.qnaData = {
            siteLang: Lang.getLocaleToLang,
            title: '',
            content: '',
            url: '',
            assets: []
        }
    }

    validate() {
        let valid = true
        let focus = false

        if(this.qnaData.title.length < 1) {
            this.validator.title = 'validate.qna.title.required'
            valid = false

            if (!focus) {
                this.$refs.title.focus()
                focus = true
            }
        } else if (this.qnaData.title.length > 50) {
            this.validator.title = 'validate.qna.title.limited'
            valid = false

            if (!focus) {
                this.$refs.title.focus()
                focus = true
            }
        } else {
            this.validator.title = ''
        }

        if(this.qnaData.content.length < 1) {
            this.validator.content = 'validate.qna.inquiry_content.required'
            valid = false

            if (!focus) {
                this.$refs.content.focus()
                focus = true
            }
        } else {
            this.validator.content = ''
        }

        return valid
    }

    submit() {
        if(confirm(this.$t('qna_page.personal_inquiry.alert.edit').toString())){
            this.uploadedFiles.forEach((item) => {
                this.qnaData.assets.push(item.uuid)
            })

            if (this.validate()) {
                QnaModule.actionQnaEdit(this.qnaData)
                    .then(() => {
                        alert(this.$t('qna_page.personal_inquiry.alert.end_modify').toString());
                        this.showModal = false;
                        QnaModule.actionQnaList();
                    })
            }
        }
    }

    created() {
        QnaModule.actionQnaList()
    }
}
