

















































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import AboutLayout from "@/layouts/AboutIndex.vue";

@Component({
	components: {
		AboutLayout
	},
	metaInfo() {
		return {
			title: `MOBIS Introduction | MOBIS Business Connect.`,
		};
	}
})
export default class About extends Vue {
	showScrDown: boolean = true;

	scrollTop() {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}

	onScroll() {
		let pageY = window.pageYOffset;
		this.showScrDown = pageY < 100;
	}

	mounted() {
		window.addEventListener("scroll", this.onScroll);
	}

	beforeDestroy() {
		window.removeEventListener("scroll", this.onScroll);
	}
}
