











































































import Vue from 'vue'
import Component from "vue-class-component";
import VueButton from "@/components/Button/VueButton.vue";
import {State} from "vuex-class";
import {IProductShow} from "@/store/models/Product/ProductModel";
import {Prop} from "vue-property-decorator";
import ProductModule from "@/store/modules/ProductModule";

@Component({
  components: { VueButton }
})
export default class ProductDetail extends Vue {

    @Prop({default: ""}) getProductName!: string;

    @State(state => state.product.productShow)
    productShow!: IProductShow | null;

    isActive: boolean = false;

    /**
	 * swiper option
	 */
	modalSwiperOption: any = {
        loop: false,
        keyboard: {
            enabled: true,
        },
        slidesPerView: 2,
        spaceBetween: 20,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
        },
        breakpoints: {
			720: {
				slidesPerGroup: 4,
				slidesPerView: 4,
			},
		},
    }

    productData: object[] = [
        {
            category: '',
            modelName: 'Holographic HUD',
            listContent: [
                {
                    list: 'list1'
                },
                {
                    list: 'list1'
                }
            ],
            thumbNail: require("@/assets/images/temp/product/@tmp_kv.jpg"),
            videoUrl: 'https://www.youtube.com/embed/UyDzr-LnKdg',
            swiperData: [

            ]
        }
    ]

    swiperData: object[] = [
        {
            thumbNail: require('@/assets/images/temp/product/@tmp_thumb05.jpg'),
            modelName: 'related product1'
        },
        {
            thumbNail: require('@/assets/images/temp/product/@tmp_thumb05.jpg'),
            modelName: 'related product2'
        },
        {
            thumbNail: require('@/assets/images/temp/product/@tmp_thumb05.jpg'),
            modelName: 'related product3'
        },
        {
            thumbNail: require('@/assets/images/temp/product/@tmp_thumb05.jpg'),
            modelName: 'related product4'
        },
        {
            thumbNail: require('@/assets/images/temp/product/@tmp_thumb05.jpg'),
            modelName: 'related product4'
        },
    ]

	bookmarkActive() {
        if (this.productShow !== null) {
            ProductModule.actionBookmarkProduct(this.productShow)
                .then(() => {
                    ProductModule.mutationChangeBookmarkDetail();

                    if (this.productShow !== null) {
                        ProductModule.mutationChangeBookmarkMain(this.productShow);
                        ProductModule.mutationChangeBookmark(this.productShow);
                        ProductModule.mutationChangeBookmarkSearch(this.productShow);
                    }
                })
        }
	}

}
