






































import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import { ROUTE_NAME } from "@/router";
import {State} from "vuex-class";

@Component
export default class VueTag extends Vue {
    @State(state => state.lang.locale) locale!: string;

	@Prop({ default: true }) meetingNo?: boolean;
	@Prop({ default: true }) inquiryNo?: boolean;


	ROUTE_NAME = ROUTE_NAME;
}
