



























import Vue from "vue";
import Component from "vue-class-component";
import { ROUTE_NAME } from "@/router";

@Component
export default class AcessDenyPopup extends Vue {
  ROUTE_NAME = ROUTE_NAME;

  showPopup = false;

  setShow(isShow: boolean) {
    this.showPopup = isShow;
  }
}
