
























































import Component from "vue-class-component";
import {CardItemMixin} from "@/components/Mixins/CardItemMixin";
import {Prop} from "vue-property-decorator";
import VueButton from "@/components/Button/VueButton.vue";
import {MEDIA_TYPE} from "@/store/models/MediaCenter/MediaCenterModel";
import ProductModule from "@/store/modules/ProductModule";
import UserModule from "@/store/modules/UserModule";
import AuthModule from "@/store/modules/AuthModule";

@Component({
    components: {
        VueButton,
    },
})
export default class ProductCardListItem extends CardItemMixin {
    @Prop({default: "simple"}) cardType!: string;

    @Prop({default: false}) bookmarkType!: boolean;

    @Prop({default: ""}) type!: string;

    isActive: boolean = false;

    MEDIA_TYPE = MEDIA_TYPE;

    swiperOption = {
        loop: false,
        keyboard: {
            enabled: true,
        },
        slidesPerView: "auto",
        watchOverflow: true
    };

    get isLogin() {
        return AuthModule.isLogin;
    }

    bookmarkActive() {
        ProductModule.actionBookmarkProduct(this.item)
            .then(() => {
                ProductModule.mutationChangeBookmarkMain(this.item);
                ProductModule.mutationChangeBookmark(this.item);
                ProductModule.mutationChangeBookmarkSearch(this.item);
                UserModule.mutationChangeBookmarkPage(this.item);
            })
    }
}
