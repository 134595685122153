import Vue from 'vue';

export class PopupMixin extends Vue {
    get baseVideoUrl() {
        if (process.env.VUE_APP_LOCALE === 'ch') {
            return '//player.bilibili.com/player.html?bvid='
        } else {
            return 'https://www.youtube.com/embed/';
        }
    }
}
