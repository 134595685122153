import store from '@/store';
import {Action, getModule, Module, MutationAction, VuexModule} from "vuex-module-decorators";
import * as CountryApi from "@/store/api/CountryApi";
import {ICountry, ICountryState} from "@/store/models/Country/CountryModel";
import {IOption} from "@/store/models/Form/FormModel";

@Module({
    store,
    name: 'country',
    namespaced: true,
    dynamic: true
})
class CountryModule extends VuexModule implements ICountryState {

    countries: ICountry[] = [];

    @MutationAction
    async countryList() {
        const countryListRes = await CountryApi.reqCountryList();

        let countries: ICountry[] = [];

        countries = countryListRes.items;

        countries.sort((a:any, b:any) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        })

        return {
            countries: countries
        }
    }

    get getCountryCodeList() {
        const countryCodeList: IOption[] = []

        this.countries.map(country => {
            countryCodeList.push({
                text: '+'+country.code.padStart(3, '0'),
                value: country.pk,
                description: country.name
            })
        });

        return countryCodeList
    }
}

export default getModule(CountryModule);
