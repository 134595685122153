












































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import VueInput from "@/components/Form/VueInput.vue";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import UserAgreementsModal from "@/components/Modal/UserAgreementsModal.vue";
import PersonalInformationModal from "@/components/Modal/PersonalInformationModal.vue";
import AgreeMarketingModal from "@/components/Modal/AgreeMarketingModal.vue";
import { EnumLang } from "@/store/models/Lang/LangModel";
import { IOption } from "@/store/models/Form/FormModel";
import {
	IRegistryReqValidate,
	RegistryReq,
} from "@/store/models/Auth/AuthModel";
import { IPartner, IPartnerDomain } from "@/store/models/Partner/PartnerModel";
import { State } from "vuex-class";
import { ICountry } from "@/store/models/Country/CountryModel";
import Lang from "@/store/modules/LangModule";
import Auth from "@/store/modules/AuthModule";
import Partner from "@/store/modules/PartnerModule";
import Country from "@/store/modules/CountryModule";
import UserModule from "@/store/modules/UserModule";
import { IProductCategory } from "@/store/models/Product/ProductModel";
import VueSortingCheckMulti from "@/components/Form/VueSortingCheckMulti.vue";
import CountryModule from "@/store/modules/CountryModule";
import VueRegistryComplete from "@/components/Auth/VueRegistryComplete.vue";

@Component({
	components: {
		VueSortingCheckMulti,
		VueInput,
		VueCheck,
		VueButton,
		VueSelect,
		VueValidator,
		UserAgreementsModal,
		PersonalInformationModal,
		AgreeMarketingModal,
        VueRegistryComplete
	},
})
export default class VueRegistry extends Vue {
	@State((state) => state.lang.locale) locale!: string;
	@State((state) => state.country.countries) countries!: ICountry[];
	@State((state) => state.partner.partners) partners!: IPartner[];
	@State((state) => state.partner.partnerDomains)
	partnerDomains!: IPartnerDomain[];
	@State((state) => state.user.productCategories)
	productCategories!: IProductCategory[];

	partnerData: IPartner = {
		pk: 0,
		globalName: "",
		localName: "",
		url: "",
	};

	registryData: RegistryReq = {
		type: "GENERAL",
		lastName: "",
		firstName: "",
		email: "",
		password: "",
		passwordConfirmation: "",
		partnerPk: 0,
		department: "",
		position: "",
        mobileCountryPk: 0,
		mobileNum: "",
		phoneNum: "",
		countryPk: null,
		siteLang: Lang.getLocaleToLang,
		productCategories: [],
		agreementTermsOfService: false,
		agreementPrivacyPolicy: false,
		agreementMarketing: false,
	};

	validator: IRegistryReqValidate = {
		name: "",
		partner: "",
		partnerLocalName: "",
		partnerGlobalName: "",
		partnerUrl: "",
		email: "",
		password: "",
		passwordConfirmation: "",
		phone: "",
		mobile: "",
		country: "",
		department: "",
		position: "",
		productCategories: "",
	};

	countryList: IOption[] = [];
	partnerList: IOption[] = [];
	domainList: IOption[] = [];
	domainDefault: string = "registry_page.email.email_default_option";
	agreementAll: boolean = false;
	lang = EnumLang;

	email1: string = "";
	email2: string = "";
    selfDomain: boolean = false;

	phone1: string = "";
	phone2: string = "";
	phone3: string = "";

	mobile1: string = "";
	mobile2: string = "";
	mobile3: string = "";

    timezone: string = this.$moment().format('Z');
	validityTerm: string = "";

	//pages
	join: boolean = true;
	standby: boolean = false;
	authenticationMail: boolean = false;

	//modal
	userAgreements: boolean = false;
	personalInformation: boolean = false;
	agreeMarketing: boolean = false;

	$refs!: {
		firstName: HTMLInputElement;
		lastName: HTMLInputElement;
		partnerLocalName: HTMLInputElement;
		partnerGlobalName: HTMLInputElement;
		partnerUrl: HTMLInputElement;
		email: HTMLInputElement;
		password: HTMLInputElement;
		passwordConfirm: HTMLInputElement;
		phone1: HTMLInputElement;
		phone2: HTMLInputElement;
		phone3: HTMLInputElement;
		mobile1: HTMLInputElement;
		mobile2: HTMLInputElement;
		mobile3: HTMLInputElement;
		department: HTMLInputElement;
		position: HTMLInputElement;
	};

	toggleModal(name: string) {
		if (name === "userAgreementsModal") {
			this.userAgreements = !this.userAgreements;
		} else if (name === "personalInformationModal") {
			this.personalInformation = !this.personalInformation;
		} else {
			this.agreeMarketing = !this.agreeMarketing;
		}
	}

	localeToCode() {
		return Lang.getLocaleToCode;
	}

	localeToLang() {
		return Lang.getLocaleToLang;
	}

	changeAgreementAll() {
		if (!this.agreementAll) {
			this.registryData.agreementTermsOfService = true;
			this.registryData.agreementPrivacyPolicy = true;
			this.registryData.agreementMarketing = true;
		} else {
			this.registryData.agreementTermsOfService = false;
			this.registryData.agreementPrivacyPolicy = false;
			this.registryData.agreementMarketing = false;
		}
	}

	get countryCodeList(): IOption[] {
		return CountryModule.getCountryCodeList;
	}

	@Watch("registryData.agreementTermsOfService")
	@Watch("registryData.agreementPrivacyPolicy")
	@Watch("registryData.agreementMarketing")
	changeAgreement() {
		if (
			this.registryData.agreementTermsOfService &&
			this.registryData.agreementPrivacyPolicy &&
			this.registryData.agreementMarketing
		) {
			this.agreementAll = true;
		} else {
			this.agreementAll = false;
		}
	}

	@Watch("email1")
	@Watch("email2")
	setEmail() {
		this.registryData.email = this.email1 + "@" + this.email2;
	}

	@Watch("phone1")
	@Watch("phone2")
	@Watch("phone3")
	setPhone() {
		this.registryData.phoneNum =
			this.phone1 +
			"-" +
			this.phone2 +
			"-" +
			this.phone3;
	}

	@Watch("mobile1")
	@Watch("mobile2")
	@Watch("mobile3")
	setMobile() {
		this.registryData.mobileNum =
			this.mobile1 +
			"-" +
			this.mobile2 +
			"-" +
			this.mobile3;
	}

	changePartner(partnerIndex: number) {
		this.domainList = [];
		this.domainDefault = "registry_page.email.email_default_option";
		this.email2 = "";
        this.selfDomain = false;
        this.registryData.type = "GENERAL"

        this.domainList.push({
            text: "registry_page.email.email_self_option",
            value: -1,
        });

		if (partnerIndex === -1) {
            this.registryData.type = "GENERAL_NEW_PARTNER"
			this.registryData.partnerPk = null;
            this.selfDomain = true;
		} else {
			this.registryData.partnerPk = partnerIndex;
			this.partnerData = {
				pk: 0,
				localName: "",
				globalName: "",
				url: "",
			};

			Partner.actionPartnerDomainList(this.registryData.partnerPk).then(
				() => {
					for (const item of this.partnerDomains) {
						this.domainList.push({
							text: item.domain,
							value: item.domain,
						});
					}
				}
			);
		}
	}

	changeDomain(domain: string) {
        this.email2 = domain;
        this.registryData.type = "GENERAL"

        if (domain == "-1") {
            this.registryData.type = "GENERAL_NEW_DOMAIN"
            this.email2 = '';
            this.selfDomain = true;
        } else {
            this.selfDomain = false;
        }
	}

	validate() {
		let valid = true;
		let focus = false;

        this.validator = {
            name: "",
            partner: "",
            partnerLocalName: "",
            partnerGlobalName: "",
            partnerUrl: "",
            email: "",
            password: "",
            passwordConfirmation: "",
            phone: "",
            mobile: "",
            country: "",
            department: "",
            position: "",
            productCategories: "",
        };

		const emailRule =
			/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
		const passwordRule =
			/^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*?])[A-Za-z\d!@#$%^&*?]{8,16}$/;
		const phoneRule = /\d{2,3}-\d{3,4}-\d{4}/;
		const mobileRule = /\d{3}-\d{3,4}-\d{4}/;

		if (
			this.registryData.lastName.length < 1 ||
			this.registryData.firstName.length < 1
		) {
			this.validator.name = "validate.registry.name.required";
			valid = false;

			if (!focus) {
				if (this.registryData.lastName.length < 1) {
					this.$refs.lastName.focus();
					focus = true;
				} else {
					this.$refs.firstName.focus();
					focus = true;
				}
			}
		} else if (
			this.registryData.lastName.length +
				this.registryData.firstName.length <
				2 ||
			this.registryData.lastName.length +
				this.registryData.firstName.length >
				30
		) {
			this.validator.name = "validate.registry.name.real_name";
			valid = false;
		}

		if (this.registryData.partnerPk === 0) {
			this.validator.partner = "validate.registry.partner.required";
			valid = false;

			if (!focus) {
				focus = true;
			}
		} else if (this.registryData.partnerPk === null) {
			if (this.partnerData.globalName.length < 1) {
				this.validator.partnerGlobalName = "validate.registry.partner.global_required";
				valid = false;

				if (!focus) {
					this.$refs.partnerGlobalName.focus();
					focus = true;
				}
			}

			if (this.partnerData.localName.length < 1) {
				this.validator.partnerLocalName = "validate.registry.partner.local_required";
				valid = false;

				if (!focus) {
					this.$refs.partnerLocalName.focus();
					focus = true;
				}
			}

			if (this.partnerData.url.length < 1) {
				this.validator.partnerUrl = "validate.registry.partner_url.required";
				valid = false;

				if (!focus) {
					this.$refs.partnerUrl.focus();
					focus = true;
				}
			}
		}

		if (this.email1.length < 1 || this.email2.length < 1) {
			this.validator.email = "validate.registry.email.required";
			valid = false;

			if (!focus) {
				this.$refs.email.focus();
				focus = true;
			}
		} else if (!emailRule.test(this.registryData.email)) {
			this.validator.email = "validate.registry.email.email";
			valid = false;

			if (!focus) {
				this.$refs.email.focus();
				focus = true;
			}
		}

		if (this.registryData.password.length < 1) {
			this.validator.password = "validate.registry.password.required";
			valid = false;

			if (!focus) {
				this.$refs.password.focus();
				focus = true;
			}
		} else if (!passwordRule.test(this.registryData.password)) {
			this.validator.password = "validate.registry.password.not_correct";
			valid = false;

			if (!focus) {
				this.$refs.password.focus();
				focus = true;
			}
		}

		if (this.registryData.passwordConfirmation.length < 1) {
			this.validator.passwordConfirmation = "validate.registry.password_confirmation.required";
			valid = false;

			if (!focus) {
				this.$refs.passwordConfirm.focus();
				focus = true;
			}
		} else if (
			this.registryData.password != this.registryData.passwordConfirmation
		) {
			this.validator.passwordConfirmation = "validate.registry.password_confirmation.not_matched";
			valid = false;

			if (!focus) {
				this.$refs.passwordConfirm.focus();
				focus = true;
			}
		}

		if (
			(this.phone1.length > 0 ||
				this.phone2.length > 0 ||
				this.phone3.length > 0)
		) {
		    if (this.registryData.phoneCountryPk === 0) {
                this.validator.phone = "validate.country_select";
                valid = false;

                if (!focus) {
                    this.$refs.phone1.focus();
                    focus = true;
                }
            } else if (!phoneRule.test(this.registryData.phoneNum)) {
                this.validator.phone = "validate.registry.phone.not_correct";
                valid = false;

                if (!focus) {
                    this.$refs.phone1.focus();
                    focus = true;
                }
            }
		}

		if (
			this.mobile1.length < 1 ||
			this.mobile2.length < 1 ||
			this.mobile3.length < 1
		) {
			this.validator.mobile = "validate.registry.mobile.required";
			valid = false;

			if (!focus) {
				if (this.mobile1.length < 1) {
					this.$refs.mobile1.focus();
					focus = true;
				} else if (this.mobile2.length < 1) {
					this.$refs.mobile2.focus();
					focus = true;
				} else {
					this.$refs.mobile3.focus();
					focus = true;
				}
			}
		} else if (this.registryData.mobileCountryPk === 0) {
            this.validator.mobile = "validate.country_select";
            valid = false;

            if (!focus) {
                this.$refs.mobile1.focus();
                focus = true;
            }
        } else if (!mobileRule.test(this.registryData.mobileNum)) {
			this.validator.mobile = "validate.registry.mobile.not_correct";
			valid = false;

			if (!focus) {
				this.$refs.mobile1.focus();
				focus = true;
			}
		}

		if (this.registryData.productCategories.length < 1) {
			this.validator.productCategories = "validate.registry.Product_categories.required";
			valid = false;
		}

		if (
			valid &&
			(!this.registryData.agreementTermsOfService ||
				!this.registryData.agreementPrivacyPolicy)
		) {
			alert(this.$t("validate.registry.agree.confirm").toString());
			valid = false;
		}

		return valid;
	}

	async registry() {
		if (this.validate()) {
			if (this.registryData.partnerPk === null) {
				this.registryData.newPartner = this.partnerData;
			}

			if (this.registryData.phoneNum == '') {
			    this.registryData.phoneCountryPk = null;
            }

            this.validator = {
                name: "",
                partner: "",
                partnerLocalName: "",
                partnerGlobalName: "",
                partnerUrl: "",
                email: "",
                password: "",
                passwordConfirmation: "",
                phone: "",
                mobile: "",
                country: "",
                department: "",
                position: "",
                productCategories: "",
            };

			await Auth.registry(this.registryData).then((res: any) => {
				if (res.data) {
                    // show email authentication page
                    this.validityTerm = this.$moment()
                        .add(30, "days")
                        .format("MMM D, YYYY");

                    this.join = false;
                    this.standby = false;
                    this.authenticationMail = true;
				}

                if (res.errors.partnerPk) {
                    this.validator.partnerGlobalName = res.errors.partnerPk[0];
                }

                if (res.errors.email) {
                    this.validator.email = res.errors.email[0]
                }

                if (res.errors.firstName) {
                    this.validator.name = res.errors.firstName[0]
                }

                if (res.errors.lastName) {
                    this.validator.name = res.errors.lastName[0]
                }

                if (res.errors.mobileNum) {
                    this.validator.mobile = res.errors.mobileNum[0]
                }

                if (res.errors.password) {
                    this.validator.password = res.errors.password[0]
                }

                if (res.errors["newPartner.globalName"]) {
                    this.validator.partnerGlobalName = res.errors["newPartner.globalName"][0]
                }

                if (res.errors["newPartner.localName"]) {
                    this.validator.partnerLocalName = res.errors["newPartner.localName"][0]
                }

                if (res.errors["newPartner.url"]) {
                    this.validator.partnerUrl = res.errors["newPartner.url"][0]
                }
			});
		}
	}

    @Watch('locale')
    watchLocaleChange() {
        this.registryData.siteLang = Lang.getLocaleToLang;

        this.countries = [];

        Country.countryList()
        .then(() => {
            this.countries.map((country) => {
                this.countryList.push({
                    text: country.name,
                    value: country.pk
                })
            })
        })

        this.partnerList = [];

        // Partner select option
        this.partnerList.push({
            text: 'registry_page.partner.direct_input',
            value: -1,
        });

        Partner.partnerList()
        .then(() => {
            this.partners.map(partner => {
                this.partnerList.push({
                    text: partner.globalName,
                    value: partner.pk,
                });
            })
        });

        UserModule.actionMypageProductCategoryList();

        this.domainList = [];

        // Partner Domain select option
        this.domainList.push({
            text: "registry_page.country.country_default_option",
            value: -1,
        });
    }

	created() {
		Country.countryList()
        .then(() => {
            this.countries.map((country) => {
                this.countryList.push({
                    text: country.name,
                    value: country.pk
                })
            })
        });

        this.partnerList.push({
            text: this.$t('registry_page.partner.direct_input').toString(),
            value: -1,
        });
		Partner.partnerList()
        .then(() => {
            this.partners.map((partner) => {
                this.partnerList.push({
                    text: partner.globalName,
                    value: partner.pk
                })
            })
        });

		UserModule.actionMypageProductCategoryList();

		// Partner Domain select option
		this.domainList.push({
			text: "registry_page.country.country_default_option",
			value: -1,
		});
	}
}
