





















import Vue from "vue";
import { Component } from "vue-property-decorator";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueButton from "@/components/Button/VueButton.vue";

@Component({
	components: {
		VueCheck,
		VueButton,
	},
})
export default class VueUnregister extends Vue {}
