





















import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

@Component({
	components: {
		DatePicker,
	},
})
export default class VueCalendar extends Vue {
    @Prop({ default: "" }) value!: string;
	@Prop({ default: '' }) placeholder !: string
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: "MMM DD, YYYY" }) format!: string;

    meetingDay: string = '';

	$refs!: {
		input: HTMLInputElement;
	};

	disabledBeforeToday(date: object) {
		const today = new Date();
		today.setHours(0, 0, 0, 0);

		return date < today;
	}

	focus() {
		this.$refs.input.focus();
	}

    @Watch('value')
    changeValue() {
        this.meetingDay = this.$moment(this.value).format(this.format);
    }

    created() {
        this.meetingDay = this.$moment(this.value).format(this.format);
    }
}
