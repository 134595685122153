import {
    IMeetingCancelReq,
    IMeetingEdit,
    MainMeetingListRes, MeetingConfirmRes, MeetingEditDataRes,
    MeetingListRes,
    MeetingProductCategoryRes, MeetingProductRes, MeetingRes
} from "@/store/models/Meeting/MeetingModel";
import {axiosAuth} from "@/store/api/BaseApi";
import LangModule from "@/store/modules/LangModule";

/**
 * 메인 미팅 정보 리스트
 */
export async function reqMainMeetingList(): Promise<MainMeetingListRes> {

    let mainMeetingRes: MainMeetingListRes = new MainMeetingListRes();

    await axiosAuth()
        .post('/api/meeting/mainsite/list')
        .then(result => {
            mainMeetingRes = (result.data as MainMeetingListRes);
        })
        .catch(error => mainMeetingRes = error);

    return mainMeetingRes;
}

/**
 * 미팅 정보 리스트
 */
export async function reqMeetingList(): Promise<MeetingListRes> {

    let meetingRes: MeetingListRes = new MeetingListRes();

    await axiosAuth()
        .post('/api/meeting/list')
        .then(result => {
            meetingRes = (result.data as MeetingListRes);
        })
        .catch(error => meetingRes = error);

    return meetingRes;
}

/**
 * 미팅 제품 카테고리 API
 *
 * @param parentCode
 */
export async function reqMeetingProductCategory(parentCode?: number): Promise<MeetingProductCategoryRes> {

    let meetingProductCategoryRes = new MeetingProductCategoryRes();

    await axiosAuth()
        .post('/api/meeting/productCategoryList', {
            siteLang: LangModule.getLocaleToLang,
            parentCode: parentCode
        })
        .then(result => {
            meetingProductCategoryRes = (result.data.data as MeetingProductCategoryRes);
        })
        .catch(error => meetingProductCategoryRes = error);

    return meetingProductCategoryRes;
}

/**
 * 미팅 제품 API
 *
 * @param parentCode
 */
export async function reqMeetingProduct(parentCode?: number): Promise<MeetingProductRes> {

    let meetingProductRes = new MeetingProductRes();

    await axiosAuth()
        .post('/api/meeting/productList', {
            siteLang: LangModule.getLocaleToLang,
            parentCode: parentCode
        })
        .then(result => {
            meetingProductRes = (result.data as MeetingProductRes);
        })
        .catch(error => meetingProductRes = error);

    return meetingProductRes;
}

/**
 * 미팅 등록 / 수정 API
 */
export async function reqMeetingEdit(meetingReq: IMeetingEdit): Promise<MeetingRes> {

    let meetingRes = new MeetingRes();

    await axiosAuth()
        .post('/api/meeting/edit',
            meetingReq
        )
        .then(result => {
            meetingRes = (result.data.data as MeetingRes);
        })
        .catch(error => meetingRes = error);

    return meetingRes
}

/**
 * 미팅 조회 API
 */
export async function reqMeetingDetail(pk: number): Promise<MeetingRes> {

    let meetingRes = new MeetingRes();

    await axiosAuth()
        .post('/api/meeting/detail',{
            pk: pk
        })
        .then(result => {
            meetingRes = (result.data as MeetingRes);
        })
        .catch(error => meetingRes = error);

    return meetingRes
}

/**
 * 미팅 수정데이터 조회 API
 */
export async function reqMeetingEditData(pk: number): Promise<MeetingEditDataRes> {

    let meetingEditDataRes = new MeetingEditDataRes();

    await axiosAuth()
        .post('/api/meeting/editData',{
            pk: pk
        })
        .then(result => {
            meetingEditDataRes = (result.data as MeetingEditDataRes);
        })
        .catch(error => meetingEditDataRes = error);

    return meetingEditDataRes
}

export async function reqMeetingCancel(meetingCancelReq: IMeetingCancelReq): Promise<MeetingRes> {
    let meetingRes = new MeetingRes();

    await axiosAuth()
        .post(
            '/api/meeting/cancel',
            meetingCancelReq
        )
        .then(result => {
            meetingRes = (result.data as MeetingRes);
        })
        .catch(error => meetingRes = error);

    return meetingRes
}

/**
 * 미팅 사용자 확인
 * @param pk
 */
export async function reqMeetingUserConfirm(pk: number): Promise<MeetingConfirmRes> {

    let meetingConfirmRes: MeetingConfirmRes = new MeetingConfirmRes();

    await axiosAuth()
        .post('/api/meeting/confirm', {
            pk: pk
        })
        .then(result => {
            meetingConfirmRes = (result.data as MeetingConfirmRes)
        })
        .catch(error => meetingConfirmRes = error);

    return meetingConfirmRes;
}

export async function reqMeetingNew(): Promise<MeetingEditDataRes> {
    let meetingNewRes: MeetingEditDataRes = new MeetingEditDataRes();

    await axiosAuth()
        .post('/api/meeting/new', {})
        .then(result => {
            meetingNewRes = (result.data as MeetingEditDataRes);
        })
        .catch(error => meetingNewRes = error);

    return meetingNewRes
}
