














































































import Vue from 'vue';
import Component from "vue-class-component";
import Layout from "@/layouts/index.vue";
import VueTitle from "@/components/Title/VueTitle.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueCard from "@/components/Card/VueCard.vue";
import {State} from "vuex-class";
import {IProduct} from "@/store/models/Product/ProductModel";
import ProductModule from "@/store/modules/ProductModule";
import {Watch} from "vue-property-decorator";
import ProductDetail from "@/components/Product/Detail.vue";
import VueModal from "@/components/Modal/VueModal.vue";
import LoginModalModule from "@/store/modules/LoginModalModule";

@Component({
    components: {Layout, VueTitle, VueButton, VueCard, ProductDetail, VueModal}
})
export default class Search extends Vue {

    @State(state => state.product.searchProducts)
    products!: IProduct[];

    @State(state => state.lang.locale)
    locale!: string;

    @State(state => state.product.productShow)
    productShow!: IProduct | null;

    activeProductItem: IProduct | null = null;

    searchData: string = '';

    focusActive: boolean = false

    activePage: number = 1;

    toggleModal: boolean = false;

    closeModal() {
        this.toggleModal = false;

        if (this.productShow?.pk) {
            ProductModule.actionProductShowEnd(this.productShow.pk);
        }
    }

    initSearch() {
        this.searchData = '';
    }

    focus(focusValue: boolean) {
        this.focusActive = focusValue;
    }

    get getProductName() {
        return this.activeProductItem?.productCategoryName;
    }

    openModal(item: IProduct) {
        ProductModule.actionProductShow(item.pk)
            .then((res) => {
                if (res.data) {
                    this.activeProductItem = item;
                    this.toggleModal = true;
                    ProductModule.actionProductShowStart(item.pk);
                }

                if (res.status === 423 || res.status === 401) {
                    LoginModalModule.show(() => {
                        ProductModule.actionProductShow(item.pk)
                            .then((res) => {
                                if (res.data) {
                                    this.activeProductItem = item;
                                    this.toggleModal = true;
                                    ProductModule.actionProductShowStart(item.pk);
                                }
                            });
                    })
                }
            })
    }

    slideChange(handler: string) {
        if (handler === 'left') {
            ++this.activePage;
        } else {
            --this.activePage;
        }
    }

    created() {
        if (this.$route.query?.searchText) {
            this.searchData = this.$route.query.searchText.toString();
        }

        ProductModule.actionSearchProductList({
            search: this.searchData
        })
    }

    searching() {
        ProductModule.actionSearchProductList({
            search: this.searchData
        })
    }

    @Watch('locale')
    watchLocaleChange() {
        ProductModule.actionSearchProductList({
            search: this.searchData
        })
    }
}
