








import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class VueValidator extends Vue {
	@Prop({ default: "" }) ValidError?: string;
	@Prop({ default: false }) typeAll?: boolean;
}
