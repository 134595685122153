import moment from 'moment';

export default class Utils {
    getUUID(): string {
        return Math.random().toString(8).substr(2, 9);
    }

    df(date: string, locale: string): string {
        console.log(locale);
        switch (locale) {
            case 'ko':
            case 'zh':
            case 'ja':
                return moment(date).format('YYYY .MM .DD');
            case 'en':
            default:
                return moment(date).format('MM .DD .YYYY');
        }
    }
}
