


























































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";

@Component({
	metaInfo() {
		return {
			title: this.$route.meta.title,
		};
	},
})
export default class Link extends Vue {}
