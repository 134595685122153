










import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/layouts/index.vue";
import VueResetPasswordUser from "@/components/Auth/VueResetPasswordUser.vue";

@Component({
	components: { Layout, VueResetPasswordUser },
})
export default class ResetPasswordUser extends Vue {
}
