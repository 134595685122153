

















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import {ROUTE_NAME} from "@/router";
import {State} from "vuex-class";
import LogoFooterLayout from "@/layouts/LogoFooterIndex.vue";
import VueButton from "@/components/Button/VueButton.vue";
@Component({
	components: {
		LogoFooterLayout,
        VueButton
	}
})
export default class PressKit extends Vue {
    ROUTE_NAME = ROUTE_NAME;

    @State((state) => state.lang.locale) locale!: string;

    goIntroduction() {
		window.open('//drive.google.com/drive/folders/1CN_OehWEwyc5GjykQcYVSkG6D3KiaUEd?usp=sharing', '_blank');
	}

	goPressRelease() {
		window.open('//drive.google.com/drive/folders/1A1hz0J1imIzcFouSdAYMqydrb5CD7U8Q?usp=sharing', '_blank');
	}

	goPhoto() {
		window.open('//drive.google.com/drive/folders/1kDDZe9r2vvdBXPRTrtI9hhlBcrCC_LOT?usp=sharing', '_blank');
	}

	goVideo() {
		window.open('//drive.google.com/drive/folders/1BVwJoqCk8l6dP9SlugDiMTJwzbdd60tS?usp=sharing', '_blank');
	}

	goContactInfo() {
		window.open('//drive.google.com/drive/folders/1nwtt4aQ5Z0kTIJDFoKcZJsTGz4YcsGEG?usp=sharing', '_blank');
	}
}
