import {BaseRes} from "@/store/models/BaseModel";
import * as BaseApi from "@/store/api/BaseApi";
import {PartnerDomainInfoRes, PartnerDomainListRes, PartnerListRes} from "@/store/models/Partner/PartnerModel";

/**
 * 협력사 리스트
 */
export async function reqPartnerList(): Promise<PartnerListRes> {

    let partnerList: PartnerListRes = new PartnerListRes();

    await BaseApi.axiosAuth()
        .post('/api/partner/list')
        .then(result => {
            partnerList = (result.data.data as PartnerListRes)
        })
        .catch(error => partnerList = error);
    return partnerList;
}

/**
 * 파트너 도메인 리스트
 *
 * @param partnerPk
 */
export async function reqPartnerDomainList(partnerPk: number): Promise<PartnerDomainListRes> {

    let partnerDomainList: PartnerDomainListRes = new PartnerDomainListRes();

    await BaseApi.axiosAuth()
        .post('/api/partner/domain/list', {partnerPk: partnerPk})
        .then(result => {
            partnerDomainList = (result.data.data as PartnerDomainListRes);
        })
        .catch(error => partnerDomainList = error);
    return partnerDomainList;
}

export async function reqPartnerDomainInfo(domain: string): Promise<PartnerDomainInfoRes> {

    let partnerDomainInfo: PartnerDomainInfoRes = new PartnerDomainInfoRes();

    await BaseApi.axiosDefault()
        .post('/api/partner/show-by-domain', {domain: domain})
        .then(result => {
            partnerDomainInfo = (result.data as PartnerDomainInfoRes);
        })
        .catch(error => partnerDomainInfo = error);
    return partnerDomainInfo;
}
