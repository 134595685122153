










import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/layouts/index.vue";
import VueResetPassword from "@/components/Auth/VueResetPassword.vue";
import {ROUTE_NAME} from "@/router";

@Component({
	components: { Layout, VueResetPassword },
})
export default class ResetPassword extends Vue {

    created() {
        if (!this.$route.query?.uuid) {
            this.$router.push({
                name: ROUTE_NAME.ERROR500
            })
        }
    }

}
