import {BaseRes} from "@/store/models/BaseModel";
import {
    LoginReq,
    RegistryReq,
    UserInfoRes,
    ResetPasswordRes,
    UserLoginRes,
    UserRegistryRes,
    IFindEmailReq,
    FindEmailRes,
    SendPasswordAuthEmailRes,
    CheckPasswordAuthEmailRes,
    IResetPassword,
    SessionTokenRes, CheckEmailAuthRes, ICheckEmailAuthReq, IUserPasswordResetReq, UserPasswordResetRes
} from "@/store/models/Auth/AuthModel";
import * as BaseApi from "@/store/api/BaseApi";
import {axiosAuth, axiosDefault, sessionAuth} from "@/store/api/BaseApi";

/**
 * 로그인
 *
 * @param loginReq
 */
export async function reqUserLogin(loginReq: LoginReq): Promise<UserLoginRes> {

    let userLoginRes: UserLoginRes = new UserLoginRes();

    await BaseApi.axiosDefault()
        .post(
            '/api/sign/in',
            loginReq
        )
        .then((result) => {
            userLoginRes = (result.data as UserLoginRes);
        })
        .catch((error) => {
            userLoginRes = error;
        })
    return userLoginRes;
}

/**
 * 회원가입
 *
 * @param registryReq
 */
export async function reqUserRegistry(registryReq: RegistryReq): Promise<UserRegistryRes> {

    let userRegistryRes: UserRegistryRes = new UserRegistryRes();

    await BaseApi.axiosDefault()
        .post(
            '/api/sign/up',
            registryReq
        )
        .then((result) => {
            userRegistryRes = (result.data as UserRegistryRes);
        })
        .catch((error) => {
            userRegistryRes = error;
        })
    return userRegistryRes;
}

export async function reqFindEmail(findEmailReq: IFindEmailReq): Promise<FindEmailRes> {

    let findEmailRes: FindEmailRes = new FindEmailRes();

    await axiosDefault()
        .post(
            '/api/sign/find-email',
            findEmailReq
        )
        .then((result) => {
            findEmailRes = (result.data as FindEmailRes);
        })
        .catch((error) => {
            findEmailRes = error;
        })

    return findEmailRes
}

/**
 * 패스워드 재설정 1단계. 이메일 전송
 * @param email
 */
export async function reqSendPasswordAuthEmail(email: string): Promise<SendPasswordAuthEmailRes> {

    let sendPasswordAuthEmailRes: SendPasswordAuthEmailRes = new SendPasswordAuthEmailRes();

    await axiosDefault()
        .post('/api/sign/send-password-auth-email', {
            email: email
        })
        .then(result => {
            sendPasswordAuthEmailRes = (result.data as SendPasswordAuthEmailRes);
        })
        .catch(error => {
            sendPasswordAuthEmailRes = error;
        });

    return sendPasswordAuthEmailRes;
}

/**
 * 패스워드 변경 2단계 uuid 유효성 인증
 * @param uuid
 */
export async function reqCheckPasswordAuthEmail(uuid: string): Promise<CheckPasswordAuthEmailRes> {

    let checkPasswordAuthEmailRes: CheckPasswordAuthEmailRes = new CheckPasswordAuthEmailRes();

    await axiosDefault()
        .post('/api/sign/check-password-auth-email', {
            uuid: uuid
        })
        .then(result => {
            checkPasswordAuthEmailRes = (result.data.data as CheckPasswordAuthEmailRes);
        })
        .catch(error => {
            checkPasswordAuthEmailRes = error;
        })

    return checkPasswordAuthEmailRes;
}

export async function reqResetPassword(req: IResetPassword): Promise<ResetPasswordRes> {

    let resetPasswordRes: ResetPasswordRes = new ResetPasswordRes();

    // TODO: 비밀번호 재설정이 이게 아닌갑다...
    await axiosDefault()
        .post('/api/sign/reset-password',req)
        .then((result) => {
            resetPasswordRes = (result.data.data as ResetPasswordRes);
        })
        .catch((error) => {
            resetPasswordRes = error;
        })

    return resetPasswordRes
}

export async function reqWithdrwal(): Promise<BaseRes> {
    let userWithdrawalRes: BaseRes = new BaseRes();

    await BaseApi.axiosAuth()
        .post('/api/sign/withdrawal', {})
        .then(result => {
            userWithdrawalRes = (result.data as BaseRes);
        })
        .catch(error => userWithdrawalRes = error)

    return userWithdrawalRes;
}

/**
 * 세션 토큰 요청
 */
export async function reqGetSessionToken(): Promise<SessionTokenRes> {

    let sessionTokenRes: SessionTokenRes = new SessionTokenRes();

    await BaseApi.sessionAuth()
        .post('/api/sign/session-token', {})
        .then(result => {
            sessionTokenRes = (result.data as SessionTokenRes);
        })
        .catch(error => sessionTokenRes = error);

    return sessionTokenRes;
}

/**
 * 패스워드 변경 2단계 uuid 유효성 인증
 * @param uuid
 * @param type
 */
export async function reqCheckEmailAuth (req: ICheckEmailAuthReq): Promise<CheckEmailAuthRes> {

    let checkEmailAuthRes: CheckEmailAuthRes = new CheckEmailAuthRes();

    await axiosDefault()
        .post('/api/sign/check-auth-email', req)
        .then(result => {
            checkEmailAuthRes = (result.data as CheckEmailAuthRes);
        })
        .catch(error => {
            checkEmailAuthRes = error;
        })

    return checkEmailAuthRes;
}

/**
 * 사용자 패스워드 변경
 * @param req
 */
export async function reqUserPasswordReset(req: IUserPasswordResetReq): Promise<UserPasswordResetRes> {

    let userPasswordResetRes: UserPasswordResetRes = new UserPasswordResetRes();

    await axiosAuth()
        .post('/api/user/account/reset-password', req)
        .then(result => {
            userPasswordResetRes = (result.data as UserPasswordResetRes);
        })
        .catch(error => {
            userPasswordResetRes = error;
        });

    return userPasswordResetRes;
}
