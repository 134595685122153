



































































import Component from "vue-class-component";
import {CardItemMixin} from "@/components/Mixins/CardItemMixin";
import {Prop} from "vue-property-decorator";
import VueButton from "@/components/Button/VueButton.vue";
import VueTag from "@/components/Tag/VueTag.vue";

@Component ({
	components: {
		VueButton, VueTag
	}
})
export default class TextCardListItem extends CardItemMixin {
    @Prop({default: () => []}) buttonType!:	string[];

    isActive: boolean = false

    bookmarkActive() {
        this.isActive = !this.isActive
    }

    clickEvent(type: string) {
        this.$emit('click', {
            type: type, value: this.item
        })
    }
}
