import {IStatisticsMenu, IStatisticsProduct, StatisticsRes} from "@/store/models/Statistics/StatisticsApiModel";
import {axiosAuth, noticeAxios} from "@/store/api/BaseApi";

export async function reqShowProduct(req: IStatisticsProduct): Promise<StatisticsRes> {

    let res: StatisticsRes = new StatisticsRes();

    noticeAxios()
    .post('/api/statistics/product/view-start', req)
    .then(result => {
        res = (result.data as StatisticsRes);
    })
    .catch(error => res = error);

    return res;
}

export async function reqLeaveProduct(req: IStatisticsProduct): Promise<StatisticsRes> {
    let res: StatisticsRes = new StatisticsRes();

    noticeAxios()
        .post('/api/statistics/product/view-end', req)
        .then(result => {
            res = (result.data as StatisticsRes);
        })
        .catch(error => res = error);

    return res;
}

export async function reqShowMenu(req: IStatisticsMenu): Promise<StatisticsRes> {

    let res: StatisticsRes = new StatisticsRes();

    noticeAxios()
        .post('/api/statistics/menu-start', req)
        .then(result => {
            res = (result.data as StatisticsRes);
        })
        .catch(error => res = error);

    return res;
}

export async function reqLeaveMenu(req: IStatisticsMenu): Promise<StatisticsRes> {

    let res: StatisticsRes = new StatisticsRes();

    noticeAxios()
        .post('/api/statistics/menu-end', req)
        .then(result => {
            res = (result.data as StatisticsRes);
        })
        .catch(error => res = error);

    return res;
}
