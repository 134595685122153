














import Vue from 'vue';
import {Component} from "vue-property-decorator";
import Layout from "@/layouts/index.vue";
import VueRegistryComplete from "@/components/Auth/VueRegistryComplete.vue";
import AuthModule from "@/store/modules/AuthModule";
import {ROUTE_NAME} from "@/router";

@Component({
    components: { Layout, VueRegistryComplete }
})
export default class RegistryComplete extends Vue {

    title: string = 'login_page.registry_complete.subject';

    message1: string = 'login_page.registry_complete.message1';

    message2: string = 'login_page.registry_complete.message2';

   created() {
        // 인증 요청
        AuthModule.actionCheckEmailAuth({
            uuid: this.$route.query.uuid.toString(),
            type: this.$route.query.type.toString().toUpperCase()
        })
            .then((result => {
                if (result.data.type != 'GENERAL') {
                    this.title = "login_page.approval_pending.subject"
                    this.message1 = "login_page.approval_pending.message1"
                    this.message2 = "login_page.approval_pending.message2"
                }

                if (result.errors?.uuid) {
                    if (result.errors.uuid[0] === "협력사가 승인 상태가 아닙니다.") {
                        this.title = 'login_page.approval_pending.subject';
                        this.message1 = 'login_page.approval_pending.message1';
                        this.message2 = 'login_page.approval_pending.message2';
                    } else {
                        this.$router.push({
                            name: ROUTE_NAME.ERRORCERT
                        });
                    }
                }
            }))
            .catch(() => {
                this.$router.push({
                    name: ROUTE_NAME.ERRORCERT,
                    params: this.$route.params,
                })
            })
    }

}
