










import Vue from "vue";
import {Component} from "vue-property-decorator";
import Layout from "@/layouts/index.vue";
import VueLogin from "@/components/Auth/VueLogin.vue";
import VueRegistry from "@/components/Auth/VueRegistry.vue";

@Component({
    components: {
        Layout,
        VueLogin,
        VueRegistry,
    },
    metaInfo() {
        return {
            title: `Sign in | MOBIS Business Connect.`,
        };
    },
})
export default class Login extends Vue {
    switcher: string = "VueLogin";

    mounted() {
        if (this.$route.params?.target && (this.$route.params.target.toString() === 'VueLogin' || this.$route.params.target.toString() === 'VueRegistry')) {
            this.switcher = this.$route.params.target.toString();
        }
    }
}
