import store from '@/store';
import {Action, getModule, Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    IProduct,
    IProductCategory, IProductListReq, IProductShow,
    IProductState,
    ProductCategoryListRes, ProductListRes, ProductShowRes
} from "@/store/models/Product/ProductModel";
import {
    reqBookmarkProduct,
    reqProductCategoryList,
    reqProductList,
    reqProductShow,
    reqUserProductCategories
} from "@/store/api/ProductApi";
import {BaseRes} from "@/store/models/BaseModel";
import {reqLeaveProduct, reqShowProduct} from "@/store/api/StatisticsApi";

@Module({
    store,
    name: 'product',
    namespaced: true,
    dynamic: true
})
class ProductModule extends VuexModule implements IProductState {

    products: IProduct[] = [];

    mainProducts: IProduct[] = [];

    searchProducts: IProduct[] = [];

    productCategories: IProductCategory[] = [];

    userProductCategories: IProductCategory[] = [];

    productShow: IProductShow | null = null;

    @Mutation
    async mutationChangeBookmark(item: IProduct) {
        this.products.map(product => {
            if (product.pk === item.pk) {
                product.bookmark = !product.bookmark
            }
        });
    }

    @Mutation
    async mutationChangeBookmarkDetail() {
        if (this.productShow !== null) {
            this.productShow.bookmark = !this.productShow.bookmark;
        }
    }

    @Mutation
    async mutationChangeBookmarkMain(item: IProduct) {
        this.mainProducts.map(product => {
            if (product.pk === item.pk) {
                product.bookmark = !product.bookmark;
            }
        })
    }

    @Mutation
    async mutationChangeBookmarkSearch(item: IProduct) {
        this.searchProducts.map(product => {
            if (product.pk === item.pk) {
                product.bookmark = !product.bookmark;
            }
        })
    }

    @Mutation
    async mutationChangeProductShow(productShow: IProductShow) {
        this.productShow = productShow
    }

    /**
     * 메인 제품 리스트
     */
    @MutationAction
    async actionMainProductList(parentPks: number[]) {
        const mainProductList: ProductListRes = await reqProductList({
            productCategoryParentPkList: parentPks
        });

        return {
            mainProducts: mainProductList.products
        }
    }

    /**
     * 프로덕트 리스트
     */
    @MutationAction
    async actionProductList(productListReq: IProductListReq) {
        const productListRes: ProductListRes = await reqProductList({
            ...productListReq
        });

        return {
            products: productListRes.products
        }
    }

    /**
     * 검색 프로덕트
     * @param productListReq
     */
    @MutationAction
    async actionSearchProductList(productListReq: IProductListReq) {
        const productListRes: ProductListRes = await reqProductList({
            ...productListReq
        })

        return {
            searchProducts: productListRes.products
        };
    }

    /**
     * 카테고리 리스트
     * @param parentPk
     */
    @MutationAction
    async actionProductCategoryList(parentPk?: number) {
        const productCategoryListRes: ProductCategoryListRes = await reqProductCategoryList({
            parentPk: parentPk
        });

        return {
            productCategories: productCategoryListRes.productCategories
        }
    }

    /**
     * 제품 상세
     * @param productPk
     */
    @Action
    async actionProductShow(productPk: number) {
        const productShowRes: ProductShowRes = await reqProductShow(productPk);
        await this.mutationChangeProductShow(productShowRes.data);
        return productShowRes;
    }

    /**
     * 유저 관심제품
     */
    @MutationAction
    async actionUserProductCategoryList() {
        const productCategoryList: ProductCategoryListRes = await reqUserProductCategories();

        return {
            userProductCategories: productCategoryList.productCategories
        };
    }

    /**
     * 북마크 처리
     * @param product
     */
    @Action
    async actionBookmarkProduct(product: IProduct) {
        await reqBookmarkProduct(product.pk);
    }

    @Action
    async actionProductShowStart(productPk: number) {
        await reqShowProduct({
            pk: productPk
        });
    }

    @Action
    async actionProductShowEnd(productPk: number) {
        await reqLeaveProduct({
            pk: productPk
        });
    }
}

export default getModule(ProductModule);
