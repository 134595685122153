export interface ILang {
    locales: string[]
    locale: string
    htmlLang: string
}

export enum EnumLang {
    'en',
    'kr',
    'ch',
    'jp',
}
