






















































































































import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import VueButton from "@/components/Button/VueButton.vue";
import VueInput from "@/components/Form/VueInput.vue";
import VueTextarea from "@/components/Form/VueTextarea.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import {State} from "vuex-class";
import {IQnaEditReq, IQnaEditValidate, IQuestionType} from "@/store/models/Qna/QnaModel";
import {IOption} from "@/store/models/Form/FormModel";
import Lang from "@/store/modules/LangModule";
import QnaModule from "@/store/modules/QnaModule";
import {IAsset} from "@/store/models/BaseModel";

@Component({
	components: {
		VueButton,
		VueInput,
		VueTextarea,
		VueSelect,
		VueCheck,
		VueValidator,
	},
})
export default class VuePersonalInquiry extends Vue {
    @State((state) => state.lang.locale) locale!: string;
    @State((state) => state.qna.questionTypes) questionTypes!: IQuestionType[];
    @State((state) => state.qna.uploadedFiles) uploadedFiles!: IAsset[];

    qnaData: IQnaEditReq = {
        siteLang: Lang.getLocaleToLang,
        title: '',
        content: '',
        url: '',
        assets: []
    }

    validator: IQnaEditValidate = {
        title: '',
        content: '',
        assets: ''
    }

    defaultInquiry: string = this.$t('qna_page.personal_inquiry.section1.selectbox').toString()

    $refs!: {
        title: HTMLInputElement;
        content: HTMLInputElement;
        uploadFile: HTMLInputElement;
    }

    get inquiryList(): IOption[] {
        let list: IOption[] = []

        this.questionTypes.forEach(item => {
            list.push({
                text: item.name,
                value: item.code,
            });
        })

        return list
    }

    clickFile() {
        if (this.uploadedFiles.length >= 5) {
            alert(this.$t('qna_page.personal_inquiry.alert.file.attach_5file').toString())
            return false;
        }

        this.$refs.uploadFile.click();
    }

    async uploadFileDragend($event: any) {
        await this.uploadFile($event.dataTransfer.files);
    }

    async uploadFileClick($event: any) {
        await this.uploadFile($event.target.files);
    }

    async uploadFile(items: File[]) {
        if (items.length > 5
            || (this.uploadedFiles.length + items.length) > 5) {
            alert(this.$t('qna_page.personal_inquiry.alert.file.attach_5file').toString());
            return false;
        }

        new Promise(((resolve, reject) => {
            items.forEach((file: File) => {
                if (file.size > 10485760) {
                    reject(this.$t('qna_page.personal_inquiry.alert.file.attach_10mb').toString());
                }
            });

            resolve(null);
        }))
        .then(async () => await QnaModule.actionUploadQna(items))
        .catch(err => alert(err));
    }

	/**
	 * file uploade delete
	 */
	async fileDelete(asset: IAsset) {
		await QnaModule.mutationRemoveFiles(asset)
	}

    @Watch("locale")
    changeSiteLang() {
        this.qnaData.siteLang = Lang.getLocaleToLang;
        this.defaultInquiry = this.$t('qna_page.personal_inquiry.section1.selectbox').toString();
    }

    cancel() {
	    if (confirm(this.$t('validate.qna.confirm2').toString())) {
            this.qnaData = {
                siteLang: Lang.getLocaleToLang,
                title: '',
                content: '',
                url: '',
                assets: []
            }

            this.$emit('update:activeIndex', 1)
        }
    }

    validate() {
	    let valid = true
        let focus = false
        let reg = /<(\/)?([a-zA-Z0-9]*)(\s[a-zA-Z0-9]*=[^>]*)?(\s)*(\/)?>/ig;

        this.validator = {
            title: '',
            content: '',
            assets: ''
        }

        if(this.qnaData.title.length < 1) {
            this.validator.title = 'validate.qna.title.required'
            valid = false

            if (!focus) {
                this.$refs.title.focus()
                focus = true
            }
        } else if (this.qnaData.title.length > 50) {
            this.validator.title = 'validate.qna.title.limited'
            valid = false

            if (!focus) {
                this.$refs.title.focus()
                focus = true
            }
        }

        if(this.qnaData.content.length < 1) {
            this.validator.content = 'validate.qna.inquiry_content.required'
            valid = false

            if (!focus) {
                this.$refs.content.focus()
                focus = true
            }
        }

        if(reg.test(this.qnaData.title)) {
            this.qnaData.title = this.qnaData.title.replace(reg, "");
        }

        if(reg.test(this.qnaData.content)) {
            this.qnaData.content = this.qnaData.content.replace(reg, "");
        }

        return valid
    }

    async submit() {
		if(confirm(this.$t('qna_page.personal_inquiry.alert.submit_qna').toString())){

			this.uploadedFiles.forEach((item) => {
				this.qnaData.assets.push(item.uuid)
			})

			if (this.validate()) {
				QnaModule.actionQnaEdit(this.qnaData)
					.then(res => {
						if (res.data.pk) {
                            alert(this.$t('qna_page.personal_inquiry.alert.inquiry_registered').toString());
                            this.$emit('update:activeIndex', 1)
						}
					})
			}
		}
    }

    destroyed() {
        QnaModule.actionResetFiles();
    }
}
