
















































































import Vue from "vue";
import Component from "vue-class-component";
import VueModal from "@/components/Modal/VueModal.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueTag from "@/components/Tag/VueTag.vue";
import { State } from "vuex-class";
import {
	IMeeting,
	IMeetingUser,
	IMeetingCancelReq,
	MainMeetingStatus,
} from "@/store/models/Meeting/MeetingModel";
import Lang from "@/store/modules/LangModule";
import { EnumLang } from "@/store/models/Lang/LangModel";
import VueTextarea from "@/components/Form/VueTextarea.vue";
import MeetingModule from "@/store/modules/MeetingModule";
import { Prop } from "vue-property-decorator";
import VueValidator from "@/components/Validator/VueValidator.vue";

@Component({
	components: {
		VueModal,
		VueButton,
		VueTag,
		VueTextarea,
        VueValidator,
	},
})
export default class MeetingCancelModal extends Vue {
	@State((state) => state.meeting.meetingDetail) meetingInfo!: IMeeting;

	@Prop({ default: MainMeetingStatus.REQUEST }) meetingStatus!: string;

	meetingCancelData: IMeetingCancelReq = {
		pk: 0,
		reason: "",
	};

    reasonValidator: string = '';

	$refs!: {
		reason: HTMLInputElement;
	};

	get meetingDate() {
		let meetingAt = this.$moment(this.meetingInfo.meetingAt);
		let date = meetingAt.format("MMM D, YYYY");
		let time = meetingAt.format("HH:mm");

		return date + " / " + time;
	}

	localeToCode() {
		return Lang.getLocaleToCode;
	}

	getName(item: IMeetingUser) {
		return this.localeToCode() === EnumLang.en + 1
			? item.firstName + " " + item.lastName
			: item.lastName + item.firstName;
	}

	async meetingCancel() {
		// 취소 처리
			if (confirm(this.$t('meeting_page.modify.alert.cancel_right').toString())) {
				await MeetingModule.actionMeetingCancel(
					this.meetingCancelData
				).then((res: any) => {
                    if (res.data) {
                        MeetingModule.actionMeetingList();
                        this.$emit("close");
                    }

                    if (res.errors) {
                        this.reasonValidator = res.errors.reason[0];
                        this.$refs.reason.focus();
                    }
				});
			}
	}

	mounted() {
		this.meetingCancelData.pk = this.meetingInfo.pk;
	}
}
