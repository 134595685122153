





























import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import MainCardList from "@/components/Card/MainCardList.vue";
import ImageCardList from "@/components/Card/ImageCardList.vue";
import TextCardList from "@/components/Card/TextCardList.vue";

interface SwiperElement extends HTMLElement {
    swiperHandler: any;
}

@Component({
	components: {
		MainCardList,
		ImageCardList,
		TextCardList
	}
})
export default class VueCard extends Vue {
	/**
	 * 화면에따른 타입
	 */
	@Prop({ default: "" }) type!: string;

	/**
	 * 이미지/텍스트 타입
	 */
	@Prop({ default: "" }) cardListType!: string;

	/**
	 * 데이터
	 */
	@Prop({ default: {} }) items!: object;

	@Prop({ default: "simple" }) cardType!: string;

	@Prop({ default: false }) bookmarkType!: boolean;

    $refs!: {
        imageCard: SwiperElement
    }

	get swiperHandler() {
	    return this.$refs.imageCard.swiperHandler;
    }

	clickEvent(payload: any) {
		this.$emit("click", payload);
	}

}
