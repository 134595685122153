import store from '@/store';
import {Action, getModule, Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {IKeyvisualState, IMainKeyvisual} from "@/store/models/Keyvisual/KeyvisualModel";
import {reqKeyvisualUpCount, reqMainKeyVisualList} from "@/store/api/KeyvisualApi";

@Module({
    store,
    name: 'keyvisual',
    namespaced: true,
    dynamic: true
})
class KeyvisualModule extends VuexModule implements IKeyvisualState {

    mainKeyvisuals: IMainKeyvisual[] = [];

    @MutationAction
    async actionMainKeyVisualList() {
        const mainKeyVisualListRes = await reqMainKeyVisualList();

        return {
            mainKeyvisuals: mainKeyVisualListRes.items
        }
    }

    @Action
    async actionKeyvisualUpCount(keyvisualPk: number) {
        await reqKeyvisualUpCount(keyvisualPk);
    }
}

export default getModule(KeyvisualModule);
