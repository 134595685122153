



































import Vue from 'vue';
import {Component, Watch} from "vue-property-decorator";
import Layout from "@/layouts/index.vue";
import VuePersonalInquiry from "@/components/PersonalInquiry/index.vue";
import List from "@/components/List/index.vue";
import VueListTab from "@/components/Tab/VueListTab.vue";
import VueTitle from "@/components/Title/VueTitle.vue";
import {ICommonCode} from "@/store/models/CommonModel";
import QnaModule from "@/store/modules/QnaModule";
import {State} from "vuex-class";

@Component({
    components: {
        Layout, VueListTab, VueTitle, VuePersonalInquiry, List
    },
    metaInfo() {
        return {
            title: `Inquiry | MOBIS Business Connect.`
        };
    },
})
export default class Qna extends Vue {

    @State(state => state.lang.locale)
    locale!: string;

    switcher: string[] = [
        'VuePersonalInquiry',
        'List'
    ];

    activeIndex: number | string = 0;

    tabList: ICommonCode[] = [
        {
            name: "qna_page.tabs.personal_inquiry",
            pk: 0,
        },
        {
            name: "qna_page.tabs.inquiry_list",
            pk: 1,
        },
    ]

    created() {
        QnaModule.actionQuestionType()

        this.activeIndex = this.$route.query.activeIndex
            ? this.$route.query.activeIndex.toString()
            : 0;
    }

    @Watch('locale')
    watchChangeLocale() {
        QnaModule.actionQuestionType();
    }
}
