import {
    IProductCategoryListReq, IProductListReq,
    ProductCategoryListRes,
    ProductGnbCategoryRes, ProductListRes, ProductShowRes
} from "@/store/models/Product/ProductModel";
import {axiosAuth} from "@/store/api/BaseApi";
import {BaseRes} from "@/store/models/BaseModel";
import axios from "axios";

/**
 * 모든 제품 리스트
 */
export async function reqProductList(req?: IProductListReq): Promise<ProductListRes> {

    let mainProductRes: ProductListRes = new ProductListRes();

    await axiosAuth()
        .post('/api/product/list', req)
        .then(result => {
            mainProductRes = (result.data.data as ProductListRes);
        })
        .catch(error => mainProductRes = error);

    return mainProductRes
}

/**
 * 제품 카테고리 리스트
 *
 * @param req
 */
export async function reqProductCategoryList(req?: IProductCategoryListReq): Promise<ProductCategoryListRes> {

    let productCategoryListRes: ProductCategoryListRes = new ProductCategoryListRes();

    await axiosAuth()
        .post('/api/product-category/list', req)
        .then(result => {
            productCategoryListRes = (result.data.data as ProductCategoryListRes);
        })
        .catch(error => productCategoryListRes = error);

    return productCategoryListRes;
}

/**
 * Gnb 카테고리
 */
export async function reqProductGnbCategoryList(): Promise<ProductGnbCategoryRes> {

    let productGnbCategoryListRes: ProductGnbCategoryRes = new ProductGnbCategoryRes();

    await axiosAuth()
        .post('/api/product-category/gnb-list')
        .then(result => {
            productGnbCategoryListRes = (result.data.data as ProductGnbCategoryRes);
        })
        .catch(error => productGnbCategoryListRes = error);

    return productGnbCategoryListRes;
}

/**
 * 제품 상세
 * @param productPk
 */
export async function reqProductShow(productPk: number): Promise<ProductShowRes> {

    let productShowRes: ProductShowRes = new ProductShowRes();

    await axiosAuth()
        .post('/api/product/show', {pk: productPk})
        .then(result => {
            productShowRes = (result.data as ProductShowRes);
        })
        .catch(error => productShowRes = error);

    return productShowRes;

}

/**
 * 북마크 등록
 * @param productPk
 */
export async function reqBookmarkProduct(productPk: number): Promise<BaseRes> {

    let res: BaseRes = new BaseRes();

    await axiosAuth()
        .post('/api/user/bookmark/edit', {
            productPk: productPk
        })
        .then(result => {
            res = (result.data as BaseRes)
        })
        .catch(error => res = error);

    return res;
}

/**
 * 사용자 관심카테고리
 */
export async function reqUserProductCategories(): Promise<ProductCategoryListRes> {

    let productCategoryListRes: ProductCategoryListRes = new ProductCategoryListRes();

    await axiosAuth()
        .post('/api/product-category/user-product/list')
        .then(result => {
            productCategoryListRes = (result.data.data as ProductCategoryListRes);
        })
        .catch(error => productCategoryListRes = error);
    
    return productCategoryListRes;
}
