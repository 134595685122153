















import Vue from "vue";
import Component from "vue-class-component";
import VueModal from "@/components/Modal/VueModal.vue";

@Component({
	components: {
		VueModal,
	},
})
export default class Index extends Vue {}
