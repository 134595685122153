










































import Vue from 'vue'
import Component from "vue-class-component";
import VueCard from "@/components/Card/VueCard.vue";
import VueButton from "@/components/Button/VueButton.vue";
import {State} from "vuex-class";
import UserModule from "@/store/modules/UserModule";
import {IProduct} from "@/store/models/Product/ProductModel";
import ProductModule from "@/store/modules/ProductModule";
import ProductDetail from "@/components/Product/Detail.vue";
import VueModal from "@/components/Modal/VueModal.vue";

@Component({
  components: { VueCard, VueButton, VueModal, ProductDetail }
})
export default class VueBookmark extends Vue {

    @State(state => state.user.userBookmarks)
    userBookmarks!: IProduct[];

    @State(state => state.product.productShow)
    productShow!: IProduct | null;

    @State(state => state.lang.locale)
    locale!: string;

    activePage: number = 1;

    toggleModal: boolean = false;

    activeProductItem: IProduct | null = null;

    get getProductName() {
        return this.activeProductItem?.productCategoryName;
    }

    created() {
        UserModule.actionBookmarkList();
    }

    openModal(item: IProduct) {
        ProductModule.actionProductShow(item.pk)
            .then(() => {
                this.activeProductItem = item;
                this.toggleModal = true;
                ProductModule.actionProductShowStart(item.pk);
            })
    }

    closeModal() {
        this.toggleModal = false;

        if (this.productShow?.pk) {
            ProductModule.actionProductShowEnd(this.productShow.pk);
        }
    }

    slideChange(handler: string) {
        if (handler === 'left') {
            ++this.activePage;
        } else {
            --this.activePage;
        }
    }
}
