import {BaseRes} from "@/store/models/BaseModel";

export enum MENU_NAME {
    HOME = 'HOME',
    PRODUCT = 'PRODUCT',
    MEETING = 'MEETING',
    CONFERENCE = 'CONFERENCE',
    NEWS = 'NEWS',
    QNA = 'QNA',
}

export const convertToMenuName = {
    'HOME': 'HOME',
    'LANG': 'HOME',
    'PRODUCT': 'PRODUCT',
    'MEETING': 'MEETING',
    'EXHIBITION': 'CONFERENCE',
    'MEDIACENTER': 'NEWS',
    'QNA': 'QNA'
}

export interface IStatisticsProduct {
    pk: number;
}

export interface IStatisticsMenu {
    type: MENU_NAME;
}

export class StatisticsRes extends BaseRes {

    data: object = {};
    errors: object = {};

    constructor() {
        super();
    }
}
