import {BaseRes} from "@/store/models/BaseModel";
import {IParams} from "@/store/models/Form/FormModel";

export function getMeetingStatus() {

}

export enum MainMeetingStatus {
    REQUEST = 'apply',
    REQ_MANAGER = 'REQ_MANAGER',
    RESERVATION = 'plan',
    COMPLETE = 'complete',
    CANCEL = 'cancel',
}

export interface IMainMeeting {
    pk: number;
    description?: string;
    cancelReason?: string;
    statusText: string;
    meetingAt: string;
    title: string;
}

export interface IMeetingProductCategory {
    pk: number; // 기본키
    name: string; // 카테고리 이름
}

export interface IMeetingProduct {
    pk: number; // 기본키
    name: string; // 제품명
}

export interface IMeetingProductCategoryReq {
    siteLang: string; // 사이트 언어
    parentCode: number; // 상위코드
}

export interface IMeetingState {
    mainMeetings: IMainMeeting[];
}

export interface IMeetingList {
    reserveList: IMeeting[];
    requestList: IMeeting[];
    completeList: IMeeting[];
    cancelList: IMeeting[];
}

export interface IMeeting {
    pk: number;
    title: string;
    meetingAt: string;
    description?: string;
    requestDescription?: string;
    cancelReason?: string;
    changeReason?: string;
    status?: string;
    result?: string;
    lastUpdator?: string;
    meetingUsers?: IMeetingUser[];
    manager: IMeetingManager;
    cancelAt?: string; // 취소일자
    completeAt?: string; // 완료일자
    url: string;
}

export interface IMeetingEdit {
    pk?: number;
    title: string;
    reason: string;
    changeReason?: string;
    changeReasonManager?: string;
    productCategoryPk1: number;
    productCategoryPk2: number;
    productPk?: number;
    siteLang: string;
    requestAnywhen: boolean;
    status?: string;
    requestMeetingAt: string | null;
    requestPlatform: string;
    requesterJoin: boolean;
    requestDescription: string;
    requestTimeZone?: string | null;
    lastUpdator?: string;
    meetingUsers: IMeetingUser[];
    meetingAt?: string | null;
    platforms?: { name: string, value: string }[];
    manager?: IMeetingManager;
}

export interface IMeetingUser {
    firstName: string;
    lastName: string;
    position: string;
    email: string;
}

export interface IMeetingManager {
    firstName: string;
    lastName: string;
    department: string;
    position: string;
    email: string;
}

export interface IMeetingEditReqValidate {
    title: string;
    business?: string;
    requestPlatform: string;
    meetingUsers: string;
    requestDescription: string;
    reason?: string;
    changeReason?: string;
}

export interface IMeetingCancelReq {
    pk: number;
    reason: string;
}

export interface IMeetingConfirm {
    pk: number;
}

export class MainMeetingListRes extends BaseRes {

    data!: IMainMeeting[];

    constructor() {
        super();
    }
}

export class MeetingListRes extends BaseRes {

    data!: IMeetingList;

    constructor() {
        super();
    }
}

export class MeetingProductCategoryRes extends BaseRes implements Iterable<IMeetingProductCategory> {

    data!: IMeetingProductCategory[];

    [Symbol.iterator]() {
        let nextIndex = 0;

        return {
            next: () => {
                return {
                    value: this.data[nextIndex++],
                    done: nextIndex > this.data.length
                }
            }
        }
    }

    constructor() {
        super();
    }
}

export class MeetingProductRes extends BaseRes implements Iterable<IMeetingProduct> {

    data: IMeetingProduct[] = [];

    [Symbol.iterator](): Iterator<IMeetingProduct> {
        let nextIndex = 0;

        return {
            next: () => {
                return {
                    value: this.data[nextIndex++],
                    done: nextIndex > this.data.length
                }
            }
        }
    }

    constructor() {
        super();
    }
}

export class MeetingRes extends BaseRes {

    data!: IMeeting;

    constructor() {
        super();
    }
}

export class MeetingEditDataRes extends BaseRes {
    data!: IMeetingEdit;

    constructor() {
        super();
    }
}

export class MeetingDetail implements IMeeting {
    manager: IMeetingManager = {
        firstName: '',
        lastName: '',
        department: '',
        position: '',
        email: ''
    };
    meetingAt: string = '';
    pk: number = 0;
    title: string = '';
    url: string = '';
}

export class MeetingEdit implements IMeetingEdit {
    pk: number = 0;
    title: string = '';
    productCategoryPk1: number = 0;
    productCategoryPk2: number = 0;
    reason: string = '';
    requestAnywhen: boolean = true;
    requestDescription: string = '';
    requestMeetingAt: string | null = null;
    meetingAt: string | null = null;
    requestPlatform: string = '';
    requesterJoin: boolean = true;
    manager: IMeetingManager = {
        firstName: '',
        lastName: '',
        department: '',
        position: '',
        email: ''
    };
    meetingUsers: IMeetingUser[] = [];
    platforms?: { name: string, value: string }[]
    siteLang: string = '';
    changeReason: string = '';
    changeReasonManager: string = '';
}

export class MeetingConfirmRes extends BaseRes {

    data: IMeetingConfirm = {
        pk: 0
    }

    constructor() {
        super();
    }
}
