

































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import { ObjType } from "@/store/models/Form/FormModel";
import { IVsrCaptionItem } from "@/store/models/Vsr/VsrModel";
import VsrModule from "@/store/modules/VsrModule";

@Component
export default class VideoPlayer extends Vue {
  isPlaying: boolean = false;
  isMute: boolean = false;
  prevVolume: number = 0;
  caption = "";
  captionData?: { [lang: string]: IVsrCaptionItem[] };
  videoSrc?: string = "";

  @Prop({ default: "" }) src?: string;
  @Prop({ default: "" }) poster?: string;
  @Prop({ default: false }) muted?: string;
  @Prop({ default: false }) controls?: boolean;
  @Prop({ default: false }) loop?: boolean;
  @Prop({ default: true }) canMute?: boolean;
  @Prop({ default: true }) canSkip?: boolean;
  @Prop({ default: false }) useBackgroundSound?: boolean;

  @State(state => state.lang.locale) locale!: string;
  @State(state => state.lang.localeToArray) localeToArray!: ObjType;

  @State(state => state.vsr.muteBgSound) muteBgSound!: boolean;
  @State(state => state.vsr.useBgSound) useBgSound!: boolean;

  get video() {
    return this.$refs.video as any;
  }

  mounted() {
    this.videoSrc = this.src;

    if (this.muteBgSound && this.useBackgroundSound) {
      this.isMute = true;
    } else {
      this.isMute = false;
    }
  }

  @Watch("muteBgSound")
  changeMuteBgSound() {
    if (this.useBackgroundSound) {
      this.isMute = VsrModule.muteBgSound;
    }
  }

  onPlaying() {
    this.isPlaying = true;
  }

  onPause() {
    this.isPlaying = false;
  }

  onLoadedmetadata() {
    this.$emit("onLoadedmetadata");
  }

  onEnded() {
    this.caption = "";
    this.$emit("ended");
  }

  onTimeUpdate() {
    if (this.captionData == null) {
      return;
    }

    const currentTime = this.video.currentTime;
    let caption = "";
    for (const item of this.captionData[this.locale]) {
      if (item.start < currentTime) {
        caption = item.text;
      }
    }

    this.caption = caption;
  }

  play() {
    this.video.play();
  }

  pause() {
    this.video.pause();
  }

  stop() {
    this.video.currentTime = 0;
    this.video.pause();
  }

  setMute(isMute: boolean) {
    this.isMute = isMute;
    if (isMute) {
      this.prevVolume = this.video.volume;
    }

    this.video.volume = isMute ? 0 : this.prevVolume;

    if (this.useBackgroundSound) {
      VsrModule.setMuteBgSound(isMute);
    }
  }

  refresh() {
    this.video.currentTime = 0;
    this.video.play();
  }

  load(src: string, captionData: { [lang: string]: IVsrCaptionItem[] }) {
    this.videoSrc = src;
    this.captionData = captionData;
    this.video.load();
  }

  skip() {
    this.$emit("skip");
  }
}
