











































































import Vue from "vue";
import {Component} from "vue-property-decorator";
import Layout from "@/layouts/index.vue";
import VueTitle from "@/components/Title/VueTitle.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueCard from "@/components/Card/VueCard.vue";
import VueListTab from "@/components/Tab/VueListTab.vue";
import MeetingDetailModal from "@/components/Meeting/MeetingDetailModal.vue";
import MeetingModifyModal from "@/components/Meeting/MeetingModifyModal.vue";
import MeetingCancelModal from "@/components/Meeting/MeetingCancelModal.vue";
import MeetingModule from "@/store/modules/MeetingModule";
import { State } from "vuex-class";
import {IMeetingList, IMeetingManager, MainMeetingStatus} from "@/store/models/Meeting/MeetingModel";
import { Moment } from "moment";
import Lang from "@/store/modules/LangModule";
import { EnumLang } from "@/store/models/Lang/LangModel";
import { ICommonCode } from "@/store/models/CommonModel";
import {isMacOs} from 'mobile-device-detect';

interface IMeetingView {
    pk: number;
    dDay: string;
    tag: string;
    title: string;
    meetingAt: string;
    date: string;
    time: string;
    manager: IMeetingManager;
    url: string;
    buttonType: string[] | string;
}

@Component({
	components: {
		Layout,
		VueTitle,
		VueSelect,
		VueButton,
		VueCard,
		VueListTab,
		MeetingDetailModal,
		MeetingModifyModal,
		MeetingCancelModal,
	},
    metaInfo() {
        return {
            title: `Meetings | MOBIS Business Connect.`
        };
    },
})
export default class Meeting extends Vue {
	@State((state) => state.meeting.meetingList) meetingList!: IMeetingList;
    @State(state => state.lang.locale) locale!: string;

	/**
	 * mac os show
	 */
	isMacOsShow: boolean = false;

	//tab active
	activeIndex: number = 0;

    activeItem: any = {};

	lang = EnumLang;

	//tab list
	tabList: ICommonCode[] = [
		{
			name: "meeting_page.tabs.all",
			pk: 0,
		},
		{
			name: "meeting_page.tabs.schedule",
			pk: 1,
		},
		{
			name: "meeting_page.tabs.application",
			pk: 2,
		},
		{
			name: "meeting_page.tabs.complete",
			pk: 3,
		},
		{
			name: "meeting_page.tabs.cancel",
			pk: 4,
		},
	];

	//detail modal
	meetingDetailModal: boolean = false;

	//modify modal
	meetingModifyModal: boolean = false;

	//cancel modal
	meetingCancelModal: boolean = false;

    meetingStatus: string = '';

	get meetingData() {
		let list: IMeetingView[] = [];
		let reserveList: IMeetingView[] = [];
		let requestList: IMeetingView[] = [];
		let completeList: IMeetingView[] = [];
		let cancelList: IMeetingView[] = [];

		// 예정
		for (const item of this.meetingList.reserveList) {
			let date: Moment = this.$moment(item.meetingAt);
			let dDayCount: number = date.diff(this.$moment(), "days");
			let dDay: string = "D-" + dDayCount.toString();
			let buttonType: string[] = ["meeting-cancel"];

            if (dDayCount >= 8) {
                buttonType.push("change");
            }

			if (dDayCount < 1) {
				dDay = "D-DAY";
				buttonType = ["join"];
			}

			let tag: string = MainMeetingStatus.RESERVATION;

			reserveList.push({
                pk: item.pk,
				dDay: dDay,
				tag: tag,
				title: item.title,
				meetingAt: item.meetingAt,
				date: date.format("MMM D, YYYY"),
				time: date.format("HH:mm"),
                manager: item.manager,
                url: item.url,
				buttonType: buttonType,
			});
		}

		// 신청
		for (const item of this.meetingList.requestList) {
			let date: Moment = this.$moment(item.meetingAt);
			let dDay: string = '';
			let buttonType: string[] = ["change", "meeting-cancel"];
			let tag: string = MainMeetingStatus.REQUEST;

            // 담당자 지정상태는 approval이 아니다
            if (item.status !== MainMeetingStatus.REQ_MANAGER
                && item.lastUpdator == 'ADMIN' && item.meetingAt) {
                buttonType.push('approval');
            }

			requestList.push({
                pk: item.pk,
				dDay: dDay,
				tag: tag,
				title: item.title,
				meetingAt: (item.status !== MainMeetingStatus.REQ_MANAGER && item.lastUpdator == "ADMIN")
                    ? item.meetingAt
                    : '',
				date: date.format("MMM D, YYYY"),
				time: date.format("HH:mm"),
                manager: item.manager,
                url: item.url,
				buttonType: buttonType,
			});
		}

		// 완료
		for (const item of this.meetingList.completeList) {
			let date: Moment = this.$moment(item.meetingAt);
			let dDay: string = '';
			let buttonType: string[] = ["content-check"];
			let tag: string = MainMeetingStatus.COMPLETE;

			completeList.push({
                pk: item.pk,
				dDay: dDay,
				tag: tag,
				title: item.title,
				meetingAt: item.meetingAt,
				date: date.format("MMM D, YYYY"),
				time: date.format("HH:mm"),
                manager: item.manager,
                url: item.url,
				buttonType: buttonType,
			});
		}

		// 취소
		for (const item of this.meetingList.cancelList) {
			let date: Moment = this.$moment(item.meetingAt);
			let dDay: string = '';
			let buttonType: string[] = ["content-check"];
			let tag: string = MainMeetingStatus.CANCEL;

			cancelList.push({
                pk: item.pk,
				dDay: dDay,
				tag: tag,
				title: item.title,
				meetingAt: item.meetingAt,
				date: date.format("MMM D, YYYY"),
				time: date.format("HH:mm"),
                manager: item.manager,
                url: item.url,
				buttonType: buttonType,
			});
		}

		if (this.activeIndex === 1) {
			list.push(...reserveList);
		} else if (this.activeIndex === 2) {
			list.push(...requestList);
		} else if (this.activeIndex === 3) {
			list.push(...completeList);
		} else if (this.activeIndex === 4) {
			list.push(...cancelList);
		} else {
			list.push(
				...reserveList,
				...requestList,
				...completeList,
				...cancelList
			);
			list.sort((a, b) => {
				return this.$moment(b.meetingAt).diff(
					this.$moment(a.meetingAt)
				);
			});
		}

		return list;
	}

    openModal(payload: {type: string, value: IMeetingView}) {
        if (payload.type === 'join') {
            // 미팅 참가
            if (confirm(this.$t('meeting_page.modify.alert.go_meeting').toString())) {
                window.open(payload.value.url)
            }
        } else if (payload.type == 'change') {
            MeetingModule.actionMeetingEditData(payload.value.pk)
                .then(() => {
                    this.meetingStatus = payload.value.tag
                    this.meetingModifyModal = true
                })
        } else if (payload.type == 'approval') {
            this.approval(payload.value.pk)
        } else {
            MeetingModule.actionMeetingDetail(payload.value.pk)
                .then(() => {
                    this.meetingStatus = payload.value.tag;

                    if (payload.type == 'check') {
                        this.meetingDetailModal = true
                    } else if (payload.type == 'cancel') {
                        this.meetingCancelModal = true
                    }
                })
        }
    }

    async approval(pk: number) {
        if (confirm(this.$t('meeting_page.modify.alert.schedule_approval').toString())) {
            await MeetingModule.actionMeetingUserConfirm(pk)
                .then((res) => {
                    if (res.data.pk) {
                        alert(this.$t('meeting_page.modify.alert.completed_approval').toString());
                        MeetingModule.actionMeetingList();
                    } else {
                        alert(this.$t('meeting_page.modify.alert.fail.approval').toString());
                        console.log("meeting 승인 api 에러");
                    }
                })
        }
    }

	localeToCode() {
		return Lang.getLocaleToCode;
	}

	/**
	 * modal toggle
	 */
	toggleModal(name: string) {
		if (name === "meetingDetail") {
			this.meetingDetailModal = !this.meetingDetailModal;
		} else if (name === "meetingModify") {
			this.meetingModifyModal = !this.meetingModifyModal;
		} else {
			this.meetingCancelModal = !this.meetingCancelModal;
		}
	}

	created() {
		MeetingModule.actionMeetingList();
	}

	mounted() {
		if(isMacOs) {
			this.isMacOsShow = true;
		}
	}
}
