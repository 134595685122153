














import Vue from 'vue';
import { Component } from "vue-property-decorator";
import VueTitle from "@/components/Title/VueTitle.vue";

@Component({
  components: { VueTitle }
})
export default class VueInformationTitle extends Vue {



}
