






import Vue from "vue";
import Component from "vue-class-component";
import Auth from "@/store/modules/AuthModule";
import {State} from "vuex-class";
import LangModule from "@/store/modules/LangModule";
import { EnumLang } from "@/store/models/Lang/LangModel";

@Component
export default class LayoutFooter extends Vue {
    @State(state => state.lang.locale) locale!: string;

    LangModule = LangModule
    EnumLang = EnumLang

    get isLogin() {
		return Auth.isLogin;
	}

    goLink() {
        window.open("https://beian.miit.gov.cn/#/Integrated/index");
    }
}
