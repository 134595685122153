import {MainKeyvisualListRes} from "@/store/models/Keyvisual/KeyvisualModel";
import {axiosAuth} from "@/store/api/BaseApi";
import LangModule from "@/store/modules/LangModule";
import {BaseRes} from "@/store/models/BaseModel";

/**
 * 메인 키 비주얼 리스트 (제일 큰 배너)
 */
export async function reqMainKeyVisualList(): Promise<MainKeyvisualListRes> {

    let mainKeyVisualList: MainKeyvisualListRes = new MainKeyvisualListRes();

    await axiosAuth()
        .post('/api/keyVisual/mainsite/list', {
            siteLang: LangModule.getLocaleToLang
        })
        .then(result => {
            mainKeyVisualList = (result.data.data as MainKeyvisualListRes);
        })
        .catch(error => mainKeyVisualList = error)

    return mainKeyVisualList;
}

/**
 * 키비주얼(메인배너) 조회수 증가
 * @param keyvisualPk
 */
export async function reqKeyvisualUpCount(keyvisualPk: number): Promise<BaseRes> {

    let res: BaseRes = new BaseRes();

    await axiosAuth()
        .post('/api/keyVisual/mainsite/upcount', {
            pk: keyvisualPk
        })
        .then(result => {
            res = (result.data as BaseRes);
        })
        .catch(error => res = error);

    return res;
}
