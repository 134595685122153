












































































import Vue from 'vue';
import Component from "vue-class-component";
import Layout from "@/layouts/index.vue";
import VueTitle from "@/components/Title/VueTitle.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueCard from "@/components/Card/VueCard.vue";
import VueListTab from "@/components/Tab/VueListTab.vue";
import VueModal from "@/components/Modal/VueModal.vue";
import ProductDetail from "@/components/Product/Detail.vue";
import {isMacOs} from 'mobile-device-detect';
import {IOption} from "@/store/models/Form/FormModel";
import {Watch} from "vue-property-decorator";
import {IProduct, IProductCategory} from "@/store/models/Product/ProductModel";
import ProductModule from "@/store/modules/ProductModule";
import {State} from "vuex-class";
import {IProductParentCategory} from "@/store/models/Gnb/GnbModel";
import LoginModalModule from "@/store/modules/LoginModalModule";

@Component({
    components: {Layout, VueTitle, VueSelect, VueButton, VueCard, VueListTab, VueModal, ProductDetail},
    metaInfo() {
        return {
            // @ts-ignore
            title: `Product - ${this.getProductName} | MOBIS Business Connect.`
        };
    },
})
export default class Product extends Vue {

    @State(state => state.product.productCategories)
    productCategories!: IProductCategory[];

    @State(state => state.product.products)
    products!: IProduct[];

    @State(state => state.gnb.productParentCategory)
    productParentCategory!: IProductParentCategory[];

    @State(state => state.lang.locale)
    locale!: string;

    @State(state => state.product.productShow)
    productShow!: IProduct | null;

    /**
     * mac os show
     */
    isMacOsShow: boolean = false;

    /**
     * req용 카테고리 코드
     */
    commonCodePk: number | undefined;

    /**
     * req용 orderby
     */
    orderBy: string = "RECENT";

    /**
     * tab active
     */
    activeIndex: number = 0;

    /**
     * 모달 핸들러
     */
    toggleModal: boolean = false;

    /**
     * 활성 탭 오브젝트
     */
    activeItem: IProductCategory = {
        name: "",
        pk: 0,
        parentPk: 0,
    };

    selectList: IOption[] = [
        {
            text: "common.form.order_select.view",
            value: "VIEWS",
            disabled: false,
        },
        {
            text: "common.form.order_select.alpha",
            value: "ALPHABET",
            disabled: false,
        },
        {
            text: "common.form.order_select.new",
            value: "RECENT",
            disabled: false,
        },
    ];

    get getProductName() {
        return this.productParentCategory.find(item =>
            item.pk === Number(this.$route.query.parentPk)
        )?.name;
    }

    get getProductDescription() {
        return this.productParentCategory.find(item =>
            item.pk === Number(this.$route.query.parentPk)
        )?.description;
    }

    get getProductCategories() {
        let categories: IProductCategory[] = [{
            name: this.$t("common.categories_all").toString(),
            pk: 0,
            parentPk: Number(this.$route.query.parentPk),
        }];

        this.productCategories.map(item => {
            categories.push(item);
        })

        return categories;
    }

    created() {
        ProductModule.actionProductCategoryList(
            Number(this.$route.query.parentPk)
        );

        this.activeItem = {
            name: this.$route.query.name?.toString(),
            pk: Number(this.$route.query.productCategoryPk),
            parentPk: Number(this.$route.query.parentPk),
        }
    }

    mounted() {
        if (isMacOs) {
            this.isMacOsShow = true;
        }
    }

    @Watch("orderBy")
    @Watch("activeItem", {deep: true})
    watchMediaCenterNewsList() {
        ProductModule.actionProductList({
            productCategoryPkList: this.activeItem.pk > 0 ? [Number(this.activeItem.pk)] : [],
            productCategoryParentPkList: [Number(this.activeItem.parentPk)],
            sortType: this.orderBy
        });
    }

    @Watch('locale')
    watchLocaleChange() {
        ProductModule.actionProductCategoryList(
            this.$route.query?.parentPk
                ? Number(this.$route.query.parentPk)
                : 0
        );

        ProductModule.actionProductList({
            productCategoryPkList: this.activeItem.pk > 0 ? [Number(this.activeItem.pk)] : [],
            productCategoryParentPkList: [Number(this.activeItem.parentPk)]
        });
    }

    openModal(item: IProduct) {
        ProductModule.actionProductShow(item.pk)
            .then((res) => {
                console.log('product detail :: ', res);
                if (res.data) {
                    console.log('product Open :: ', res);
                    ProductModule.actionProductShowStart(item.pk);
                    this.toggleModal = true;
                }

                if (res.status === 423 || res.status === 401) {
                    LoginModalModule.show(() => {
                        ProductModule.actionProductShow(item.pk)
                            .then((res) => {
                                if (res.data) {
                                    ProductModule.actionProductShowStart(item.pk);
                                    this.toggleModal = true;
                                }
                            })
                    });
                }
            })
    }

    closeModal() {
        this.toggleModal = false;

        if (this.productShow?.pk) {
            ProductModule.actionProductShowEnd(this.productShow.pk);
        }
    }

}
