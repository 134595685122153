import Vue from "vue";
import {deviceDetect} from "mobile-device-detect";
import DeviceDetect from "mobile-device-detect";

export interface IMobileDeviceDetect {
    isBrowser: boolean;
    isMobile: boolean;
    isTablet: boolean;
    isSmartTV: boolean;
    isConsole: boolean;
    isWearable: boolean;
    isMobileSafari: boolean;
    isChromium: boolean;
    isMobileOnly: boolean;
    isAndroid: boolean;
    isWinPhone: boolean;
    isIOS: boolean;
    isChrome: boolean;
    isFirefox: boolean;
    isSafari: boolean;
    isOpera: boolean;
    isIE: boolean;
    isEdge: boolean;
    isYandex: boolean;
    isIOS13: boolean;
    isIPad13: boolean;
    isIPhone13: boolean;
    isIPod13: boolean;
    isElectron: boolean;
    isEdgeChromium: boolean;
    isLegacyEdge: boolean;
    isWindows: boolean;
    isMacOs: boolean;
    osVersion: string;
    osName: string;
    fullBrowserVersion: string;
    browserVersion: string;
    browserName: string;
    mobileVendor: string;
    mobileModel: string;
    engineName: string;
    engineVersion: string;
    getUA: string;
    deviceType: string;
    deviceDetect: Function;
}

const mobileDeviceDetect = {
    install: (vue: typeof Vue, options?: any) => {
        vue.mixin({
            created() {
                vue.prototype.$mdevice = { ...DeviceDetect };
            }
        })
    }
}

export default mobileDeviceDetect;
