import {BaseRes} from "@/store/models/BaseModel";
import {IUserInfo} from "@/store/models/Auth/AuthModel";
import { IMeetingUser } from "@/store/models/Meeting/MeetingModel";
import {i18n} from "@/libs/i18n"

export enum NotificationTypes {

    //알림 메세지 종류 (WAIT_APPROVAL: 승인 대기, CHANGE_MANAGER_SCHEDULE: 관리자 일정 변경 CHANGE_USER_SCHEDULE: 사용자 일정 변경 , APPROVED: 승인 완료, MEETING_COMPLETE: 미팅 완료, MEETING_CANCEL: 미팅 취소,
    // DAY_BEFORE_MEETING: 미팅 하루 전, ONE_HOUR_BEFORE_MEETING: 미팅 1시간 전, QNA_RECEIPT: 문의 접수, QNA_ANSWER: 문의 답변)

    WAIT_APPROVAL = 'WAIT_APPROVAL',
    CHANGE_MANAGER_SCHEDULE = 'CHANGE_MANAGER_SCHEDULE',
    CHANGE_USER_SCHEDULE = 'CHANGE_USER_SCHEDULE',
    APPROVED = 'APPROVED',
    MEETING_COMPLETE = 'MEETING_COMPLETE',
    MEETING_CANCEL = 'MEETING_CANCEL',
    DAY_BEFORE_MEETING = 'DAY_BEFORE_MEETING',
    ONE_HOUR_BEFORE_MEETING = 'ONE_HOUR_BEFORE_MEETING',
    QNA_RECEIPT = 'QNA_RECEIPT',
    QNA_ANSWERED = 'QNA_ANSWERED',
    MEETING_CANCEL_USER = 'MEETING_CANCEL_USER',

    /*WAIT_APPROVAL = '승인 대기',
    CHANGE_MANAGER_SCHEDULE = '관리자 일정 변경',
    CHANGE_USER_SCHEDULE = '사용자 일정 변경',
    APPROVED = '승인 완료',
    MEETING_COMPLETE = '미팅 완료',
    MEETING_CANCEL = '미팅 취소',
    DAY_BEFORE_MEETING = '미팅 하루 전',
    ONE_HOUR_BEFORE_MEETING = '미팅 1시간 전',
    QNA_RECEIPT = '문의 접수',
    QNA_ANSWER = '문의 답변',*/
}

export const NotificationMessages = {
    'WAIT_APPROVAL': "common.alarm.wait_approval",
    'CHANGE_MANAGER_SCHEDULE': "common.alarm.change_manager",
    'CHANGE_USER_SCHEDULE': "common.alarm.change_user",
    'APPROVED': "common.alarm.approved",
    'MEETING_COMPLETE': "common.alarm.meeting_complete",
    'MEETING_CANCEL': "common.alarm.meeting_cancel",
    'MEETING_CANCEL_USER': "common.alarm.meeting_cancel_user",
    'DAY_BEFORE_MEETING': "common.alarm.day_before",
    'ONE_HOUR_BEFORE_MEETING': "common.alarm.one_hour_before",
    'QNA_RECEIPT': "common.alarm.qna_receipt",
    'QNA_ANSWERED': "common.alarm.qna_answered",
}

export interface INotificationActionUser {
    firstName: string; // 액션 주체자 성
    lastName: string; // 액션 주체자 이름
    partnerLocal: string; // 협력사 로컬이름
    partnerGlobal: string; // 협력사 영문이름
    siteLang: string; // 액션 주체자 언어
}

export interface INotificationMeetingData {
    type: NotificationTypes; // 알림 메시지 종류
    meetingPk: number; // 미팅 기본키
    startAt?: string; // 미팅 시작 일시 - 승인 완료된 미팅인 경우
    managerPk?: number;
}

export interface INotificationQnaData {
    type: NotificationTypes; // 알림 메시지 종류
    read: boolean; // 읽음 여부
    qnaPk: number; // QNA 기본키
    qnaAnswerPk?: number; // QNA 답변 기본키 - 답변 완료인 경우
    managerPk?: number;
}

export interface INotificationManager {
    pk: number;
    firstName: string;
    lastName: string;
}

export function isMeetingData(object: any): object is INotificationMeetingData {
    return 'startAt' in object;
}

export interface INotificationItem {
    pk: number;
    read: boolean;
    createAt: string;
    notificationData: INotificationQnaData | INotificationMeetingData;
}

export interface INotification {
    managers: INotificationManager[];
    notifications: INotificationItem[];
}

export interface INotificationReadReq {
    notificationPks: number[];
}

export interface INotificationState {
    notification: INotification;
}

export class NotificationListRes extends BaseRes {

    data!: INotification

    constructor() {
        super();
    }
}

export class INotificationMeeting {
}

export class INotificationQna {
}
