import { render, staticRenderFns } from "./VueButton.vue?vue&type=template&id=66c90075&scoped=true&"
import script from "./VueButton.vue?vue&type=script&lang=ts&"
export * from "./VueButton.vue?vue&type=script&lang=ts&"
import style0 from "./VueButton.vue?vue&type=style&index=0&id=66c90075&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c90075",
  null
  
)

export default component.exports