import {BaseRes, IAsset} from "@/store/models/BaseModel";

export enum IExhibitionViewTarget {
    ALL = 'All',
    NORMAL = 'Normal',
    VIP = 'VIP'
}

export interface IMainExhibition {
    pk: number;
    title: string;
    content: string;
    assetImgPc: IAsset;
    assetImgMob: IAsset;
    movUrl: string;
    viewTarget: IExhibitionViewTarget;
}

export interface IExhibition {
    pk: number; // 전시소식 기본키
    commonCodePk: number; // 카테고리 코드
    commonCodeText: string; // 카테고리 코드 이름
    title: string; // 전시소식 타이틀
    content: string; // 전시소식 내용
    assetImgPc: IAsset; // 이미지 PC
    assetImgMob: IAsset; // 이미지 Mobile
    movUrl: string; // 영상 URL
    ViewCnt: number; // 조회수
    viewTarget?: IExhibitionViewTarget;
}

export interface IExhibitionState {
    mainExhibitionList: IMainExhibition[];
    exhibitionList: IExhibition[];
}

export interface IExhibitionReq {
    pageNo: number; // 페이지 번호
    rowPerPage: number; // 페이지당 로우 수
    commonCodePk: number; // 카테고리 코드 (전체: 0, 그 외 공통코드 PK)
    siteLang: string; // 사이트 언어
    orderBy: string; // 정렬 (new: 최신, view: 조회순)
}

export class MainExhibitionListRes extends BaseRes {
    items!: IMainExhibition[];

    constructor() {
        super();
    }
}

export class ExhibitionListRes extends BaseRes {
    items!: IExhibition[];

    constructor() {
        super();
    }
}


