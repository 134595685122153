










































































































































































































































import Component from "vue-class-component";
import Layout from "@/layouts/index.vue";
import VueTitle from "@/components/Title/VueTitle.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueTag from "@/components/Tag/VueTag.vue";
import VueCard from "@/components/Card/VueCard.vue";
import VueListTab from "@/components/Tab/VueListTab.vue";
import VueModal from "@/components/Modal/VueModal.vue";
import ExhibitionModule from "@/store/modules/ExhibitionModule";
import {
    IExhibition,
    IMainExhibition,
} from "@/store/models/Exhibition/ExhibitionModel";
import {State} from "vuex-class";
import KeyvisualModule from "@/store/modules/KeyvisualModule";
import {IMainKeyvisual} from "@/store/models/Keyvisual/KeyvisualModel";
import MeetingModule from "@/store/modules/MeetingModule";
import {IMainMeeting} from "@/store/models/Meeting/MeetingModel";
import {IUserInfo} from "@/store/models/Auth/AuthModel";
import ProductModule from "@/store/modules/ProductModule";
import {ICommonCode} from "@/store/models/CommonModel";
import {IProduct, IProductCategory} from "@/store/models/Product/ProductModel";
import {Watch} from "vue-property-decorator";
import Lang from "@/store/modules/LangModule";
import {EnumLang} from "@/store/models/Lang/LangModel";
import ProductDetail from "@/components/Product/Detail.vue";
import {PopupMixin} from "@/components/Mixins/PopupMixin";
import AuthModule from "@/store/modules/AuthModule";
import LoginModalModule from "@/store/modules/LoginModalModule";

@Component({
    components: {
        Layout,
        VueTitle,
        VueButton,
        VueTag,
        VueCard,
        VueListTab,
        VueModal,
        ProductDetail
    },
    metaInfo() {
        return {
            title: 'MOBIS Business Connect.',
        };
    }
})
export default class Index extends PopupMixin {
    @State((state) => state.auth.userInfo)
    userInfo!: IUserInfo;

    @State((state) => state.exhibition.mainExhibitionList)
    mainExhibitionList!: IMainExhibition[];

    @State((state) => state.keyvisual.mainKeyvisuals)
    mainKeyvisualList!: IMainKeyvisual[];

    @State((state) => state.meeting.mainMeetings)
    mainMeetingList!: IMainMeeting[];

    @State((state) => state.product.mainProducts)
    mainProductList!: IProduct[];

    @State(state => state.lang.locale)
    locale!: string;

    @State(state => state.product.userProductCategories)
    userProductCategories!: IProductCategory[];

    @State(state => state.product.productShow)
    productShow!: IProduct | null;

    productCategoryPks: number[] = [];

    /**
     * 예정 된 일정 없음
     */
    meetingNone: boolean = false;


    /**
     * tab active
     */
    activeIndex: number = 0;

    activePage: number = 1;

    activeItem: ICommonCode = {
        name: "",
        pk: 0,
    };

    lang = EnumLang;

    /**
     * 모달 핸들러
     */
    exhibitionModal: boolean = false;
    productModal: boolean = false;

    /**
     * 상세보기 정보
     */
    activeExhibition: IExhibition = {
        pk: 0,
        commonCodePk: 0,
        commonCodeText: '',
        title: '',
        content: '',
        assetImgPc: {
            contentType: '',
            name: '',
            url: '',
            uuid: '',
        },
        assetImgMob: {
            contentType: '',
            name: '',
            url: '',
            uuid: '',
        },
        movUrl: '',
        ViewCnt: 0
    };

    /**
     * swiper option
     */
    swiperOption: any = {
        keyboard: {
            enabled: true,
        },
        slidesPerView: 1,
        spaceBetween: 15,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            721: {
                spaceBetween: 20,
            },
        },
    };

    /**
     * banner swiper option
     */
    bannerSwiperOption: object = {
        keyboard: {
            enabled: true,
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 15,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            721: {
                spaceBetween: 0,
            },
        },
    };

    selectProductPk = 0;

    get getUserProducts() {
        let codes = [
            {
                name: this.$t("common.categories_all").toString(),
                pk: 0,
            },
        ];

        this.userProductCategories.map((item) => {
            codes.push(item);
        });

        return codes;
    }

    /**
     * 메인 미팅이 2줄씩 들어가야 해서 재가공
     */
    get realMainMeetingList(): { first?: IMainMeeting; second?: IMainMeeting }[] {
        let list: { first?: IMainMeeting; second?: IMainMeeting }[] = [];

        for (let i = 0; i < this.mainMeetingList.length; i += 2) {
            let obj: {
                first?: IMainMeeting
                second?: IMainMeeting
            } = {
                first: this.mainMeetingList[i],
            };

            if (this.mainMeetingList.length >= i + 1) {
                obj.second = this.mainMeetingList[i + 1];
            }

            list.push(obj);
        }

        return list;
    }

    get getProductName() {
        return this.mainProductList.find(item =>
            item.pk === Number(this.selectProductPk)
        )?.productCategoryName;
    }

    get sortBookmarkProductList() {
        return this.mainProductList.sort((a, b) => {
            return Number(b.bookmark) - Number(a.bookmark);
        })
    }

    get isLogin() {
        return AuthModule.isLogin;
    }

    slideChange(handler: string) {
        if (handler === "left") {
            ++this.activePage;
        } else {
            --this.activePage;
        }
    }

    @Watch('locale')
    watchLocaleChange() {
        ExhibitionModule.actionMainExhibitionList();
        KeyvisualModule.actionMainKeyVisualList();

        if (this.isLogin) {
            ProductModule.actionUserProductCategoryList();
            MeetingModule.actionMainMeetingList();
        } else {
            ProductModule.actionProductCategoryList();
        }

        ProductModule.actionMainProductList(
            this.activeItem.pk > 0
                ? [this.activeItem.pk]
                : this.productCategoryPks
        )
    }

    @Watch("activeItem", {deep: true})
    watchProductList() {
        ProductModule.actionMainProductList(
            this.activeItem.pk > 0
                ? [this.activeItem.pk]
                : this.productCategoryPks
        );

        this.activePage = 1;
    }

    localeToCode() {
        return Lang.getLocaleToCode;
    }

    exhibitionModalActive(item: IExhibition) {
        ExhibitionModule.actionExhibitionUpCount(item.pk)
            .then(() => {
                this.activeExhibition = item;
                this.exhibitionModal = true;
            })
    }

    showKeyvisualMovie(item: IMainKeyvisual) {
        KeyvisualModule.actionKeyvisualUpCount(item.pk)
            .then(() => {
                this.activeExhibition.title = item.title;
                this.activeExhibition.content = item.content;
                this.activeExhibition.movUrl = item.movUrl;
                this.exhibitionModal = true;
            })
    }

    goDetail(item: IMainKeyvisual) {
        KeyvisualModule.actionKeyvisualUpCount(item.pk)
            .then(() => {
                let link = this.$device.mobile ? item.linkMobUrl : item.linkPcUrl;
                let target = this.$device.mobile ? item.linkMobTarget : item.linkPcTarget;

                if (target == 'self') {
                    location.href = link;
                } else {
                    window.open(link, '_blank');
                }
            })
    }

    productModalActive(item: IProduct) {
        ProductModule.actionProductShow(item.pk)
            .then((res) => {
                console.log(res);

                if (res.data) {
                    this.selectProductPk = item.pk
                    this.productModal = true;
                    ProductModule.actionProductShowStart(item.pk);
                }

                if (res.status === 423 || res.status === 401) {
                    LoginModalModule.show(() => {
                        ProductModule.actionProductShow(item.pk)
                            .then((res) => {
                                if (res.data) {
                                    this.selectProductPk = item.pk
                                    this.productModal = true;
                                    ProductModule.actionProductShowStart(item.pk);

                                    MeetingModule.actionMainMeetingList();

                                    this.userInfo.userProducts.map((item) => {
                                        this.productCategoryPks.push(item.pk);
                                    });

                                    ProductModule.actionUserProductCategoryList();
                                    ProductModule.actionMainProductList(this.productCategoryPks);
                                }
                            })
                    });
                }
            })
    }

    productModalClose() {
        this.productModal = !this.productModal;

        if (this.productShow?.pk) {
            ProductModule.actionProductShowEnd(this.productShow.pk);
        }
    }

    getTagText(statusText: string) {
        switch (statusText) {
            case "신청":
                return "apply";
            case "예약":
                return "plan";
            case "완료":
                return "complete";
            case "취소":
                return "cancel";
            default:
                return "plan";
        }
    }

    created() {
        ExhibitionModule.actionMainExhibitionList();
        KeyvisualModule.actionMainKeyVisualList();

        if (AuthModule.isLogin) {
            ProductModule.actionUserProductCategoryList();
            MeetingModule.actionMainMeetingList();

            this.userInfo.userProducts.map((item) => {
                this.productCategoryPks.push(item.pk);
            });
        } else {
            ProductModule.actionProductCategoryList();
        }

        ProductModule.actionMainProductList(this.productCategoryPks);
    }
}
