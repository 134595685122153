









































































import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import ImageCardListItem from "@/components/Card/ImageCardListItem.vue";
import ProductCardListItem from "@/components/Card/ProductCardListItem.vue";
import VueListTab from "@/components/Tab/VueListTab.vue";
import {IProduct} from "@/store/models/Product/ProductModel";
import {State} from "vuex-class";
import {Watch} from "vue-property-decorator";
import {IProductCategory} from "@/store/models/Product/ProductModel";
import {ICommonCode} from "@/store/models/CommonModel";
import {IUserInfo} from "@/store/models/Auth/AuthModel";
import ProductModule from "@/store/modules/ProductModule";
import {ISwiperElement} from "@/pages/MediaCenter.vue";
import AuthModule from "@/store/modules/AuthModule";

@Component({
    components: {
        ImageCardListItem,
        ProductCardListItem,
        VueListTab
    }
})
export default class ImageCardList extends Vue {
    @Prop({default: []}) items!: object[];
    // @Prop({ default: [] }) listTabItems!: object;
    @Prop({default: ""}) type!: string;
    @Prop({default: "simple"}) cardType!: string;
    @Prop({default: false}) bookmarkType!: boolean;


    @State(state => state.product.userProductCategories)
    userProductCategories!: IProductCategory[];

    @State(state => state.product.productCategories)
    productCategories!: IProductCategory[];

    @State((state) => state.auth.userInfo)
    userInfo!: IUserInfo;

    activePage: number = 1;

    activeItem: ICommonCode = {
        name: "",
        pk: 0,
    };

    productCategoryPks: number[] = [];

    @Watch('locale')
    watchLocaleChange() {
        ProductModule.actionMainProductList(
            this.activeItem.pk > 0
                ? [this.activeItem.pk]
                : this.productCategoryPks
        )
    }

    @Watch("activeItem", {deep: true})
    watchProductList() {
        ProductModule.actionMainProductList(
            this.activeItem.pk > 0
                ? [this.activeItem.pk]
                : this.productCategoryPks
        );

        this.activePage = 1;
    }

    /**
     * main swiper option
     */
    mainSwiperOption: any = {
        loop: false,
        keyboard: {
            enabled: true,
        },
        slidesPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 15,
        navigation: {
            nextEl: ".swiper-button-next.card",
            prevEl: ".swiper-button-prev.card",
        },
        breakpoints: {
            721: {
                spaceBetween: 20
            },
        },
    };

    mainItems: object[] = [
        {
            list: ''
        },
        {
            list: ''
        },
        {
            list: ''
        }
    ]

    get mainProducts() {
        let depths: any[] = [];
        let child: any[] = [];

        this.items.map((item, key) => {
            if (key > 0 && (key % 3) === 0) {
                depths.push(child);
                child = [];
            }

            child.push(item);

            if (key === this.items.length - 1) {
                depths.push(child);
            }
        });

        return depths;
    }

    $refs!: {
        mySwiper: ISwiperElement
    }

    get swiperHandler() {
        return this.$refs.mySwiper.$swiper;
    }

    get getUserProducts() {
        let codes = [
            {
                name: '#' + this.$t("common.categories_all").toString(),
                pk: 0,
            },
        ];

        let productCategories = AuthModule.isLogin
            ? this.userProductCategories : this.productCategories;

        productCategories.map((item) => {
            const product: IProductCategory = {
                pk: item.pk,
                name: '#' + item.name,
                description: item.description,
                parentPk: item.parentPk,
                child: item.child
            };

            codes.push(product);
        });

        return codes;
    }

    created() {

        this.userInfo.userProducts.map((item) => {
            this.productCategoryPks.push(item.pk);
        });

        ProductModule.actionMainProductList(this.productCategoryPks);
    }

    mounted() {
    }

    clickEvent(payload: IProduct) {
        this.$emit('click', payload);
    }

    slideChange(handler: string) {
        if (handler === 'left') {
            ++this.activePage;
        } else {
            --this.activePage;
        }
    }

    next() {
        if (this.$device.mobile) {
            this.activePage = this.swiperHandler.activeIndex + 1;
        } else {
            this.slideChange('left')
        }
    }

    prev() {
        if (this.$device.mobile) {
            this.activePage = this.swiperHandler.activeIndex + 1;
        } else {
            this.slideChange('right')
        }
    }
}
