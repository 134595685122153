import store from '@/store';
import {Module, VuexModule, Mutation, getModule, Action, MutationAction} from "vuex-module-decorators";

@Module({
    store,
    name: 'spinner',
    namespaced: true,
    dynamic: true
})
class SpinnerModule extends VuexModule {

    isActive: boolean = false;

    @Mutation
    changeActive(isActive: boolean) {
        this.isActive = isActive;
    }

    @Action
    open() {
        this.changeActive(true);
    }

    @Action
    close() {
        this.changeActive(false);
    }
}

export default getModule(SpinnerModule);
