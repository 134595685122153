import {CommonCodeRes} from "@/store/models/CommonModel";
import {axiosAuth} from "@/store/api/BaseApi";

/**
 * 공통코드 요청
 * @param parentPk
 */
export async function reqCommonCode(parentPk: number): Promise<CommonCodeRes> {

    let commonCodeRes = new CommonCodeRes();

    await axiosAuth()
        .post('/api/common-code/list', {parentPk: parentPk})
        .then(result => {
            commonCodeRes = (result.data.data as CommonCodeRes);
        })
        .catch(error => commonCodeRes = error);

    return commonCodeRes;
}
