






















































import Component from "vue-class-component";
import {FormMixin} from "@/components/Mixins/FormMixin";
import VueInput from "@/components/Form/VueInput.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import AuthModule from "@/store/modules/AuthModule";
import {ROUTE_NAME} from "@/router";
import {State} from "vuex-class";

@Component({
    components: {
        VueInput,
        VueButton,
        VueValidator,
    },
})
export default class VueResetPassword extends FormMixin {
    @State((state) => state.lang.locale) locale!: string;

    //input
    passwordText: string = "";
    passwordText2: string = "";
    passwordValidator: string = "";
    passwordValidator2: string = "";

    $refs!: {
        password: HTMLInputElement;
    };

    validate() {
        const passwordRule =
            /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*?])[A-Za-z\d!@#$%^&*?]{8,16}$/;

        let valid = true;

        if (this.passwordText.length < 1) {
            this.passwordValidator = 'validate.reset_password.password.required';
            valid = false;

            this.$refs.password.focus();
        }

        if (!passwordRule.test(this.passwordText)) {
            this.passwordValidator = 'validate.registry.password.not_correct';
            valid = false;

            this.$refs.password.focus();
        }

        return valid;
    }

    async submit() {
        if (this.validate()) {
            //TODO: 비밀번호 찾기 API 확인 후 수정
            await AuthModule.actionResetPassword({
                uuid: this.$route.query.uuid.toString(),
                password: this.passwordText,
                checkPassword: this.passwordText2
            })
                .then(res => {
                    console.log(res);
                    if (res.status) {
                        this.passwordValidator = res.error[0];
                    } else {
                        this.$router.push({
                            name: ROUTE_NAME.LOGIN,
                            params: {
                                lang: this.locale
                            }
                        })
                    }
                })
        }
    }
}
