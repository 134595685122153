




















import Vue from "vue";
import Component from "vue-class-component";
import { ROUTE_NAME } from "@/router";
import { State } from "vuex-class";
import VideoPlayer from "@/components/Vsr/VideoPlayer.vue";
import { VsrPageInfo } from "@/store/models/Vsr/VsrModel";

@Component({
  components: {
    VideoPlayer
  },
  metaInfo() {
    return {
      title: "MOBIS VIRTUAL SHOWROOM"
    };
  }
})
export default class VsrIndex extends Vue {
  ROUTE_NAME = ROUTE_NAME;
  @State(state => state.lang.locale) locale!: string;

  info = new VsrPageInfo("index");

  start() {
    this.$router.push({
      name: ROUTE_NAME.VSR_LOBBY,
      params: { lang: this.locale }
    });
  }
}
