


























import Vue from "vue";
import {Component} from "vue-property-decorator";
import {State} from "vuex-class";
import VueSelect from "@/components/Form/VueSelect.vue";
import LayoutFooter from "@/layouts/Footer.vue";
import VueSpinner from "@/components/Spinner/VueSpinner.vue";
import {IOption, ObjType} from "@/store/models/Form/FormModel";
import Lang from "@/store/modules/LangModule";
import axios from "axios";

require("@/store/modules/AuthModule");

@Component({
    components: {
        VueSelect,
        VueSpinner,
        LayoutFooter,
    },
})
export default class IntroLayout extends Vue {
    @State((state) => state.lang.originLocale) originLocale!: string;
    @State((state) => state.lang.locale) locale!: string;
    @State((state) => state.lang.localeToHtmlLang) localeToHtmlLang!: ObjType;
    @State((state) => state.lang.localeToArray) localeToArray!: ObjType;

    localeOption: IOption[] = [];

    changeLang(locale: string) {

        let url: string = "/" + locale;

        if (this.$route.name !== null && this.$route.name !== undefined) {
            if (
                this.$route.name.toString() !== "home" &&
                this.$route.name.toString() !== "lang"
            ) {
                url += "/" + this.$route.name.toString();
            }
        }

        Lang.changeLocale(locale);
        this.$i18n.locale = this.localeToHtmlLang[locale];
        axios.defaults.headers.common['Accept-Language'] = this.localeToHtmlLang[locale]
        document
            .querySelector("html")
            ?.setAttribute("lang", this.localeToHtmlLang[locale]);

        history.pushState(this.$route.params, "", url);
    }

    mounted() {
        for (const item of Object.keys(this.localeToArray)) {
            this.localeOption.push({
                text: this.localeToArray[item],
                value: item,
            });
        }
    }
}
