


































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import VsrModule from "@/store/modules/VsrModule";

@Component
export default class VideoPlayer extends Vue {
  isPlaying = false;
  isMobile = this.$mdevice.isMobile;
  fadeInterval: any = null;
  volume = "1.0";

  src1: string = require("@/assets/audio/vsr_bg1.mp3");
  src2: string = require("@/assets/audio/vsr_bg2.mp3");

  @State(state => state.vsr.useBgSound) useBgSound!: boolean;
  @State(state => state.vsr.bgSoundType) bgSoundType!: number;
  @State(state => state.vsr.muteBgSound) muteBgSound!: boolean;

  @Watch("$route.path")
  changeRoutePath() {
    VsrModule.setUseBgSound(true);
    VsrModule.setBgSoundType(0);
  }

  @Watch("bgSoundType")
  changeBgSoundType() {
    if (this.isMobile || this.muteBgSound) {
      if (this.useBgSound) {
        this.load();
      }
    } else {
      this.updateMute(true, 80);
      setTimeout(() => {
        this.load();
        this.updateMute(false);
      }, 1000);
    }
  }

  @Watch("muteBgSound")
  changeMuteBgSound(isMute: boolean) {
    if (this.isMobile) {
      if (isMute) {
        (this.$refs.bgAudio as any).pause();
      } else {
        (this.$refs.bgAudio as any).play();
      }
    } else {
      this.updateMute(isMute);
    }
  }

  updateMute(isMute: boolean, interval: number = 100) {
    clearInterval(this.fadeInterval);
    this.fadeInterval = setInterval(() => {
      const audio = this.$refs.bgAudio as any;
	  if(!audio) {
		  clearInterval(this.fadeInterval);
		  return
	  }

      let tempVolume = parseFloat(audio.volume);
      tempVolume = tempVolume + (isMute ? -0.1 : 0.1);
      tempVolume = tempVolume >= 1 ? 1 : tempVolume;
      tempVolume = tempVolume < 0 ? 0 : tempVolume;
      audio.volume = tempVolume.toFixed(2);
      if (tempVolume == 0 || tempVolume == 1) {
        clearInterval(this.fadeInterval);
      }
    }, interval);
  }

  load() {
	  const audio = this.$refs.bgAudio as any;
	  if(!audio) {
		  return;
	  }
	  audio.load();
  }

  play() {
    VsrModule.setMuteBgSound(false);
  }

  pause() {
    VsrModule.setMuteBgSound(true);
  }

  onPlaying() {
    this.isPlaying = true;
  }

  onPause() {
    this.isPlaying = false;
  }
}
