


























import Vue from "vue";
import Component from "vue-class-component";
import Layout from "@/layouts/Vsr.vue";
import DetailPageMenu from "@/components/Vsr/DetailPageMenu.vue";
import VideoPlayer from "@/components/Vsr/VideoPlayer.vue";
import { VsrPageInfo } from "@/store/models/Vsr/VsrModel";
import VsrModule from "@/store/modules/VsrModule";

@Component({
  components: {
    Layout,
    DetailPageMenu,
    VideoPlayer
  }
})
export default class VsrM2go extends Vue {
  info = new VsrPageInfo("m2go");
  showIntro = true;

  get menu() {
    return this.$refs.menu as any;
  }

  get videoPlayer() {
    return this.$refs.videoPlayer as any;
  }

  changeMenu(i: number) {
    VsrModule.setBgSoundType(1);
    this.showIntro = false;
    this.videoPlayer.load(this.info.getVideo(i), this.info.getCaption(i));
  }

  onSkip() {
    if (this.menu.index + 1 >= this.info.getItemCount()) {
      this.videoPlayer.stop();
      return;
    }

    this.menu.select(this.menu.index + 1);
  }
}
