







































import Component from "vue-class-component";
import {FormMixin} from "@/components/Mixins/FormMixin";
import {Prop} from 'vue-property-decorator';

@Component
export default class VueTextarea extends FormMixin {
    @Prop({default: ''}) width!: string;
    @Prop({default: ''}) height!: string;
    @Prop({default: false}) resize!: boolean;

    focusActive: boolean = false

    $refs!: {
        input: HTMLInputElement;
    };

    setFocus(focusValue: boolean) {
        this.focusActive = focusValue;
    }

    focus() {
        this.$refs.input.focus();
    }

    resizeNo(e: any){
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`
    }
}
