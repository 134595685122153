









import Vue from 'vue'
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
@Component
export default class VueTag extends Vue {
    @Prop({default: ''}) tagType?: string;
}
