import {BaseRes, IAsset} from "@/store/models/BaseModel";
import {ICommonCode} from "@/store/models/CommonModel";

export enum IMediaCenterViewTarget {
    ALL = 'All',
    NORMAL = 'Normal',
    VIP = 'VIP'
}

export enum MEDIA_TYPE {
    YOUTUBE = 'YOUTUBE',
    NEWS = 'NEWS',
    INSTAGRAM = 'INSTAGRAM',
    FACEBOOK = 'FACEBOOK'
}

export interface IMediaCenterNews {
    pk: number; // 기본키
    commonCodePk: number; // 카테고리 코드
    title: string; // 미디어뉴스 타이틀
    contentPc: string; // 내용 PC
    contentMob: string; // 내용 Mobile
    movUrl: string; // 영상 URL
    assets: IAsset[];
    mediaType: string;
    viewCnt: number; // 조회수
    viewTarget: IMediaCenterViewTarget;
}

export interface IMediaCenterState {
    mediaCenterNewsList: IMediaCenterNews[];
    mediaCenterCommonCodes: ICommonCode[];
}

export interface IMediaCenterNewsReq {
    pageNo: number; // 페이지 번호
    rowPerPage: number; // 페이지당 로우 수
    commonCodePk?: number; // 카테고리 코드 (전체:0, 그외 공통 코드 PK)
    siteLang: string; // 사이트 언어
    orderBy: string; //정렬조건 (new:최근등록순, view:조회수많은순)
}

export class MediaCenterNewsListRes extends BaseRes {

    items!: IMediaCenterNews[];

    constructor() {
        super();
    }
}
