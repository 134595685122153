



















































































































import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import Layout from "@/layouts/index.vue";
import VueLogin from "@/components/Auth/VueLogin.vue";
import VueRegistry from "@/components/Auth/VueRegistry.vue";
import VueFindPassword from "@/components/Auth/VueFindPassword.vue";
import {ICommonCode} from "@/store/models/CommonModel";
import VueInput from "@/components/Form/VueInput.vue";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueTab from "@/components/Tab/VueTab.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueFindEmail from "@/components/Auth/VueFindEmail.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import {State} from "vuex-class";
import {ILoginReqValidate} from "@/store/models/Auth/AuthModel";

@Component({
    components: {
        VueInput,
        VueCheck,
        VueButton,
        VueTab,
        VueSelect,
        VueFindEmail,
        VueFindPassword,
        VueValidator,
        Layout,
        VueLogin,
        VueRegistry,
    },
    metaInfo() {
        return {
            title: this.$route.meta.title,
        };
    },
})
export default class FindPassword extends Vue {

    @State(state => state.lang.locale)
    locale!: string;

    emailText: string = '';

    isLogin: boolean = false;

    findIdPassword: boolean = true;

    findIdSuccess: boolean = false;

    findPasswordSuccess: boolean = false;

    activeIndex: number = 1;

    tabList: ICommonCode[] = [
        {
            name: "login_page.find_email.find_email_btn",
            pk: 0,
        },
        {
            name: "login_page.find_email.find_password_btn",
            pk: 1,
        },
    ];

    validator: ILoginReqValidate = {
        email: "",
        password: "",
    };

    setSelected(tab: number) {
        this.activeIndex = tab;
    }

    findEmail(email: string) {
        this.emailText = email;
        this.showPage("findIdSuccess");
    }

    findPassword(email: string) {
        this.emailText = email;
        this.showPage("findPasswordSuccess");
    }

    showPage(page: string) {
        switch (page) {
            case "findId":
                this.emailText = this.$route.query?.email ? this.$route.query.email.toString() : "";
                this.isLogin = false;
                this.findIdPassword = true;
                this.findIdSuccess = false;
                this.findPasswordSuccess = false;
                this.activeIndex = 0;
                break;

            case "findPassword":
                this.emailText = this.$route.query?.email ? this.$route.query.email.toString() : "";
                this.isLogin = false;
                this.findIdPassword = true;
                this.findIdSuccess = false;
                this.findPasswordSuccess = false;
                this.activeIndex = 1;
                break;

            case "findIdSuccess":
                this.isLogin = false;
                this.findIdPassword = false;
                this.findIdSuccess = true;
                this.findPasswordSuccess = false;
                this.activeIndex = 0;
                break;

            case "findPasswordSuccess":
                this.isLogin = false;
                this.findIdPassword = false;
                this.findIdSuccess = false;
                this.findPasswordSuccess = true;
                this.activeIndex = 1;
                break;

            case "login":
            default:
                this.emailText = "";
                this.isLogin = true;
                this.findIdPassword = false;
                this.findIdSuccess = false;
                this.findPasswordSuccess = false;
                this.activeIndex = 0;
                break;
        }
    }

    @Watch('locale')
    watchChangeLocale() {
        this.validator = {
            email: '',
            password: '',
        };
    }
}
