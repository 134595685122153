



































































import Component from "vue-class-component";
import { CardItemMixin } from "@/components/Mixins/CardItemMixin";
import { Prop } from "vue-property-decorator";
import VueButton from "@/components/Button/VueButton.vue";
import { MEDIA_TYPE } from "@/store/models/MediaCenter/MediaCenterModel";
import { IAsset } from "@/store/models/BaseModel";

@Component({
	components: {
		VueButton,
	},
})
export default class ImageCardListItem extends CardItemMixin {
	@Prop({ default: "simple" }) cardType!: string;

	@Prop({ default: false }) bookmarkType!: boolean;

	isActive: boolean = false;

	MEDIA_TYPE = MEDIA_TYPE;

	swiperOption = {
		loop: false,
		keyboard: {
			enabled: true,
		},
		slidesPerView: "auto",
		breakpoints: {
			721: {
				spaceBetween: 20,
			},
		},
		pagination: {
			el: ".thumbnail-pagination",
			clickable: true,
		},
		watchOverflow: true
	};

	bookmarkActive() {
		this.isActive = !this.isActive;
	}

	getContent() {
        const _item = this.item as {
            content?: string
            contentMob?: string
            contentPc?: string
        };

	    if (_item.content) {
	        return _item.content;
        } else {
            return (this.$device.mobile ? _item.contentMob : _item.contentPc);
        }
    }

	getThumbnail(item: { assets: any[]; }) {
		console.log(this.$device.mobile);
		let assets = item.assets.filter((asset: IAsset) => {
			if (this.$device.mobile && asset.assetTarget === "MOB") {
				// Mobile 만 필터링
				return asset;
			} else if (!this.$device.mobile && asset.assetTarget === "PC") {
				// PC만 필터링
				return asset;
			} else if (asset.assetTarget === "SLIDE") {
				// 둘다아닌경우는 SLIDE 타입
				return asset;
			}
		});

		console.log(assets);

		return assets;
	}
}
