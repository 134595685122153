





















import { Component, Vue } from "vue-property-decorator";
import { ROUTE_NAME } from "./router";
import BackgroundSound from "@/components/Vsr/BackgroundSound.vue";

@Component({
  components: {
    BackgroundSound
  }
})
export default class App extends Vue {
  get isMain() {
    return this.$route.path.search(ROUTE_NAME.VSR_INDEX) === -1;
  }
}
