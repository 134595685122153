export const BASE_S3_URL =
  "https://hyundai-mobis-image.s3.ap-northeast-2.amazonaws.com/static";

export interface IVsrCaptionItem {
  start: number;
  end: number;
  text: string;
}

export interface IVsrPageInfoItem {
  title: string;
  video: string;
  products?: IVsrProductSummary[];
  caption: { [lang: string]: IVsrCaptionItem[] };
}

export interface IVsrPageInfo {
  intro?: string;
  items?: IVsrPageInfoItem[];
}

export interface IVsrProductSummary {
  pk: number;
  pk_dev: number;
  pk_ch: number;
  model: string;
  categoryName: string;
}

export class VsrPageInfo {
  data!: IVsrPageInfo;

  constructor(pageName: string) {
    this.data = require("@/assets/json/vsr.json")[pageName];
  }

  getIntroUrl() {
    if (this.data.intro?.indexOf("http") !== -1) {
      return this.data.intro;
    }
    return BASE_S3_URL + this.data.intro;
  }

  getItemAtIndex(index: number) {
    return this.data.items![index];
  }

  getItemCount() {
    return this.data.items ? this.data.items!.length : 0;
  }

  getItems() {
    return this.data.items;
  }

  getTitles() {
    const titles = [];
    for (const item of this.data!.items!) {
      titles.push(item.title);
    }
    return titles;
  }

  getVideo(index: number) {
    if (this.getItemAtIndex(index).video?.indexOf("http") !== -1) {
      return this.getItemAtIndex(index).video;
    }
    return BASE_S3_URL + this.getItemAtIndex(index).video;
  }

  getCaption(index: number) {
    return this.data.items![index].caption;
  }
}
