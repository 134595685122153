



























































import Vue from "vue";
import Component from "vue-class-component";
import VueInput from "@/components/Form/VueInput.vue";
import { Prop, Watch } from "vue-property-decorator";
import { IOption } from "@/store/models/Form/FormModel";

@Component({
	components: {
		VueInput
	}
})
export default class VueSelect extends Vue {
	@Prop({ required: true, default: ["선택하세요"] }) options!: IOption[];
	@Prop({ default: "" }) defaultValue!: string | IOption;
	@Prop({ default: 0 }) tabindex!: number;
	@Prop({ default: false }) disabled!: boolean;
	@Prop({ default: "" }) selectSize!: string;
	@Prop({ default: "" }) selectEn!: string;
	@Prop({ default: "" }) colorType!: string;
	@Prop({ default: "" }) width!: string;
	@Prop() mText?: string;
	@Prop() mValue?: string;
	@Prop({ default: false }) multi!: boolean;
    @Prop({ default: '' }) value!: string;

	open: boolean = false;

	selected: string | object | null = "";

	searchText: string = '';

	selectOption(option: IOption) {
		this.selected = option["text"];
		this.open = false;
		this.$emit("input", option["value"]);
	}

	@Watch("options")
    @Watch("defaultValue")
	setSelected() {
		this.selected = this.defaultValue
			? this.defaultValue
			: this.options && this.options.length > 0
                ? this.options[0]
                : null;
	}

    get getOptions() {
        return !this.multi && this.searchText === ''
            ? this.options
            : this.options.filter((item) => {
                if (item.description) {
                    return (item.description + ' ' + item.text).toLowerCase().search(
                        this.searchText.toLowerCase()
                    ) > -1;
                }

                return item.text.toLowerCase().search(
                    this.searchText.toLowerCase()
                ) > -1;
            })
    }

    created() {
        this.selected = this.defaultValue
            ? this.defaultValue
            : this.options && this.options.length > 0
                ? this.options[0]
                : null;
    }
}
