import {IUploadReq, UploadRes} from "@/store/models/CommonModel";
import {axiosFormData} from "@/store/api/BaseApi";

/**
 * 업로드 요청
 * @param uploadReq
 */
export async function reqUpload(uploadReq: IUploadReq): Promise<UploadRes> {

    let uploadRes: UploadRes = new UploadRes();

    const formData = new FormData();
    formData.append('target', uploadReq.target);

    uploadReq.files.forEach((file) => {
        formData.append('files', file);
    })

    await axiosFormData()
        .post('/api/asset-front/new', formData)
        .then(result => {
            uploadRes = (result.data as UploadRes);
        })
        .catch(error => uploadRes = error);

    return uploadRes;
}
