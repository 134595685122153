import Vue from 'vue'
import App from './App.vue'
import store from './store/';
import {router} from "./router";
import VueMoment from "vue-moment";
import moment from "moment";
import device from "vue-device-detector"
import {i18n} from "./libs/i18n"
import VueMeta from "vue-meta"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import '@/assets/css/import.scss';
import 'swiper/css/swiper.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import mobileDeviceDetect, {IMobileDeviceDetect} from "@/plugins/mobileDeviceDetect";
import DeviceDetect from "mobile-device-detect";
import Utils from "@/libs/Utils";
import utilityServiceProvider from "@/plugins/utilityServiceProvider";
import {augmentVueRouterPush} from "@/plugins/VueRouter-AugmentedPush";
import VueGtm from 'vue-gtm';

augmentVueRouterPush();

Vue.config.productionTip = false

moment.locale('en');

Vue.use(VueMoment, { moment });
Vue.use(device);
Vue.use(VueMeta)
Vue.use(VueAwesomeSwiper);
Vue.use(mobileDeviceDetect);
Vue.use(utilityServiceProvider);

// @ts-ignore
Vue.use(AOS);

Vue.use(VueGtm, {
	id: 'GTM-WBRV38T',
	defer: false,
	enabled: true,
	debug: true,
	loadScript: true,
	vueRouter: router,
	trackOnNextTick: false
})

Vue.directive('a-html', {
	bind: (el, binding, vnode) => {
		el.innerHTML = binding.value.toString().replace(/(?:\r\n|\r|\n)/g, '<br />');
	}
});

Vue.directive('html-to-text', {
	bind: (el, binding, vnode) => {
		el.innerHTML = binding.value.toString().replace(/(?:\r\n|\r|\n)/g, '<br />');
		el.innerHTML = binding.value.toString().replace( /(<([^>]+)>)/gi, '');
	}
});

declare module 'vue/types/vue' {
	interface Vue {
		$mdevice: IMobileDeviceDetect,
		$utils: Utils
	}
}

new Vue({
	router,
	store,
	i18n,
	mounted () {
		AOS.init({
			// @ts-ignore
			easing: 'my-easing',
			duration: 500,
			delay: 50,
			offset: -10000
		});
	},
	render: h => h(App),
}).$mount('#app')
