export class Session {

    static token: string | null =  sessionStorage.getItem('session');

    static getToken() {
        return Session.token;
    }

    static setToken(token: string) {
        Session.token = token;
        //sessionStorage.setItem('session', token);
        setSessionToken(token).then(r => { return true });
    }

    static clearToken() {
        sessionStorage.removeItem('session');
        Session.token = null;
    }

    static hasToken(): boolean {
        return Session.token !== null;
    }
}

export function setSessionToken(token: string) {
    return Promise.resolve().then(() => {
        sessionStorage.setItem('session', token);
    })
}

export function getSessionToken() {
    return Promise.resolve().then(() => {
        return sessionStorage.getItem('session');
    })
}
