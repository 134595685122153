




























import Vue from 'vue';
import { Component } from "vue-property-decorator";
import Layout from "@/layouts/index.vue";
import VueInformation from "@/components/Information/index.vue";
import VueInformationTitle from "@/components/Information/InformationTitle.vue";
import VueBookmark from "@/components/Bookmark/index.vue";
import VueBookmarkTitle from "@/components/Bookmark/BookmarkTitle.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueListTab from "@/components/Tab/VueListTab.vue";
import {ICommonCode} from "@/store/models/CommonModel";

@Component({
    components: {
        Layout, VueInformation, VueBookmark, VueInformationTitle, VueBookmarkTitle, VueSelect, VueButton, VueListTab
    },
    metaInfo() {
        return {
            title: `My Account | MOBIS Business Connect.`
        };
    },
})
export default class Mypage extends Vue {

    switcher: string[] = [
        'VueInformation',
        'VueBookmark'
    ];

    activeIndex: number | string = 0;

    titleSwitcher: string[] = [
        'VueInformationTitle',
        'VueBookmarkTitle'
    ];

    tabList: ICommonCode[] = [
		{
            name: "mypage_page.tabs.information",
            pk: 0,
		},
		{
            name: "mypage_page.tabs.bookmark",
            pk: 1
		},
	]

    created() {
        this.activeIndex = this.$route.query.activeIndex
            ? this.$route.query.activeIndex.toString()
            : 0;
    }

}
