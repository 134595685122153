import {IMediaCenterNewsReq, MediaCenterNewsListRes} from "@/store/models/MediaCenter/MediaCenterModel";
import {axiosAuth} from "@/store/api/BaseApi";
import {BaseRes} from "@/store/models/BaseModel";

/**
 * 미디어센터 페이지 리스트 API
 * @param mediaCenterNewsReq
 */
export async function reqMediaCenterNewsList(mediaCenterNewsReq: IMediaCenterNewsReq): Promise<MediaCenterNewsListRes> {

    let mediaCenterNewsList = new MediaCenterNewsListRes()

    await axiosAuth()
        .post('/api/medianews/list', mediaCenterNewsReq)
        .then(result => {
            mediaCenterNewsList = (result.data.data as MediaCenterNewsListRes);
        })
        .catch(error => mediaCenterNewsList = error);

    return mediaCenterNewsList;
}

/**
 * 미디어센터 조회수 증가
 * @param mediaCenterNewsPk
 */
export async function reqMediaCenterUpCount(mediaCenterNewsPk: number): Promise<BaseRes> {

    let res: BaseRes = new BaseRes();

    await axiosAuth()
        .post('/api/medianews/upcount', {
            pk: mediaCenterNewsPk
        })
        .then(result => res = (result.data as BaseRes))
        .catch(error => res = error);
    
    return res;
}
