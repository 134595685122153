






























































import Component from "vue-class-component";
import {FormMixin} from "@/components/Mixins/FormMixin";
import {ROUTE_NAME} from "@/router";
import VueInput from "@/components/Form/VueInput.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import AuthModule from "@/store/modules/AuthModule";

export interface IUserValidator {
    passwordValidator: string;
    updatePasswordValidator: string;
    checkPasswordValidator: string
}

@Component({
    components: {
        VueInput,
        VueButton,
        VueValidator,
    },
})
export default class VueResetPassword extends FormMixin {
    ROUTE_NAME = ROUTE_NAME;

    password: string = '';
    updatePassword: string = '';
    checkPassword: string = '';

    validation: IUserValidator = {
        passwordValidator: '',
        updatePasswordValidator: '',
        checkPasswordValidator: '',
    }

    $refs!: {
        password: HTMLInputElement;
        checkPassword: HTMLInputElement;
        updatePassword: HTMLFormElement;
    };

    validate() {
        const passwordRule =
            /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*?])[A-Za-z\d!@#$%^&*?]{8,16}$/;

        let valid = true;

        this.validation.passwordValidator = '';
        this.validation.updatePasswordValidator = '';
        this.validation.checkPasswordValidator = '';

        if (this.password.length < 1) {
            this.validation.passwordValidator = "validate.reset_password.password.required";
            valid = false;

            this.$refs.password.focus();

            return valid;
        }

        if (!passwordRule.test(this.updatePassword)) {
            this.validation.updatePasswordValidator = 'validate.registry.password.not_correct';
            valid = false;

            this.$refs.updatePassword.focus();

            return valid;
        }

        if (this.updatePassword.length < 1) {
            this.validation.updatePasswordValidator = "validate.reset_password.password.required";
            valid = false;

            this.$refs.updatePassword.focus();

            return valid;
        }

        if (this.checkPassword.length < 1) {
            this.validation.checkPasswordValidator = "validate.reset_password.password.required";
            valid = false;

            this.$refs.checkPassword.focus();

            return valid;
        }

        if (valid && this.updatePassword !== this.checkPassword) {
            this.validation.checkPasswordValidator = "validate.reset_password.password.not_matched";
            valid = false;

            this.$refs.checkPassword.focus();
        }

        return valid;
    }

    async submit() {
        if (this.validate()) {
            await AuthModule.actionUserPasswordReset({
                password: this.password,
                updatePassword: this.updatePassword,
                checkPassword: this.checkPassword
            })
                .then(res => {
                    // @ts-ignore
                    if (Object.keys(res?.errors).length > 0) {
                        // @ts-ignore
                        Object.keys(res.errors).map((item) => {
                            // @ts-ignore
                            this.validation[item + 'Validator'] = res.errors[item][0];
                        })
                    } else {
                        AuthModule.logout();
                    }
                })
        }
    }
}
