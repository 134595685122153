import {IQnaEditReq, QnaListRes, QuestionTypeRes} from "@/store/models/Qna/QnaModel";
import {axiosAuth} from "@/store/api/BaseApi";
import {BaseRes, EditRes} from "@/store/models/BaseModel";

export async function reqQnaList(): Promise<QnaListRes> {

    let qnaListRes: QnaListRes = new QnaListRes();

    await axiosAuth()
        .post('/api/qna/list', {})
        .then(result => {
            qnaListRes = (result.data.data as QnaListRes);
        })
        .catch(error => qnaListRes = error);

    return qnaListRes;
}

export async function reqQuestionType(): Promise<QuestionTypeRes> {

    let questionTypeRes: QuestionTypeRes = new QuestionTypeRes();

    await axiosAuth()
        .post('/api/qna/question-type')
        .then(result => {
            questionTypeRes = (result.data as QuestionTypeRes)
        })
        .catch(error => questionTypeRes = error);

    return questionTypeRes;
}

export async function reqQnaEdit(qnaEdirReq: IQnaEditReq): Promise<EditRes> {
    let qnaEditRes = new EditRes();

    await axiosAuth()
        .post('/api/qna/edit',
            qnaEdirReq
        )
        .then(result => {
            qnaEditRes = (result.data as EditRes);
        })
        .catch(error => qnaEditRes = error);

    return qnaEditRes
}

export async function reqQnaRemove(pk: number): Promise<BaseRes> {
    let qnaRemoveRes = new BaseRes();

    await axiosAuth()
        .post('/api/qna/delete', {
            pk: pk
        })
        .then(result => {
            qnaRemoveRes = (result.data as BaseRes);
        })
        .catch(error => qnaRemoveRes = error);

    return qnaRemoveRes;
}
