export class OAuth {

    static token: string | null =  localStorage.getItem('token');

    static getToken() {
        return OAuth.token;
    }

    static setToken(token: string) {
        OAuth.token = token;
        setOAuthToken(token).then(r => { return true });
    }

    static clearToken() {
        localStorage.removeItem('token');
        OAuth.token = null;
    }

    static hasToken(): boolean {
        return OAuth.token !== null;
    }
}

export function setOAuthToken(token: string) {
    return Promise.resolve().then(() => {
        localStorage.setItem('token', token);
    })
}

export function getOAuthToken() {
    return Promise.resolve().then(() => {
        return localStorage.getItem('token');
    })
    .catch(() => {
        return '';
    })
}
