import {Action, getModule, Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import store from "@/store";

@Module({
    store,
    name: 'loginModal',
    namespaced: true,
    dynamic: true
})
class LoginModalModule extends VuexModule {

    isShow: boolean = false;

    loginCallback: Function = {} as Function;

    @Mutation
    mutationChangeIsShow(isShow: boolean) {
        this.isShow = isShow;
    }

    @Mutation
    mutationChangeLoginCallback(callback: Function) {
        this.loginCallback = callback;
    }

    @Action
    show(callback: Function) {
        this.mutationChangeLoginCallback(callback);
        this.mutationChangeIsShow(true);
    }

    @Action
    close() {
        this.mutationChangeIsShow(false);
    }
}

export default getModule(LoginModalModule);
