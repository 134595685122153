import { render, staticRenderFns } from "./VueRegistry.vue?vue&type=template&id=5be2a32e&scoped=true&"
import script from "./VueRegistry.vue?vue&type=script&lang=ts&"
export * from "./VueRegistry.vue?vue&type=script&lang=ts&"
import style0 from "./VueRegistry.vue?vue&type=style&index=0&id=5be2a32e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5be2a32e",
  null
  
)

export default component.exports