import {BaseRes, IAsset} from "@/store/models/BaseModel";

export enum QnaStatus {
    WAIT = 'WAIT',
    COMPLETE = 'COMPLETE',
}

export interface IQna {
    pk: number;
    siteLang: string;
    questionTypeCode: string;
    processStatus: string;
    title: string;
    content: string;
    url: string;
    assets: IAsset[],
    createAt: string;
    answer: IAnswer
}

export interface IAnswer {
    pk: number;
    content: string;
    url: string;
    assets: IAsset[]
    createAt: string;
    updateAt: string;
}

export interface IQuestionType {
    name: string;
    code: string;
}

export interface IQnaItem {
    pk: number,
    isActive: boolean,
    questionTypeCode: string,
    title: string,
    date: string,
    state: string,
    inquiryContent: string,
    questionAdd: IAdd,
    answerAdd: IAdd,
    answerContent: string
}

export class QnaItem implements IQnaItem {
    pk: number = 0;
    answerAdd: IAdd = {link: '', assets: []};
    answerContent: string = '';
    date: string = '';
    inquiryContent: string = '';
    isActive: boolean = false;
    questionAdd: IAdd = {link: '', assets: []};
    questionTypeCode: string = '';
    state: string = 'WAIT';
    title: string = '';
}

export interface IAdd {
    link: string;
    assets: IAsset[]
}

export interface IQnaEditValidate {
    title: string;
    content: string;
    assets: string;
}

export interface IQnaState {
    qnaList: IQna[];
    questionTypes: IQuestionType[];
}

export interface IQnaEditReq {
    pk?: number;
    siteLang: string;
    title: string;
    content: string;
    url: string;
    assets: string[] // Asset uuid array
}

export class QnaListRes extends BaseRes {
    items!: IQna[];

    constructor() {
        super();
    }
}

export class QuestionTypeRes extends BaseRes {
    data!: IQuestionType[];

    constructor() {
        super();
    }
}
