




















































































































import Vue from "vue";
import Component from "vue-class-component";
import { ROUTE_NAME } from "@/router";
import { State } from "vuex-class";
import { IOption, ObjType } from "@/store/models/Form/FormModel";
import Auth from "@/store/modules/AuthModule";
import Lang from "@/store/modules/LangModule";
import axios from "axios";
import AcessDenyPopup from "@/components/Vsr/AcessDenyPopup.vue";

@Component({
  components: {
    AcessDenyPopup
  },
  metaInfo() {
    return {
      title: "MOBIS VIRTUAL SHOWROOM"
    };
  }
})
export default class Layout extends Vue {
  ROUTE_NAME = ROUTE_NAME;

  sections: object[] = [
    {
      title: "M. Vision",
      routeName: ROUTE_NAME.VSR_M2GO,
      subItems: [
        { title: "M. Vision TO", routeName: ROUTE_NAME.VSR_MTO },
        { title: "M. Vision HI", routeName: ROUTE_NAME.VSR_MHI },
        { title: "M. Vision POP", routeName: ROUTE_NAME.VSR_MPOP },
        { title: "M. Vision 2GO", routeName: ROUTE_NAME.VSR_M2GO }
      ]
    },
    {
      title: "Tech Gallery",
      routeName: ROUTE_NAME.VSR_TECH_GALLERY
    },
    {
      title: "MOBIS Vision",
      routeName: ROUTE_NAME.VSR_MOBIS_VISION
    },
    {
      title: "MOBIS Now",
      routeName: ROUTE_NAME.VSR_MOBIS_NOW
    }
  ];

  isOpenSubItem = false;

  localeOption: IOption[] = [];
  isOpenLanSelector: boolean = false;
  isOpenSideMenu: boolean = false;

  @State(state => state.lang.originLocale) originLocale!: string;
  @State(state => state.lang.locale) locale!: string;
  @State(state => state.lang.localeToArray) localeToArray!: ObjType;
  @State(state => state.lang.localeToHtmlLang) localeToHtmlLang!: ObjType;

  get isLogin() {
    return Auth.isLogin;
  }

  mounted() {
    for (const item of Object.keys(this.localeToArray)) {
      this.localeOption.push({
        text: this.localeToArray[item],
        value: item
      });
    }

    // setTimeout(() => {
    //   (this.$refs.bgAudio as any).play();
    // }, 1000);
  }

  showAccessDenyPopup() {
    (this.$refs.accessDenyPopup as any).setShow(true);
  }

  goRoute(routeName: string) {
    if (!this.isLogin && routeName == ROUTE_NAME.VSR_TECH_GALLERY) {
      this.showAccessDenyPopup();
      return;
    }

    if (routeName == ROUTE_NAME.VSR_TECH_GALLERY) {
      this.$router.push({
        name: ROUTE_NAME.HOME,
        params: { lang: this.locale }
      });
      return;
    }

    this.$router.push({ name: routeName, params: { lang: this.locale } });
  }

  openLangSelector(isOpen: boolean) {
    this.isOpenLanSelector = isOpen;
  }

  openSideMenu(isOpen: boolean) {
    this.isOpenSideMenu = isOpen;
  }

  clickSection(index: number) {
    if (index === 0) {
      this.isOpenSubItem = !this.isOpenSubItem;
    } else {
      this.goRoute((this.sections[index] as any).routeName);
    }
  }

  sectionItemClass(item: any) {
    let classNames = [];
    if (item.subItems) {
      classNames.push("has-sub-item");

      if (this.isOpenSubItem) {
        classNames.push("expanded");
      }
    }

    return classNames.join(" ");
  }

  localeToChar(locale: string) {
    return locale == "en" ? "EN" : this.localeToArray[locale].substring(0, 1);
  }

  changeLang(locale: string) {
    this.isOpenLanSelector = false;

    let url: string = "/" + locale;

    if (this.$route.name !== null && this.$route.name !== undefined) {
      if (
        this.$route.name.toString() !== "home" &&
        this.$route.name.toString() !== "lang"
      ) {
        url += "/" + this.$route.name.toString();
      }
    }

    Lang.changeLocale(locale);
    this.$i18n.locale = this.localeToHtmlLang[locale];
    axios.defaults.headers.common["Accept-Language"] = this.localeToHtmlLang[
      locale
    ];
    document
      .querySelector("html")
      ?.setAttribute("lang", this.localeToHtmlLang[locale]);

    history.pushState(this.$route.params, "", url);
  }

  async logout() {
    Auth.logout(ROUTE_NAME.VSR_LOBBY);
  }
}
