import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {BaseRes} from "@/store/models/BaseModel";
import {OAuth} from "@/libs/OAuth";
import Lang from "@/store/modules/LangModule";
import SpinnerModule from "@/store/modules/SpinnerModule";
import {ROUTE_NAME, router} from "@/router";
import AuthModule from "@/store/modules/AuthModule";
import {Session} from "@/libs/Session";
import {SessionTokenRes} from "@/store/models/Auth/AuthModel";
import {reqGetSessionToken} from "@/store/api/AuthApi";
import LoginModalModule from "@/store/modules/LoginModalModule";

export enum REQUEST_RESULT {
    SUCCESS = 200,
    ERROR_WITH_MESSAGE = 422,
    UNAUTHORIZATION = 423,
    AUTHORIZATION_FAILED = 401,
    SERVER_ERROR = 500,
}

const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'X-SITE-LANG': Lang.getLocaleToLang
};

const BASE_URL = process.env.VUE_APP_BASE_URL;

function defaultInterceptorRequest(request: AxiosRequestConfig) {
    SpinnerModule.open();
    return request;
}

function noSpinnerInterceptorRequest(request: AxiosRequestConfig) {
    return request;
}

function responseInterceptors(customAxios: AxiosInstance, type: string) {
    customAxios.interceptors.request.use(
        type === 'default'
            ? defaultInterceptorRequest
            : noSpinnerInterceptorRequest
    );

    customAxios.interceptors.response.use(
        function (response) {
            if (type === 'default') {
                SpinnerModule.close();
            }
            return response;
        },
        function (error) {
            const baseRes: BaseRes = new BaseRes();

            if (type === 'default') {
                SpinnerModule.close();
            }

            if (error.response == null) {
                baseRes.setBasicError();
            }

            switch (error.response.status) {
                case REQUEST_RESULT.AUTHORIZATION_FAILED:
                case REQUEST_RESULT.UNAUTHORIZATION:
                    OAuth.clearToken();
                    baseRes.setAuthError();
                    break;
                case REQUEST_RESULT.ERROR_WITH_MESSAGE:
                    baseRes.setValidateError(error.response);
                    break;
                case REQUEST_RESULT.SERVER_ERROR:
                    baseRes.setServerError();
                    break;
                default:
                    baseRes.setBasicError();
                    break;
            }
            return Promise.reject(baseRes);
        }
    );
    return customAxios;
}

export function axiosDefault() {
    DEFAULT_HEADERS["X-SITE-LANG"] = Lang.getLocaleToLang

    return responseInterceptors(axios.create({
        baseURL: BASE_URL,
        headers: DEFAULT_HEADERS
    }), 'default')
}

export function sessionAuth() {
    const token: string | null = OAuth.getToken();

    return responseInterceptors(axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            'X-ACCESS-TOKEN': token,
            'Access-Control-Allow-Origin': '*',
            'X-SITE-LANG': Lang.getLocaleToLang
        }
    }), 'default');
}

export function axiosAuth() {
    const token: string | null = OAuth.getToken();
    const session: string | null = Session.getToken();

    return responseInterceptors(axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            'X-ACCESS-TOKEN': token,
            'X-SESSION-TOKEN': session,
            'Access-Control-Allow-Origin': '*',
            'X-SITE-LANG': Lang.getLocaleToLang
        }
    }), 'default');
}

export function axiosFormData() {
    const token: String | null = OAuth.getToken();
    const session: string | null = Session.getToken();

    return responseInterceptors(axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'X-ACCESS-Token': token,
            'X-SESSION-TOKEN': session,
            'Access-Control-Allow-Origin': '*',
            'X-SITE-LANG': Lang.getLocaleToLang
        }
    }), 'default');
}

export function noticeAxios() {
    const token: String | null = OAuth.getToken();
    const session: string | null = Session.getToken();

    return responseInterceptors(axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            'X-ACCESS-TOKEN': token,
            'X-SESSION-TOKEN': session,
            'Access-Control-Allow-Origin': '*',
            'X-SITE-LANG': Lang.getLocaleToLang
        }
    }), 'notice');
}
