






















































































import Vue from "vue";
import Component from "vue-class-component";
import VueModal from "@/components/Modal/VueModal.vue";
import VueInput from "@/components/Form/VueInput.vue";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueTab from "@/components/Tab/VueTab.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueFindEmail from "@/components/Auth/VueFindEmail.vue";
import VueFindPassword from "@/components/Auth/VueFindPassword.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import {ILoginReqValidate, LoginReq} from "@/store/models/Auth/AuthModel";
import {ICommonCode} from "@/store/models/CommonModel";
import {IOption} from "@/store/models/Form/FormModel";
import Auth from "@/store/modules/AuthModule";
import {State} from "vuex-class";
import LoginModalModule from "@/store/modules/LoginModalModule";
import {ROUTE_NAME} from "@/router";

@Component({
    components: {
        VueModal,
        VueInput,
        VueCheck,
        VueButton,
        VueTab,
        VueSelect,
        VueFindEmail,
        VueFindPassword,
        VueValidator,
    },
})
export default class VueLoginModal extends Vue {

    @State(state => state.loginModal.isShow) isShow!: boolean;

    @State(state => state.loginModal.loginCallback) loginCallback!: Function;

    switcher: string = "isLogin";

    loginData: LoginReq = {
        email: this.getEmail() || "",
        password: "",
    };

    validator: ILoginReqValidate = {
        email: "",
        password: "",
    };

    isRemember: boolean = this.getIsRemember() || false;

    emailText: string = "";

    //pages
    isLogin: boolean = true;
    findIdPassword: boolean = false;
    findIdSuccess: boolean = false;
    findPasswordSuccess: boolean = false;

    //tab
    activeIndex: number = 0;

    tabList: ICommonCode[] = [
        {
            name: "login_page.find_email.find_email_btn",
            pk: 0,
        },
        {
            name: "login_page.find_email.find_password_btn",
            pk: 1,
        },
    ];

    //휴대폰
    numberList: IOption[] = [];

    getEmail() {
        return localStorage.getItem("rememberEmail");
    }

    getIsRemember() {
        let isRemember: boolean = false;

        if (localStorage.getItem("rememberEmail")) {
            isRemember = true;
        }

        return isRemember;
    }

    validate() {
        let valid: boolean = true;

        this.validator = {
            email: "",
            password: "",
        };

        if (this.loginData.email.length < 1) {
            this.validator.email = this.$t(
                "validate.login.email.required"
            ).toString();
            valid = false;
        }

        if (this.loginData.password.length < 1) {
            this.validator.password = this.$t(
                "validate.login.password.required"
            ).toString();
            valid = false;
        }

        return valid;
    }

    async login() {
        if (this.validate()) {
            if (this.isRemember) {
                localStorage.setItem("rememberEmail", this.loginData.email);
            }

            await Auth.login(this.loginData)
                .then((res: any) => {
                    if (res.errors.email) {
                        this.validator.email = res.errors.email[0]
                    }

                    if (res.errors.password) {
                        this.validator.password = res.errors.password[0]
                    }

                    if (res.data) {
                        LoginModalModule.close()
                        console.log("callback :: ", this.loginCallback);
                        this.loginCallback();
                    }
                });
        }
    }

    showRegistry() {
        this.close();

        this.$router.push({
            name: ROUTE_NAME.LOGIN,
            params: {
                target: 'VueRegistry'
            }
        })
    }

    showPage(page: string) {
        this.close();

        this.$router.push({
            name: ROUTE_NAME.LOGIN,
            query: {
                target: page
            }
        });
    }

    close() {
        LoginModalModule.close();
    }
}
