import store from '@/store';
import {Module, VuexModule, Mutation, getModule, Action, MutationAction} from "vuex-module-decorators";
import {INotification, INotificationState, NotificationListRes} from "@/store/models/Notification/NotificationModel";
import {reqNotificationList, reqNotificationRead} from "@/store/api/NotificationApi";

@Module({
    store,
    name: "notification",
    namespaced: true,
    dynamic: true,
})
class NotificationModule extends VuexModule implements INotificationState {

    notification: INotification = {
        notifications: [],
        managers: []
    };

    @MutationAction
    async actionNotificationList() {
        const notificationRes = await reqNotificationList();

        return {
            notification: notificationRes.data
        }
    }

    @Mutation
    mutationNotificationRead(pk: number) {
        this.notification.notifications.map(item => {
            if (item.pk === pk) {
                item.read = true;
            }
        })
    }

    @Action({
        rawError: true
    })
    async actionNotificationRead(notificationPk: number) {
        await reqNotificationRead({
            notificationPks: [notificationPk]
        });

        this.mutationNotificationRead(notificationPk);
    }
}

export default getModule(NotificationModule)
