import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from '@/store';
import {OAuth} from "@/libs/OAuth";
import {ROUTE_NAME, router} from '@/router/';
import {
    IAuth,
    ICheckEmailAuthReq,
    IFindEmailReq,
    IResetPassword,
    IUserPasswordResetReq,
    LoginReq,
    RegistryReq,
    SessionTokenRes,
    UserInfo,
    UserPasswordResetRes
} from "@/store/models/Auth/AuthModel";
import * as AuthApi from "@/store/api/AuthApi";
import {
    reqCheckEmailAuth,
    reqCheckPasswordAuthEmail,
    reqFindEmail,
    reqGetSessionToken,
    reqResetPassword,
    reqSendPasswordAuthEmail,
    reqUserPasswordReset,
    reqWithdrwal
} from "@/store/api/AuthApi";
import Lang from "@/store/modules/LangModule"
import {EnumLang} from "@/store/models/Lang/LangModel"
import {BaseRes} from "@/store/models/BaseModel";
import {Session} from "@/libs/Session";

@Module({
    store,
    name: "auth",
    namespaced: true,
    dynamic: true,
    preserveState: localStorage.getItem('vuex') !== null
})
class AuthModule extends VuexModule implements IAuth {

    userInfo: UserInfo = new UserInfo();

    @Mutation
    changeUserModel(userInfo: UserInfo) {
        this.userInfo = userInfo;
    }

    @Mutation
    clearUser() {
        this.userInfo = new UserInfo();
    }

    @Action({
        rawError: true
    })
    async login(
        userLogin: LoginReq
    ) {
        const userLoginRes = await AuthApi.reqUserLogin(userLogin);

        if (userLoginRes.data) {
            OAuth.setToken(userLoginRes.data.accessToken);
            Session.setToken(userLoginRes.data.sessionToken);

            const loginUser: UserInfo = new UserInfo()
            loginUser.firstName = userLoginRes.data.firstName
            loginUser.lastName = userLoginRes.data.lastName
            loginUser.userProducts = userLoginRes.data.userProducts
            loginUser.email = userLogin.email

            this.changeUserModel(loginUser)
        }

        return userLoginRes
    }

    @Action({
        rawError: true
    })
    async registry(registryReq: RegistryReq) {
        const userRegisterRes = await AuthApi.reqUserRegistry(registryReq)

        return userRegisterRes
    }

    @Action({
        rawError: true
    })
	async logout(routeName?: string) {
		OAuth.clearToken()
		this.clearUser();

        router.push({
            name: routeName || ROUTE_NAME.HOME
        });
	}

    get isLogin() {
        return !!this.userInfo?.email;
    }

    get getUserName() {
        let name:string = '홍길동'

        if(Object.keys(this.userInfo).length !== 0) {
            if(Lang.getLocaleToCode === (EnumLang.en + 1)) {
                name = this.userInfo.firstName + " " + this.userInfo.lastName
            } else {
                name = this.userInfo.lastName + this.userInfo.firstName
            }
        }

        return name
    }

    @Action
    async actionFindEmail(findEmailReq: IFindEmailReq) {
        const findEmailRes = await reqFindEmail(findEmailReq)

        return findEmailRes
    }

    @Action
    async actionResetPassword(req: IResetPassword) {
        return await reqResetPassword(req)
    }

    @Action
    async actionWithdrawal() {
        const withdrwalRes: BaseRes = await reqWithdrwal()

        OAuth.clearToken()
        this.clearUser()

        if (withdrwalRes.error) {
            console.log(withdrwalRes.error);
        }
    }

    /**
     * 비밀번호 찾기 1단계 이메일 전송
     * @param email
     */
    @Action
    async actionSendPasswordAuthEmail(email: string) {
        return await reqSendPasswordAuthEmail(email);
    }

    @Action
    async actionCheckPasswordAuthEmail(uuid: string) {
        return await reqCheckPasswordAuthEmail(uuid);
    }

    @Action
    async actionCheckEmailAuth(req: ICheckEmailAuthReq) {
        return await reqCheckEmailAuth(req);
    }

    /**
     * 세션토큰 요청
     */
    @Action
    async actionGetSessionToken() {
        const sessionTokenRes: SessionTokenRes = await reqGetSessionToken();
        Session.setToken(sessionTokenRes.data.sessionToken);
        return sessionTokenRes;
    }

    /**
     * 유저 패스워드 변경
     * @param req
     */
    @Action
    async actionUserPasswordReset(req: IUserPasswordResetReq) {
        return await reqUserPasswordReset(req);
    }
}

export default getModule(AuthModule);
