






























































































































































import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import Layout from "@/layouts/index.vue";
import VueTitle from "@/components/Title/VueTitle.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueInput from "@/components/Form/VueInput.vue";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueTextarea from "@/components/Form/VueTextarea.vue";
import VueSortingCheck from "@/components/Form/VueSortingCheck.vue";
import VueCalendar from "@/components/Form/VueCalendar.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import { ICheck, IOption } from "@/store/models/Form/FormModel";
import MeetingModule from "@/store/modules/MeetingModule";
import {
    IMeetingEdit,
	IMeetingEditReqValidate,
	IMeetingUser,
} from "@/store/models/Meeting/MeetingModel";
import { ROUTE_NAME } from "@/router";
import Lang from "@/store/modules/LangModule";
import {State} from "vuex-class";
@Component({
	components: {
		Layout,
		VueTitle,
		VueInput,
		VueCheck,
		VueTextarea,
		VueSelect,
		VueSortingCheck,
		VueButton,
		VueCalendar,
		VueValidator,
	},
    metaInfo() {
        return {
            title: `Meeting Request | MOBIS Business Connect.`
        };
    },
})
export default class RequestMeeting extends Vue {
    @State((state) => state.lang.locale) locale!: string;

	meetingData: IMeetingEdit = {
        title: this.$t('meeting_page.modify.section5.request_content').toString(),
		reason: "",
        changeReason: "",
		productCategoryPk1: 0,
		productCategoryPk2: 0,
		productPk: 0,
		siteLang: Lang.getLocaleToLang,
        requestAnywhen: true,
		requestMeetingAt: null,
		requestPlatform: "Webex",
		requesterJoin: true,
        requestTimeZone: '',
		requestDescription: "",
		meetingUsers: [],
	};

	validator: IMeetingEditReqValidate = {
        title: "",
		business: "",
		requestPlatform: "",
		meetingUsers: "",
		requestDescription: "",
	};

	$refs!: {
		requestDescription: HTMLInputElement;
		requesterJoin: HTMLInputElement;
        title: HTMLInputElement;
        business: HTMLSelectElement;
	};

	meetingUsers: IMeetingUser[] = [];
	meetingPlatformList: ICheck[] = [];
	productCategoryList: IOption[] = [];
	productSubCategoryList: IOption[] = [];
	productList: IOption[] = [];
	meetingUser: number = 0;
    meetingAt: string = '';

    productCategoryText: string = 'meeting_page.modify.section2.category';
    productSubCategoryText: string = 'meeting_page.modify.section2.classification';
    productText: string = 'meeting_page.modify.section2.name';

    requestDescriptionAppender: string
        = `\r\n\r\n----\r\n[${this.$moment().format('YYYY-MM-DD hh:mm:ss')} (GMT${this.$moment().format('Z')})]\r\n\r\n`;

	getMeetingProductCategory(type: string, pk?: number) {
		MeetingModule.actionMeetingProductCategory(pk).then((res: any) => {
			for (const item of res) {
				if (type == "category") {
					this.productCategoryList.push({
						text: item.name,
						value: item.pk,
					});

                    if (item.pk == this.meetingData.productCategoryPk1) {
                        this.productCategoryText = item.name;
                    }
				} else if (type === "subCategory") {
					this.productSubCategoryList.push({
						text: item.name,
						value: item.pk,
					});

                    if (item.pk == this.meetingData.productCategoryPk2) {
                        console.log(item.name)
                        this.productSubCategoryText = item.name;
                    }
				}
			}
		});
	}

	getMeetingProduct(pk: number) {
		MeetingModule.actionMeetingProduct(pk).then((res: any) => {
			for (const item of res) {
				this.productList.push({
					text: item.name,
					value: item.pk,
				});

                if (item.pk == this.meetingData.productPk) {
                    this.productText = item.name;
                }
			}
		});
	}

	changeProductCategory(pk: number) {
		let productSubCategoryList: IOption[] = [];
		let productList: IOption[] = [];

        this.productSubCategoryText = "meeting_page.modify.section2.classification";

        productList.push({
            text: "meeting_page.modify.section2.name",
            value: 0,
        });
        this.productText = "meeting_page.modify.section2.name";

		this.productSubCategoryList = productSubCategoryList;
		this.productList = productList;

		if (pk > 0) {
			this.meetingData.productCategoryPk1 = pk;
			this.meetingData.productCategoryPk2 = 0;
			this.meetingData.productPk = 0;
			this.getMeetingProductCategory("subCategory", pk);
		} else {
			this.meetingData.productCategoryPk1 = pk;
			this.meetingData.productCategoryPk2 = pk;
			this.meetingData.productPk = pk;
		}
	}

	changeProductSubCategory(pk: number) {
		let productList: IOption[] = [];
		this.productList = productList;
        this.productText = "meeting_page.modify.section2.name";

		if (pk > 0) {
			this.meetingData.productCategoryPk2 = pk;
			this.meetingData.productPk = 0;
			this.getMeetingProduct(pk);
		} else {
			this.meetingData.productCategoryPk2 = pk;
			this.meetingData.productPk = pk;
		}
	}

	changeProduct(pk: number) {
		this.meetingData.productPk = pk;
	}

	setMeetingPlatform(key: number) {
		this.meetingPlatformList.forEach((item, index) => {
			item.value = false;
			if (key === index) {
				item.value = true;
				this.meetingData.requestPlatform = item.name;
			}
		}, this);
	}

	removeMeetingUser(key: number) {
		const meetingUser: IMeetingUser[] = [];
		this.meetingUsers.forEach((item, index) => {
			if (key !== index) {
				meetingUser.push(item);
			}
		});
		this.meetingUser--;
		this.meetingUsers = meetingUser;
	}

    @Watch("locale")
    changeSiteLang() {
        this.meetingData.siteLang = Lang.getLocaleToLang;

        this.productCategoryList = [];
        this.productSubCategoryList = [];
        this.productList = [];
        this.productCategoryText = 'meeting_page.modify.section2.category';
        this.productSubCategoryText = 'meeting_page.modify.section2.classification';
        this.productText = 'meeting_page.modify.section2.name';

        this.getMeetingProductCategory("category");
        if (this.meetingData.productCategoryPk1 > 0) {
            this.getMeetingProductCategory("subCategory", this.meetingData.productCategoryPk1);
        } else {
            this.productSubCategoryList.push({
                text: "meeting_page.modify.section2.classification",
                value: 0,
            });
        }

        if (this.meetingData.productCategoryPk2 > 0) {
            this.getMeetingProduct(this.meetingData.productCategoryPk2);
        } else {
            this.productList.push({
                text: "meeting_page.modify.section2.name",
                value: 0,
            });
        }
    }

    @Watch('meetingData.requestAnywhen')
    changeAnywhen() {
	    if (this.meetingData.requestAnywhen) {
	        this.meetingData.requestMeetingAt = null;
        } else {
            this.meetingData.requestMeetingAt = (this.meetingAt) ? this.meetingAt : null;
        }
    }

	created() {
        MeetingModule.actionMeetingNew()
            .then((res: any) => {
                if (res.data) {
                    res.data.platforms.forEach((item: {name: string, value: string}) => {
                        this.meetingPlatformList.push({
                            text: item.name,
                            name: item.value,
                            value: false,
                        });
                    }, this);
                }
            })

		this.productSubCategoryList.push({
			text: "meeting_page.modify.section2.classification",
			value: 0,
		});
		this.productList.push({
			text: "meeting_page.modify.section2.name",
			value: 0,
		});
		this.getMeetingProductCategory("category");
	}

	setRequestMeetingAt(value: string) {
		this.meetingData.requestMeetingAt = (value)
            ? this.$moment(value).format('YYYY-MM-DD HH:mm')
            : '';

		this.meetingAt = this.meetingData.requestMeetingAt;
	}

    checkEmptyMeetingUser() {
        const meetingUser: IMeetingUser[] = [];

        this.meetingUsers.forEach((item) => {
            if (item.firstName && item.lastName) {
                meetingUser.push(item);
            }
        });

        this.meetingUsers = meetingUser;
        this.meetingUser = meetingUser.length;
    }

	validate() {
		let valid = true;
		let focus = false;
        let reg = /<(\/)?([a-zA-Z0-9]*)(\s[a-zA-Z0-9]*=[^>]*)?(\s)*(\/)?>/ig;

        this.validator = {
            title: "",
            business: "",
            requestPlatform: "",
            meetingUsers: "",
            requestDescription: "",
        };

        if (this.meetingData.title.length < 1) {
            this.validator.title = 'meeting_page.modify.alert.enter_subject';
            valid = false;

            if (!focus) {
                focus = true;
            }
        }

		if (
			this.meetingData.productCategoryPk1 < 1 ||
			this.meetingData.productCategoryPk2 < 1
		) {
			this.validator.business = 'meeting_page.modify.alert.all_input';
			valid = false;

			if (!focus) {
                this.$refs.business.focus();
				focus = true;
			}
		}

		if (this.meetingData.requestPlatform.length < 1) {
			this.validator.requestPlatform = 'meeting_page.modify.alert.select_meeting';
			valid = false;

			if (!focus) {
                this.$refs.requestDescription.focus();
				focus = true;
			}
		}

		if (!this.meetingData.requesterJoin && this.meetingUsers.length < 1) {
			this.validator.meetingUsers = 'meeting_page.modify.alert.register_attendee';
			valid = false;

			if (!focus) {
				this.$refs.requesterJoin.focus();
				focus = true;
			}
		}

		if (this.meetingData.requestDescription.length < 1) {
			this.validator.requestDescription = 'meeting_page.modify.alert.enter_meeting';
			valid = false;

			if (!focus) {
				this.$refs.requestDescription.focus();
				focus = true;
			}
		}

        if(reg.test(this.meetingData.title)) {
            this.meetingData.title = this.meetingData.title.replace(reg, "");
        }

        if(reg.test(this.meetingData.requestDescription)) {
            this.meetingData.requestDescription = this.meetingData.requestDescription.replace(reg, "");
        }

		return valid;
	}

	async edit() {
		if (confirm(this.$t('meeting_page.modify.alert.submit_meeting').toString())) {
			if (
				!this.meetingData.requestAnywhen &&
				!this.meetingData.requestMeetingAt
			) {
                this.meetingData.requestAnywhen = true;
                this.meetingData.requestMeetingAt = null;
			}

            this.checkEmptyMeetingUser();
            this.meetingData.meetingUsers = this.meetingUsers
            this.meetingData.requestTimeZone = this.$moment().format('Z');

			if (this.validate()) {
				await MeetingModule.editMeeting(this.meetingData)
                    .then((res: any) => {
                        if (res.pk) {
                            alert(this.$t('meeting_page.modify.alert.end_meeting').toString())
                            this.$router.push({name: ROUTE_NAME.MEETING, params: {lang: this.locale}})
                        } else {
                            alert(this.$t('meeting_page.modify.alert.fail_meeting').toString())
                            console.log('meeting 신청 api 에러')
                        }
                    })
			}
		}
	}

	cancel() {
		if (
			confirm(
				this.$t('meeting_page.modify.alert.cancel_meeting').toString()
			)
		) {
			this.$router.push({ name: ROUTE_NAME.MEETING, params: {lang: this.locale} });
		}
	}
}
