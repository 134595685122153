import store from '@/store';
import {Module, VuexModule, Mutation, getModule, Action} from "vuex-module-decorators";

@Module({
	store,
	name: 'vsr',
	namespaced: true,
	dynamic: true
})
class VsrModule extends VuexModule {

	useBgSound: boolean = false;
	bgSoundType: number = -1;
	muteBgSound: boolean = false;

	@Mutation
	changeUseBgSound(useBgSound: boolean) {
		this.useBgSound = useBgSound;
	}

	@Action
	setUseBgSound(useBgSound: boolean) {
		this.changeUseBgSound(useBgSound);
	}

	@Mutation
	changeBgSoundType(bgSoundType: number) {
		this.bgSoundType = bgSoundType;
	}

	@Action
	setBgSoundType(bgSoundType: number) {
		this.changeBgSoundType(bgSoundType);
	}

	@Mutation
	changeMuteBgSound(muteBgSound: boolean) {
		this.muteBgSound = muteBgSound;
	}

	@Action
	setMuteBgSound(isMute: boolean) {
		this.changeMuteBgSound(isMute);
	}
}

export default getModule(VsrModule);
