

































import Component from "vue-class-component";
import { FormMixin } from "@/components/Mixins/FormMixin";

@Component
export default class VueCheck extends FormMixin {
    $refs!: {
        check: HTMLInputElement;
    };

	change($event: any) {
		this.$emit("input", $event.target.checked);
	}

    focus() {
        this.$refs.check.focus();
    }
}
