
























































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import VueInput from "@/components/Form/VueInput.vue";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueTab from "@/components/Tab/VueTab.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import { IOption } from "@/store/models/Form/FormModel";
import {ILoginReqValidate, LoginReq} from "@/store/models/Auth/AuthModel";
import Auth from "@/store/modules/AuthModule";
import VueFindEmail from "@/components/Auth/VueFindEmail.vue";
import VueFindPassword from "@/components/Auth/VueFindPassword.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import { ICommonCode } from "@/store/models/CommonModel";
import { ROUTE_NAME } from "@/router/";

@Component({
	components: {
		VueInput,
		VueCheck,
		VueButton,
		VueTab,
		VueSelect,
		VueFindEmail,
		VueFindPassword,
		VueValidator,
	},
})
export default class VueLogin extends Vue {
	switcher: string = "isLogin";

	loginData: LoginReq = {
		email: this.getEmail() || "",
		password: "",
	};

	validator: ILoginReqValidate = {
		email: "",
		password: "",
	};

	isRemember: boolean = this.getIsRemember() || false;

	emailText: string = "";

	//pages
	isLogin: boolean = true;
	findIdPassword: boolean = false;
	findIdSuccess: boolean = false;
	findPasswordSuccess: boolean = false;

	//tab
	activeIndex: number = 0;

	tabList: ICommonCode[] = [
		{
			name: "login_page.find_email.find_email_btn",
			pk: 0,
		},
		{
			name: "login_page.find_email.find_password_btn",
			pk: 1,
		},
	];

	//휴대폰
	numberList: IOption[] = [];

    mounted() {
        if (this.$route.query?.target) {
            this.showPage(this.$route.query.target.toString());
        }
    }

	setSelected(tab: number) {
		this.activeIndex = tab;
	}

	getEmail() {
		return localStorage.getItem("rememberEmail");
	}

	getIsRemember() {
		let isRemember: boolean = false;

		if (localStorage.getItem("rememberEmail")) {
			isRemember = true;
		}

		return isRemember;
	}

	validate() {
		let valid: boolean = true;

        this.validator = {
            email: "",
            password: "",
        };

		if (this.loginData.email.length < 1) {
			this.validator.email = this.$t(
				"validate.login.email.required"
			).toString();
			valid = false;
		}

		if (this.loginData.password.length < 1) {
			this.validator.password = this.$t(
				"validate.login.password.required"
			).toString();
			valid = false;
		}

		return valid;
	}

	async login() {
		if (this.validate()) {
			if (this.isRemember) {
				localStorage.setItem("rememberEmail", this.loginData.email);
			}

			await Auth.login(this.loginData)
                .then((res: any) => {
                    if (res.errors.email) {
                        this.validator.email = res.errors.email[0]
                    }

                    if (res.errors.password) {
                        this.validator.password = res.errors.password[0]
                    }

					if (res.data) {
						this.$router.push({
							name: this.$route.params.routeName || ROUTE_NAME.HOME
						});
					}
			    });
		}
	}

	findEmail(email: string) {
		this.emailText = email;
		this.showPage("findIdSuccess");
	}

	findPassword(email: string) {
		this.emailText = email;
		this.showPage("findPasswordSuccess");
	}

	showPage(page: string) {
		switch (page) {
			case "findId":
				this.emailText = this.$route.query?.email?.toString() ? this.$route.query.email.toString() : "";
				this.isLogin = false;
				this.findIdPassword = true;
				this.findIdSuccess = false;
				this.findPasswordSuccess = false;
				this.activeIndex = 0;
				break;

			case "findPassword":
                this.emailText = this.$route.query?.email?.toString() ? this.$route.query.email.toString() : "";
				this.isLogin = false;
				this.findIdPassword = true;
				this.findIdSuccess = false;
				this.findPasswordSuccess = false;
				this.activeIndex = 1;
				break;

			case "findIdSuccess":
				this.isLogin = false;
				this.findIdPassword = false;
				this.findIdSuccess = true;
				this.findPasswordSuccess = false;
				this.activeIndex = 0;
				break;

			case "findPasswordSuccess":
				this.isLogin = false;
				this.findIdPassword = false;
				this.findIdSuccess = false;
				this.findPasswordSuccess = true;
				this.activeIndex = 1;
				break;

			case "login":
			default:
				this.emailText = "";
				this.isLogin = true;
				this.findIdPassword = false;
				this.findIdSuccess = false;
				this.findPasswordSuccess = false;
				this.activeIndex = 0;
				break;
		}
	}
}
