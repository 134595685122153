














import Vue from "vue";
import { Component } from "vue-property-decorator";
import VueButton from "@/components/Button/VueButton.vue";

@Component({
	components: {
		VueButton
	},
	metaInfo() {
		return {
			title: this.$route.meta.title,
		};
	},
})
export default class Error404 extends Vue {

}
