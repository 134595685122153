












import Vue from "vue";
import Component from "vue-class-component";
import LayoutHeader from "@/layouts/Header.vue";
import LayoutFooter from "@/layouts/Footer.vue";
import VueSpinner from "@/components/Spinner/VueSpinner.vue";
import VueLoginModal from "@/components/Auth/VueLoginModal.vue";

require("@/store/modules/AuthModule");
require("@/store/modules/LoginModalModule");

@Component({
	components: {
        VueLoginModal,
		VueSpinner,
		LayoutHeader,
		LayoutFooter,
	},
})
export default class Layout extends Vue {
	// 헤더 스크롤
	showScrDown: boolean = true;

	isSlot: boolean = true;

	onScroll() {
		let pageY = window.pageYOffset;
		this.showScrDown = pageY < 60;
	}

	mounted() {
		window.addEventListener("scroll", this.onScroll);
    }

	beforeDestroy() {
		window.removeEventListener("scroll", this.onScroll);
	}
}
