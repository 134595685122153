
























import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import VueCheck from "@/components/Form/VueCheck.vue";
import VueButton from "@/components/Button/VueButton.vue";

@Component({
	components: {
		VueCheck,
		VueButton,
	},
})
export default class VueRegistryComplete extends Vue {

    @Prop({default: ''}) title!: string;

    @Prop({default: ''}) message1!: string;

    @Prop({default: ''}) message2!: string;

}
