

























import Component from "vue-class-component";
import { FormMixin } from "@/components/Mixins/FormMixin";
import VueInput from "@/components/Form/VueInput.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueValidator from "@/components/Validator/VueValidator.vue";
import AuthModule from "@/store/modules/AuthModule";
//import Auth from "@/store/modules/AuthModule";

@Component({
	components: {
		VueInput,
		VueButton,
		VueValidator,
	},
})
export default class VueFindPassword extends FormMixin {
	//input
	emailText: string = "";
	emailValidator: string = "";

	$refs!: {
		email: HTMLInputElement;
	};

	validate() {
        let reg = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
		let valid = true;

        this.emailValidator = '';

		if (this.emailText.length < 1) {
			this.emailValidator = "validate.find_password.email.required";
			valid = false;

			this.$refs.email.focus();
		}

        if (!reg.test(this.emailText)) {
            this.emailValidator = "validate.find_password.email.required";
            valid = false;

            this.$refs.email.focus();
        }

		return valid;
	}

	async submit() {
		if (this.validate()) {
			await AuthModule.actionSendPasswordAuthEmail(this.emailText)
                .then((res: any) => {
                    if (res.data) {
                        this.$emit("next", this.emailText);
                    }

                    if (res.errors.email) {
                        this.emailValidator = res.errors.email[0];
                        this.$refs.email.focus();
                    }
                })
		}
	}
}
