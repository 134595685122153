import {REQUEST_RESULT} from "@/store/api/BaseApi";
import {AxiosResponse} from "axios";

interface IBaseResponse {
    status: number;
    message: string | string[];
    error: string | string[];
}

export interface IAsset {
    contentType: string; // 컨텐츠 타입
    name: string; // 파일 이름
    url: string; // 파일 주소 (s3)
    uuid: string; // 파일 식별자
    assetTarget?: string;
}

export class BaseRes implements IBaseResponse {

    status!: number;
    message!: string | string[];
    error!: string | string[];

    setBasicError() {
        this.status = REQUEST_RESULT.ERROR_WITH_MESSAGE;
        this.message = "요청에 실패하였습니다.";
    }

    setAuthError() {
        this.status = REQUEST_RESULT.UNAUTHORIZATION;
        this.message = '인증 기간이 만료 되었습니다.';
    }

    setServerError() {
        this.status = REQUEST_RESULT.SERVER_ERROR;
        this.message = '서버 오류가 발생하였습니다.';
    }

    setValidateError(response: AxiosResponse) {
        this.status = REQUEST_RESULT.ERROR_WITH_MESSAGE;
        Object.keys(response.data.message).map(error => {
            response.data.message[error].map((message: string) => {
                this.message = [];
                this.message.push(message);
            })
        });
    }

    setValidateError2(response: AxiosResponse) {
        this.status = REQUEST_RESULT.ERROR_WITH_MESSAGE;
        Object.keys(response.data.errors).map(error => {
            response.data.errors[error].map((message: string) => {
                this.error = [];
                this.error.push(message);
            })
        });
    }
}

export class EditRes extends BaseRes {
    data!: {
        pk: number;
    }

    constructor() {
        super();
    }
}

export class BaseIterable<T> implements Iterable<T> {

    data!: T[];

    [Symbol.iterator]() {
        let nextIndex = 0;

        return {
            next: () => {
                return {
                    value: this.data[nextIndex++],
                    done: nextIndex > this.data.length
                }
            }
        }
    }
}
