

































































































import Component from "vue-class-component";
import VueTitle from "@/components/Title/VueTitle.vue";
import VueSelect from "@/components/Form/VueSelect.vue";
import VueButton from "@/components/Button/VueButton.vue";
import VueCard from "@/components/Card/VueCard.vue";
import VueListTab from "@/components/Tab/VueListTab.vue";
import VueModal from "@/components/Modal/VueModal.vue";
import { ICommonCode } from "@/store/models/CommonModel";
import { State } from "vuex-class";
import { IExhibition } from "@/store/models/Exhibition/ExhibitionModel";
import { IOption } from "@/store/models/Form/FormModel";
import ExhibitionModule from "@/store/modules/ExhibitionModule";
import { Watch } from "vue-property-decorator";
import {PopupMixin} from "@/components/Mixins/PopupMixin";
import IntroLayout from "@/layouts/IntroIndex.vue";
import VueSimpleTitle from "@/components/Title/VueSimpleTitle.vue";

@Component({
	components: {
    VueSimpleTitle,
		IntroLayout,
		VueTitle,
		VueSelect,
		VueButton,
		VueCard,
		VueListTab,
		VueModal,
	},
    metaInfo() {
        return {
            title: `VIP Exhibition | MOBIS Business Connect.`
        };
    },
})
export default class VipExhibition extends PopupMixin {

	@State((state) => state.exhibition.exhibitionCommonCodes)
	exhibitionCommonCodes!: ICommonCode[];

	//@State((state) => state.exhibition.exhibitionList)
	//exhibitionList!: IExhibition[];

  @State(state => state.lang.locale)
  locale!: string;


	/**
	 * mac os show
	 */
	isMacOsShow: boolean = false;

	/**
	 * req용 카테고리 코드
	 */
	commonCodePk: number | undefined;

	/**
	 * 활성 탭 오브젝트
	 */
	activeItem: ICommonCode = {
		name: "",
		pk: 0,
	};

	/**
	 * req용 orderby
	 */
	orderBy: string = "new";

	/**
	 * tab active
	 */
	activeIndex: number = 0;

	/**
	 * 모달 핸들러
	 */
	showModal: boolean = false;

	/**
	 * 상세보기 정보
	 */
	activeExhibition: IExhibition = {} as IExhibition;

	/**
	 * 필터 옵션
	 */
	selectList: IOption[] = [
		{
			text: "common.form.order_select.view",
			value: "view",
			disabled: false,
		},
		{
			text: "common.form.order_select.new",
			value: "new",
			disabled: false,
		},
	];

	get commonCodes() {
	    let codes = [{
            name: this.$t("common.categories_all").toString(),
            pk: 0
        }]

        this.exhibitionCommonCodes.map(item => {
            codes.push(item);
        })

        return codes;
    }

	@Watch("orderBy")
	@Watch("activeItem", { deep: true })
	watchExhibitionList() {
		ExhibitionModule.actionMutationExhibitionList({
			commonCodePk: this.activeItem.pk,
			orderBy: this.orderBy,
		});
	}

    @Watch('locale')
    watchLocaleChange() {
        ExhibitionModule.actionExhibitionCategory();

        ExhibitionModule.actionMutationExhibitionList({
            commonCodePk: this.activeItem.pk,
            orderBy: this.orderBy,
        })
    }

	created() {
		ExhibitionModule.actionExhibitionCategory();

        if (this.$route.query?.exhibitionCategoryPk) {
            this.activeItem = {
                name: this.$route.query.name.toString(),
                pk: Number(this.$route.query.exhibitionCategoryPk)
            }
        } else {
            ExhibitionModule.actionMutationExhibitionList({
                commonCodePk: this.activeItem.pk,
                orderBy: this.orderBy,
            })
            .then(() => {
                if (this.$route.params?.subId) {
                    let item: IExhibition | undefined = this.exhibitionList.find((item => item.pk.toString() === this.$route.params.subId));

                    if (item) {
                        ExhibitionModule.actionExhibitionUpCount(item.pk)
                            .then(() => {
                                this.activeExhibition = item as IExhibition;
                                this.showModal = true;
                            })
                    }
                }
            })
        }
    }

	mounted() {
		if (this.$mdevice.isMacOs) {
			this.isMacOsShow = true;
		}
	}

	modalActive(item: IExhibition) {
        history.pushState({}, '', `/${this.locale}/vip-exhibition/${item.pk}`)
        ExhibitionModule.actionExhibitionUpCount(item.pk)
        .then(() => {
            this.activeExhibition = item;
            this.showModal = true;
        })
	}

	toggleModal() {
      history.pushState({}, '', `/${this.locale}/vip-exhibition`)
      this.showModal = !this.showModal;
	}

  get exhibitionList() {
    return ExhibitionModule.vipExhibitionList;
  }

}
