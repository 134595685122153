










import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/layouts/index.vue";
import VueUnregisterConfirm from "@/components/Auth/VueUnregisterConfirm.vue";
import VueUnregister from "@/components/Auth/VueUnregister.vue";

@Component({
	components: {
		Layout,
		VueUnregisterConfirm,
		VueUnregister
	},
})
export default class Unregister extends Vue {
	switcher: string = "VueUnregisterConfirm";
}
