import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from "@/store"

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: store.state.lang.htmlLang,
    fallbackLocale: store.state.lang.htmlLang,
    messages: {
        'ko': require('@/locales/ko.json'),
        'en': require('@/locales/en.json'),
        'zh': require('@/locales/ch.json'),
        'ja': require('@/locales/jp.json'),
    },
    silentTranslationWarn: true
})


