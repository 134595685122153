




























































import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import TextCardListItem from "@/components/Card/TextCardListItem.vue";
import { isMacOs } from "mobile-device-detect";

@Component({
	components: {
		TextCardListItem,
	},
})
export default class TextCardList extends Vue {
	@Prop({ default: [] }) items!: object;

	isMacOsShow: boolean = false;

	activePage: number = 1;

	/**
	 * swiper option
	 */
	swiperOption: any = {
		direction: "vertical",
		loop: false,
		keyboard: {
			enabled: true,
		},
		mousewheel: true,
		slidesPerGroup: 3,
		slidesPerView: 3,
		slidesPerColumn: 2,
		spaceBetween: 20,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		breakpoints: {
			1401: {
				slidesPerGroup: 2,
				slidesPerView: 2,
				slidesPerColumn: 3,
			},
		},
		// on: {
		// 	slidePrevTransitionEnd: function () {
		// 		AOS.refreshHard();
		// 	},
		// 	slideNextTransitionStart: function () {
		// 		AOS.refreshHard();
		// 		//alert("슬라이드 변경");
		// 	},
		// },
	};

	mounted() {
		if (isMacOs) {
			this.isMacOsShow = true;
		}
	}

	clickEvent(payload: any) {
		this.$emit("click", payload);
	}

	slideChange(handler: string) {
	    if (handler === 'left') {
	        ++this.activePage;
        } else {
	        --this.activePage;
        }
    }

	next() {
        this.slideChange('left')
    }

    prev() {
	    this.slideChange('right')
    }
}
