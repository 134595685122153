

























































































































































import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {ROUTE_NAME} from "@/router";
import {State} from "vuex-class";
import {IGnbMenu, IGnbMenuItem, IGnbParent} from "@/store/models/Gnb/GnbModel";
import GnbModule from "@/store/modules/GnbModule";
import UserAgreementsModal from "@/components/Modal/UserAgreementsModal.vue";
import PersonalInformationModal from "@/components/Modal/PersonalInformationModal.vue";

@Component({
  components: {
    UserAgreementsModal,
    PersonalInformationModal
  }
})
export default class LayoutGnb extends Vue {
    @Prop({default: false}) active!: Boolean;
    @Prop({default: false}) isActive!: Boolean;
    // TODO :: product-category gnb api 받아와서 메뉴에 매핑시킬것.
    GnbModule = GnbModule;
    @State(state => state.gnb.gnbMenus)
    gnbMenus!: IGnbMenu;

    @State(state => state.lang.locale)
    locale!: string;

    // 메뉴 리스트
    menuList: IGnbParent<IGnbMenuItem>[] = [
        {
            name: "common.gnb.meeting.meeting",
            subMenu: [
                {
                    name: "common.gnb.meeting.meeting",
                    link: ROUTE_NAME.MEETING,
                },
                {
                    name: "common.gnb.meeting.meeting_request",
                    link: ROUTE_NAME.REQUESTMEETING,
                },
            ],
            toggle: true,
        },
        {
            name: "common.gnb.qna.qna",
            subMenu: [
                {
                    name: "common.gnb.qna.personal_inquiry",
                    link: ROUTE_NAME.QNA,
                },
                {
                    name: "common.gnb.qna.inquiry_list",
                    link: ROUTE_NAME.QNA,
                    query: {
                        activeIndex: 1
                    }
                },
            ],
            toggle: true,
        },
        {
            name: "common.gnb.mypage.mypage",
            subMenu: [
                {
                    name: "common.gnb.mypage.information",
                    link: ROUTE_NAME.MYPAGE,
                },
                {
                    name: "common.gnb.mypage.bookmark",
                    link: ROUTE_NAME.MYPAGE,
                    query: {
                        activeIndex: 1
                    }
                },
            ],
            toggle: true,
        },
    ];

    // 이용약관 모달
	  userAgreements: boolean = false;

    // 개인정보처리방침 모달
	  personalInformation: boolean = false;

    created() {
        GnbModule.actionGnbList();
    }

    // Modal
    toggleModal(name: string) {
      if (name === "userAgreementsModal") {
        this.userAgreements = !this.userAgreements;
      } else {
			  this.personalInformation = !this.personalInformation;
      }
		}

    @Watch('locale')
    watchLocaleChange() {
        GnbModule.actionGnbList();
    }
}
