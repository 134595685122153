import {BookmarkListRes, IBookmarkListReq, IMypageEditReq, MypageShowRes} from "@/store/models/User/UserModel";
import * as BaseApi from "@/store/api/BaseApi";
import {BaseRes} from "@/store/models/BaseModel";

/**
 * 마이페이지 > 내정보 요청
 */
export async function reqMypageShow(): Promise<MypageShowRes> {

    let mypageShowRes: MypageShowRes = new MypageShowRes();

    await BaseApi.axiosAuth()
        .post('/api/user/account/show', {})
        .then(result => {
            mypageShowRes = (result.data as MypageShowRes);
        })
        .catch(error => mypageShowRes = error);

    return mypageShowRes;
}

/**
 * 마이페이지 수정
 * @param req
 */
export async function reqMyPageEdit(req: IMypageEditReq): Promise<BaseRes> {

    let mypageEditRes: BaseRes = new BaseRes();

    await BaseApi.axiosAuth()
        .post('/api/user/account/edit', req)
        .then(result => {
            mypageEditRes = (result.data as BaseRes);
        })
        .catch(error => mypageEditRes = error)

    return mypageEditRes;
}

/**
 * 마이페이지 > 북마크 리스트
 * @param bookmarkListReq
 */
export async function reqBookmarkList(bookmarkListReq: IBookmarkListReq): Promise<BookmarkListRes> {

    let bookmarkListRes: BookmarkListRes = new BookmarkListRes();

    await BaseApi.axiosAuth()
        .post('/api/user/bookmark/list', bookmarkListReq)
        .then(result => {
            bookmarkListRes = (result.data.data as BookmarkListRes);
        })
        .catch(error => bookmarkListRes = error);

    return bookmarkListRes;
}
