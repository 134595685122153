import {ExhibitionListRes, IExhibitionReq, MainExhibitionListRes} from "@/store/models/Exhibition/ExhibitionModel";
import {axiosAuth} from "@/store/api/BaseApi";
import LangModule from "@/store/modules/LangModule";
import {BaseRes} from "@/store/models/BaseModel";

/**
 * 메인 전시(Exhibition) 리스트
 */
export async function reqMainExhibitionList(): Promise<MainExhibitionListRes> {

    let mainExhibitionListRes: MainExhibitionListRes = new MainExhibitionListRes();

    await axiosAuth()
        .post('/api/exhibition/list', {
            pageNo: 1,
            rowPerPage: 8,
            siteLang: LangModule.getLocaleToLang,
            orderBy: 'new'
        })
        .then(result => {
            mainExhibitionListRes = (result.data.data as MainExhibitionListRes);
        })
        .catch(error => mainExhibitionListRes = error);

    return mainExhibitionListRes;
}

/**
 * 전시 리스트
 * @param exhibitionListReq
 */
export async function reqExhibitionList(exhibitionListReq: IExhibitionReq): Promise<ExhibitionListRes> {

    let exhibitionListRes: ExhibitionListRes = new ExhibitionListRes();

    await axiosAuth()
        .post('/api/exhibition/list', exhibitionListReq)
        .then(result => {
            exhibitionListRes = (result.data.data as ExhibitionListRes);
        })
        .catch(error => exhibitionListRes = error);

    return exhibitionListRes;
}

/**
 * 전시소식 조회수 증가
 * @param exhibitionPk
 */
export async function reqExhibitionUpCount(exhibitionPk: number): Promise<BaseRes> {

    let res: BaseRes = new BaseRes();

    await axiosAuth()
        .post('/api/exhibition/upcount', {
            pk: exhibitionPk
        })
        .then(result => {
            res = (result.data as BaseRes);
        })
        .catch(error => res = error);

    return res;
}
