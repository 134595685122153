















import Vue from "vue";
import Component from "vue-class-component";
import VueSortingCheck from "@/components/Form/VueSortingCheck.vue";
import {Prop} from "vue-property-decorator";
import VueObjectCheck from "@/components/Form/VueObjectCheck.vue";

@Component({
    components: {VueObjectCheck, VueSortingCheck}
})
export default class VueSortingCheckMulti extends Vue {

    @Prop() value!: number[];

    @Prop() items!: object[];

    updateInput(payload: {checked: boolean, value: number}) {
        if (payload.checked) {
            this.value.push(payload.value);
        } else {
            this.value.splice(this.value.findIndex(item => {
                return item === payload.value
            }), 1);
        }

        this.$emit('input', this.value);
    }

    isChecked(vueItem: any) {
        return this.value.find(item => item === vueItem);
    }
}
