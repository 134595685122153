




























import Vue from "vue";
import Component from "vue-class-component";
import Layout from "@/layouts/Vsr.vue";
import VideoPlayer from "@/components/Vsr/VideoPlayer.vue";
import { VsrPageInfo } from "@/store/models/Vsr/VsrModel";
import VsrModule from "@/store/modules/VsrModule";

@Component({
  components: {
    Layout,
    VideoPlayer
  }
})
export default class VsrMobisVision extends Vue {
  info = new VsrPageInfo("mobisVision");
  showIntro = true;
  showStartBtn = false;

  get videoPlayer() {
    return this.$refs.videoPlayer as any;
  }

  start() {
    VsrModule.setUseBgSound(false);
    this.showIntro = false;
    this.showStartBtn = false;

    this.videoPlayer.load(this.info.getVideo(0), this.info.getCaption(0));
  }
}
