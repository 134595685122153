import {Module, VuexModule, getModule, MutationAction, Action, Mutation} from "vuex-module-decorators";
import store from '@/store';
import {reqBookmarkList, reqMyPageEdit, reqMypageShow} from "@/store/api/UserApi";
import {
    BookmarkListRes, IBookmark,
    IMypageEditReq,
    IMypageShow, IMypageUser,
    IUserState,
    MypageShow,
    MypageShowRes
} from "@/store/models/User/UserModel";
import {IProduct, IProductCategory, ProductCategoryListRes} from "@/store/models/Product/ProductModel";
import {reqProductCategoryList} from "@/store/api/ProductApi";
import LangModule from "@/store/modules/LangModule";
import {BaseRes} from "@/store/models/BaseModel";

@Module({
    store,
    name: "user",
    namespaced: true,
    dynamic: true
})
class UserModule extends VuexModule implements IUserState {

    mypageShow: IMypageShow = new MypageShow()

    productCategories: IProductCategory[] = [];

    userBookmarks: IProduct[] = [];

    @Mutation
    changeUserPhoneNumber(phoneNum: string) {
        this.mypageShow.user.phoneNum = phoneNum;
    }

    @Mutation
    changeUserMobileNumber(mobileNum: string) {
        this.mypageShow.user.mobileNum = mobileNum;
    }

    @Mutation
    changeUserProductCategories(productCategoryPks: number[]) {
        this.mypageShow.userProduct.productCategoryPks = productCategoryPks;
    }

    @Mutation
    changeUserDepartment(department: string) {
        this.mypageShow.user.department = department;
    }

    @Mutation
    changeUserPosition(position: string) {
        this.mypageShow.user.position = position;
    }

    @Mutation
    async mutationChangeBookmarkPage(item: IProduct) {
        this.userBookmarks.map(product => {
            if (product.pk === item.pk) {
                product.bookmark = !product.bookmark;
            }
        })
    }

    /**
     * 마이페이지 > 내정보
     */
    @MutationAction
    async actionMypageShow() {
        const mypageShowRes: MypageShowRes = await reqMypageShow();

        return {
            mypageShow: mypageShowRes.data
        }
    }

    /**
     * 마이페이지 관심제품 코드
     */
    @MutationAction
    async actionMypageProductCategoryList() {
        const productCategoryListRes: ProductCategoryListRes = await reqProductCategoryList({});

        return {
            productCategories: productCategoryListRes.productCategories
        }
    }

    /**
     * 마이페이지  내정보 수정
     * @param req
     */
    @Action
    async actionMypageEdit(req: IMypageEditReq) {
        const mypageEditRes: BaseRes = await reqMyPageEdit(req);

        if (mypageEditRes.error) {
            console.log(mypageEditRes.error);
        }
    }

    /**
     * 마이페이지 북마크 제품 리스트
     */
    @MutationAction
    async actionBookmarkList() {
        const bookmarkListRes: BookmarkListRes = await reqBookmarkList({
            sort: 'VIEWS',
        })

        return {
            userBookmarks: bookmarkListRes.products
        }
    }

}

export default getModule(UserModule);
