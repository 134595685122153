import Vue from 'vue';
import {Prop} from "vue-property-decorator";

export class FormMixin extends Vue {
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: 'text' }) type!: string;
    @Prop({ default: 20 }) maxlength!: number;
    @Prop({ default: '' }) value!: string;
    @Prop({ default: '' }) name!: string;
    @Prop({ default: '' }) labelFor!: string;
    @Prop({ default: '' }) placeholder !: string
    @Prop({ default: '' }) labelStyle!: string;
    @Prop({ default: '' }) checked!: string;
}
