






























import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";
import { ROUTE_NAME } from "@/router";
import Layout from "@/layouts/Vsr.vue";

@Component({
  components: {
    Layout
  }
})
export default class VsrLobby extends Vue {
  @State(state => state.lang.locale) locale!: string;

  ROUTE_NAME = ROUTE_NAME;

  loaded = false;
  showBg = true;
  sections: object[] = [
    {
      title: "M. Vision",
      routeName: ROUTE_NAME.VSR_M2GO,
      subItems: [
        { title: "M. Vision TO", routeName: ROUTE_NAME.VSR_MTO },
        { title: "M. Vision HI", routeName: ROUTE_NAME.VSR_MHI },
        { title: "M. Vision POP", routeName: ROUTE_NAME.VSR_MPOP },
        { title: "M. Vision 2GO", routeName: ROUTE_NAME.VSR_M2GO }
      ]
    },
    {
      title: "Tech Gallery",
      routeName: ROUTE_NAME.VSR_TECH_GALLERY
    },
    {
      title: "MOBIS Vision",
      routeName: ROUTE_NAME.VSR_MOBIS_VISION
    },
    {
      title: "MOBIS Now",
      routeName: ROUTE_NAME.VSR_MOBIS_NOW
    }
  ];

  created() {
    setTimeout(() => {
      this.loaded = true;
    }, 2000);
  }

  clickSection(item: any) {
    if (item.subItems) {
      return;
    }

    this.goRoute(item.routeName);
  }

  goRoute(routeName: string) {
    (this.$refs.layout as any).goRoute(routeName);
  }
}
